import * as React from 'react';
import { Datagrid, ReferenceField, TextField, usePermissions } from 'react-admin';
import './SolutionFeedbackDatagrid.scss';
import { isAdminOrHigher } from '../providers/auth.provider';

export const SolutionFeedbackDatagrid = (): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="show" className="solution-feedbacks-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField sortable={false} source="id" label="#"/>
			{isAdminOrHigher(permissions) &&
                <ReferenceField sortable={false} source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField sortable={false} source="name.he"/>
                </ReferenceField>}

			<ReferenceField sortable={false} source="orderId" reference="solution-orders" label="SOLUTION_ORDER"
			                link="show">
				<TextField sortable={false} source="client.user.fullName"/>
			</ReferenceField>

			<TextField source="review" label="REVIEW"/>
		</Datagrid>
	);
};
