import React, { FC, HtmlHTMLAttributes, memo } from 'react';
import { FieldProps, useRecordContext } from 'react-admin';
import { PhoneFieldProps } from './PhoneField';
import get from 'lodash-es/get';

export const styles = {
	wrapper: { display: 'flex', gap: '1rem', alignItems: 'center' },
	colorDot: {
		width: '1.25rem',
		height: '1.25rem',
		marginRight: '.25rem',
		borderRadius: '50%',
		boxShadow: '2px 2px 5px #00000029'
	}
};

export const ColorField: FC<PhoneFieldProps> = memo<PhoneFieldProps>(
	({ source, record, className, ...rest }: any) => {
		const recordFromContext = useRecordContext();

		record ??= recordFromContext;

		return <div style={styles.wrapper}>
			<div style={{ ...styles.colorDot, background: get(record, source) }}
			/>
			<span className={className}>{get(record, source)}</span>
		</div>;
	});

ColorField.displayName = 'ColorField';

export interface ColorFieldProps
	extends FieldProps,
		HtmlHTMLAttributes<HTMLAnchorElement> {
}

