import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput, } from 'react-admin';
import { VehicleList } from './VehicleList';
import { VehicleDatagrid } from './VehicleDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Vehicle } from './Vehicle';

export * from './Vehicle';
export * from './VehicleCreate';
export * from './VehicleDatagrid';
export * from './VehicleEdit';
export * from './VehicleList';
export * from './VehicleShow';

const VehiclesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'owner', header: 'OWNER' },
	{ key: 'garage', header: 'GARAGE' },
	{ key: 'imageUrl', header: 'IMAGE' },
	{ key: 'plateNumber', header: 'PLATE_NUMBER' },
	{ key: 'type', header: 'TYPE' },
	{ key: 'year', header: 'YEAR' },
	{ key: 'horsePower', header: 'HORSE_POWER' },
	{ key: 'engineSize', header: 'ENGINE_SIZE' },
	{ key: 'gearType', header: 'GEAR_TYPE' },
	{ key: 'color', header: 'COLOR' },
];

export const VehiclesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: Vehicle) => entity;

	const vehiclesFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('רכבים', exportFields, exportDataMapper)}
				{...props}
				filters={vehiclesFilter}
				bulkActionButtons={<VehiclesBulkActionButtons/>}
				title="VEHICLES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <VehicleList/> : <VehicleDatagrid/>}
			</List>
		</Fragment>
	);
};
