import * as React from 'react';
import { DateField, ReferenceField, Show, ShowProps, SimpleShowLayout, TextField, usePermissions, } from 'react-admin';
import { isAdminOrHigher, isGarageOwnerOrHigher } from '../providers/auth.provider';

export const GarageDowntimeShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={isGarageOwnerOrHigher(permissions) ? undefined : false}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				{isAdminOrHigher(permissions) &&
                    <ReferenceField source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField source="name.he"/>
                </ReferenceField>}

				<DateField locales="he-IL" source="from" label="FROM_DATE"/>
				<DateField locales="he-IL" source="to" label="TO_DATE"/>
				<TextField source="reason.he" label="REASON"/>
			</SimpleShowLayout>
		</Show>
	);
};
