import * as React from 'react';
import { Datagrid, ReferenceField, TextField, usePermissions } from 'react-admin';
import './FeedbackQuestionDatagrid.scss';
import { isAdminOrHigher } from '../providers/auth.provider';

export const FeedbackQuestionDatagrid = (): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="edit" className="feedback-questions-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField sortable={false} source="id" label="#"/>
			{isAdminOrHigher(permissions) &&
                <ReferenceField sortable={false} source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField sortable={false} source="name.he"/>
                </ReferenceField>}

			<TextField source="label.he" label="LABEL"/>
		</Datagrid>
	);
};
