import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput, } from 'react-admin';
import { GarageList } from './GarageList';
import { GarageDatagrid } from './GarageDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Garage } from './Garage';

export * from './Garage';
export * from './GarageCreate';
export * from './GarageDatagrid';
export * from './GarageEdit';
export * from './GarageList';
export * from './GarageShow';

const GaragesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'name', header: 'NAME' },
	{ key: 'address.lon', header: 'LON' },
	{ key: 'address.lat', header: 'LAT' },
	{ key: 'address.addressStr', header: 'ADDRESS' },
	{ key: 'contactDetails.name', header: 'CONTACT_DETAILS.NAME' },
	{ key: 'contactDetails.phone', header: 'CONTACT_DETAILS.PHONE' },
	{ key: 'contactDetails.whatsappNumber', header: 'CONTACT_DETAILS.WHATSAPP_NUMBER' },
	{ key: 'contactDetails.email', header: 'CONTACT_DETAILS.EMAIL' },
	{ key: 'primaryColor', header: 'PRIMARY_COLOR' },
	{ key: 'backgroundColor', header: 'BACKGROUND_COLOR' },
	{ key: 'description', header: 'DESCRIPTION' },
	{ key: 'logoImageUrl', header: 'LOGO_IMAGE' },
	{ key: 'coverImageUrl', header: 'COVER_IMAGE' },
	{ key: 'openingHours', header: 'OPENING_HOURS' },
	{ key: 'employees', header: 'EMPLOYEES' },
	{ key: 'code', header: 'CODE' },
	{ key: 'solutions', header: 'SOLUTIONS' },
];

export const GaragesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: Garage) => entity;

	const garagesFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('garages', exportFields, exportDataMapper)}
				{...props}
				filters={garagesFilter}
				bulkActionButtons={<GaragesBulkActionButtons/>}
				title="GARAGES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <GarageList/> : <GarageDatagrid/>}
			</List>
		</Fragment>
	);
};
