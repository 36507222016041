import * as React from 'react';
import { EditButton, Labeled, ReferenceField, ShowButton, TextField, useListContext } from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';

const feedbackAnswerStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const FeedbackAnswerList = () => {
	const classes = feedbackAnswerStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="feedback-answers"
										record={entity}
									/>
									<EditButton
										resource="feedback-answers"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={entity} source="id" label="#"/>
						</Labeled>
						<Labeled label="FEEDBACK" sx={classes.cardContentRow}>
							<ReferenceField record={entity} source="feedbackId" label="FORUM"
							                reference="solution-feedbacks" link="show">
								<TextField source="id"/>
							</ReferenceField>

						</Labeled>
						<Labeled label="QUESTION" sx={classes.cardContentRow}>
							<ReferenceField record={entity} source="questionId" label="FORUM"
							                reference="solution-questions" link="show">
								<TextField source="label.he"/>
							</ReferenceField>

						</Labeled>
						<Labeled label="FEEDBACK_VALUE" sx={classes.cardContentRow}>
							<TextField record={entity} source="value" label="FEEDBACK_VALUE"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
