import * as React from 'react';
import { Datagrid, FileField, FunctionField, NumberField, ReferenceField, TextField } from 'react-admin';
import { PriceField } from '../components/PriceField';
import './PriceQuoteDatagrid.scss';
import { GalleryField } from '../components/GalleryField';
import { StatusField } from '../components/StatusField';
import { PriceQuoteStatusToColor } from '../util/statuses-colors.config';
import { SolutionOrderAssigneeField } from './SolutionOrderAssigneeField';
import { TranslateField } from '../components/TranslateField';

export const PriceQuoteDatagrid = (): React.ReactElement => {

	return (
		<Datagrid rowClick="edit" className="price-quotes-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField source="id" label="#"/>
			<ReferenceField sortable={false} source="solutionOrderId" reference="solution-orders" label="SOLUTION_ORDER"
			                link="show">
				<FunctionField
					render={(record, source) => `#${record?.id} - ${record?.client?.user?.fullName} - ${record?.vehicle?.plateNumber}`}/>
			</ReferenceField>
			<TranslateField source="type" label="TYPE" enumName="EPriceQuoteType"/>
			<StatusField source="status" label="STATUS" enumToColors={PriceQuoteStatusToColor}
			             enumName="EPriceQuoteStatus"/>
			<TextField source="description" label="DESCRIPTION"/>
			<PriceField source="price" label="PRICE"/>
			<NumberField source="standardTimeInMinutes" label="STANDARD_TIME_IN_MINUTES"/>
			<SolutionOrderAssigneeField source="solutionOrderId" label="ASSIGNEE"/>
			<FileField sortable={false} source="fileUrl" label="FILE"/>
			<GalleryField sortable={false} source="gallery" label="GALLERY"/>
		</Datagrid>
	);
};
