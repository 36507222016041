import * as React from 'react';
import {
	AutocompleteInput,
	Create,
	CreateProps,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
	usePermissions
} from 'react-admin';
import { EApprovalStatus } from '../enums';
import { SingleImageInput } from '../components/SingleImageInput';
import { isAdminOrHigher } from '../providers/auth.provider';

export const ClientCreate = (props: CreateProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Create {...props}>
			<SimpleForm>
				{isAdminOrHigher(permissions) && <ReferenceInput
                    label="GARAGE"
                    source="garageId" reference="garages"
                    alwaysOn
                    validate={[required()]}
                >
                    <AutocompleteInput label="GARAGE" optionText="name.he" validate={[required()]}/>
                </ReferenceInput>}
				<TextInput source="user.fullName" label="NAME" validate={[required()]}/>
				<TextInput source="user.phone.phone" label="PHONE" validate={[required()]}
				           dir="ltr"
				           sx={{ direction: 'ltr !important' }}/>
				<TextInput source="user.email" label="EMAIL"/>
				<TextInput source="taxId" label="TAX_ID" validate={[required()]}/>
				<SelectInput source="approvalStatus" label="APPROVAL_STATUS"
				             defaultValue={EApprovalStatus.Approved}
				             choices={Object.keys(EApprovalStatus).map(key => ({
					             id: key,
					             name: `EApprovalStatus.${EApprovalStatus[key]}`
				             }))}/>
				<SingleImageInput source="profileImageUrl" label="PROFILE_IMAGE"/>
				{isAdminOrHigher(permissions) && <TextInput source="description" label="DESCRIPTION"/>}
			</SimpleForm>
		</Create>
	);
};
