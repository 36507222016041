import * as React from 'react';
import {
	DateField,
	EditButton,
	Labeled,
	ReferenceField,
	ShowButton,
	TextField,
	useListContext,
	usePermissions
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { isAdminOrHigher } from '../providers/auth.provider';

const garageDowntimeStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const GarageDowntimeList = () => {
	const { permissions } = usePermissions();

	const classes = garageDowntimeStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="garage-downtimes"
										record={entity}
									/>
									<EditButton
										resource="garage-downtimes"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={entity} source="id" label="#"/>
						</Labeled>
						{isAdminOrHigher(permissions) && <Labeled label="GARAGE" sx={classes.cardContentRow}>
                            <ReferenceField record={entity} source="garageId" label="FORUM" reference="garages"
                                            link="show">
                                <TextField source="name.he"/>
                            </ReferenceField>

                        </Labeled>}
						<Labeled label="FROM_DATE" sx={classes.cardContentRow}>
							<DateField locales="he-IL" record={entity} source="from" label="FROM_DATE"/>
						</Labeled>
						<Labeled label="TO_DATE" sx={classes.cardContentRow}>
							<DateField locales="he-IL" record={entity} source="to" label="TO_DATE"/>
						</Labeled>
						<Labeled label="REASON" sx={classes.cardContentRow}>
							<TextField record={entity} source="reason.he" label="REASON"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
