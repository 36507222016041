import * as React from 'react';
import { Edit, EditProps, SimpleForm, } from 'react-admin';
import { PriceQuoteForm } from './PriceQuotesForm';

export const PriceQuoteEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<PriceQuoteForm isSolutionOrderIdChangeable={false}/>
			</SimpleForm>
		</Edit>
	);
};
