import * as React from 'react';
import {
	DateField,
	ImageField,
	NumberField,
	ReferenceField,
	Show,
	ShowProps,
	SimpleShowLayout,
	TextField,
	usePermissions,
} from 'react-admin';
import { TranslateField } from '../components/TranslateField';
import { StatusField } from '../components/StatusField';
import { ApprovalStatusToColor } from '../util/statuses-colors.config';
import { isAdminOrHigher, isServiceAdvisorOrHigher } from '../providers/auth.provider';

export const VehicleShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={isServiceAdvisorOrHigher(permissions) ? undefined : false}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				<ReferenceField source="ownerId" reference="clients" label="OWNER" link="show">
					<TextField source="name.he"/>
				</ReferenceField>

				{isAdminOrHigher(permissions) &&
                    <ReferenceField source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField source="name.he"/>
                </ReferenceField>}

				<StatusField source="approvalStatus" label="APPROVAL_STATUS"
				             enumName="EApprovalStatus"
				             enumToColors={ApprovalStatusToColor}/>

				<ImageField source="imageUrl" label="IMAGE"/>
				<TextField source="plateNumber" label="PLATE_NUMBER"/>
				<TextField source="brand" label="BRAND"/>
				<TextField source="model" label="MODEL"/>
				<DateField locales="he-IL" source="testExpiresOn" label="TEST_EXPIRES_ON"/>
				<TranslateField source="type" label="TYPE"/>
				<TextField source="year" label="YEAR"/>
				<NumberField source="horsePower" label="HORSE_POWER"/>
				<NumberField source="engineSize" label="ENGINE_SIZE"/>
				<TranslateField source="gearType" label="GEAR_TYPE"/>
				<TextField source="color" label="COLOR"/>
			</SimpleShowLayout>
		</Show>
	);
};
