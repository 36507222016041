import React from 'react';
import { useRecordContext } from 'react-admin';
import { FieldProps } from 'ra-ui-materialui/src/field/types';
import get from 'lodash/get';

export const VideoField = <
	RecordType extends Record<string, any> = Record<string, any>
>(
	props: VideoFieldProps<RecordType>
) => {
	const { source, src } = props;
	const record = useRecordContext(props);
	const sourceValue = get(record, source!);

	if (sourceValue != null) {
		return <video src={sourceValue} controls width="320" height="240"></video>;
	}
	if (src != null) {
		return <video src={src} controls width="320" height="240"></video>;
	}
	return <></>;

};

export const VideoFieldPreview = <
	RecordType extends Record<string, any> = Record<string, any>
>(
	props: VideoFieldProps<RecordType>
) => {
	const { source, src } = props;
	const record = useRecordContext(props);
	const sourceValue = get(record, source!);

	if (sourceValue != null) {
		return <video src={sourceValue} style={{
			cursor: 'pointer',
			width: '3rem',
			maxHeight: '3rem',
			borderRadius: '4px'
		}}></video>;
	}
	if (src != null) {
		return <video src={src} style={{
			cursor: 'pointer',
			width: '3rem',
			maxHeight: '3rem',
			borderRadius: '4px'
		}}></video>;
	}
	return <></>;

};

export interface VideoFieldProps<
	RecordType extends Record<string, any> = Record<string, any>
> extends FieldProps<RecordType> {
	src?: string;
}

