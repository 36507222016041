import * as React from 'react';
import { Datagrid, ReferenceField, TextField } from 'react-admin';
import './FeedbackAnswerDatagrid.scss';

export const FeedbackAnswerDatagrid = (props): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="feedback-answers-table" optimized
		          {...(props ?? {})}
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField sortable={false} source="id" label="#"/>
			<ReferenceField sortable={false} source="feedbackId" reference="solution-feedbacks" label="FEEDBACK_ID"
			                link="show">
				<TextField sortable={false} source="id" label="FEEDBACK_ID"/>
			</ReferenceField>

			<ReferenceField sortable={false} source="questionId" reference="feedback-questions" label="QUESTION"
			                link="show">
				<TextField sortable={false} source="label.he"/>
			</ReferenceField>

			<TextField source="value" label="FEEDBACK_VALUE"/>
		</Datagrid>
	);
};
