import * as React from 'react';
import { EditButton, EmailField, ImageField, Labeled, ShowButton, TextField, useListContext } from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { PhoneField } from '../components/PhoneField';
import { ApprovalStatusToColor } from '../util/statuses-colors.config';
import { StatusField } from '../components/StatusField';
import { WeekdaysField } from '../components/WeekdaysField';
import { ColorField } from '../components/ColorField';

const garageStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const GarageList = () => {
	const classes = garageStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="garages"
										record={entity}
									/>
									<EditButton
										resource="garages"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={entity} source="id" label="#"/>
						</Labeled>
						<Labeled label="NAME" sx={classes.cardContentRow}>
							<TextField record={entity} source="name.he" label="NAME"/>
						</Labeled>
						<Labeled label="ADDRESS" sx={classes.cardContentRow}>
							<TextField record={entity} source="address.addressStr" label="ADDRESS"/>
						</Labeled>
						<Labeled label="APPROVAL_STATUS" sx={classes.cardContentRow}>
							<StatusField record={entity} source="approvalStatus" label="APPROVAL_STATUS"
							             enumName="EApprovalStatus"
							             enumToColors={ApprovalStatusToColor}/>
						</Labeled>
						<Labeled label="CONTACT_DETAILS.NAME" sx={classes.cardContentRow}>
							<TextField record={entity} source="contactDetails.name" label="CONTACT_DETAILS.NAME"/>
						</Labeled>
						<Labeled label="CONTACT_DETAILS.PHONE" sx={classes.cardContentRow}>
							<PhoneField record={entity} source="contactDetails.phone" label="CONTACT_DETAILS.PHONE"/>
						</Labeled>
						<Labeled label="CONTACT_DETAILS.WHATSAPP_NUMBER" sx={classes.cardContentRow}>
							<PhoneField record={entity} source="contactDetails.whatsappNumber"
							            label="CONTACT_DETAILS.WHATSAPP_NUMBER"/>
						</Labeled>
						<Labeled label="CONTACT_DETAILS.EMAIL" sx={classes.cardContentRow}>
							<EmailField record={entity} source="contactDetails.email" label="CONTACT_DETAILS.EMAIL"/>
						</Labeled>
						<Labeled label="PRIMARY_COLOR" sx={classes.cardContentRow}>
							<ColorField record={entity} source="primaryColor" label="PRIMARY_COLOR"/>
						</Labeled>
						<Labeled label="BACKGROUND_COLOR" sx={classes.cardContentRow}>
							<ColorField record={entity} source="backgroundColor" label="BACKGROUND_COLOR"/>
						</Labeled>
						<Labeled label="DESCRIPTION" sx={classes.cardContentRow}>
							<TextField record={entity} source="description.he" label="DESCRIPTION"/>
						</Labeled>
						<Labeled label="LOGO_IMAGE" sx={classes.cardContentRow}>
							<ImageField record={entity} source="logoImageUrl" label="LOGO_IMAGE"/>
						</Labeled>
						<Labeled label="COVER_IMAGE" sx={classes.cardContentRow}>
							<ImageField record={entity} source="coverImageUrl" label="COVER_IMAGE"/>
						</Labeled>
						<Labeled label="OPENING_HOURS" sx={classes.cardContentRow}>
							<TextField record={entity} source="openingHours.he" label="OPENING_HOURS"/>
						</Labeled>
						<Labeled sx={classes.cardContentRow}>
							<WeekdaysField record={entity} source="weekAvailability"/>
						</Labeled>
						<Labeled label="CODE" sx={classes.cardContentRow}>
							<TextField record={entity} source="code" label="CODE"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
