import { EApprovalStatus, EPriceQuoteStatus, ESolutionOrderStatus, EUserStatus, EVehicleStatus } from '../enums';

export const UserStatusToColor: { [key in EUserStatus]: string } = {
	[EUserStatus.Pending]: 'Pending',
	[EUserStatus.Approved]: 'Approved'
};

export const PriceQuoteStatusToColor: { [key in EPriceQuoteStatus]: string } = {
	[EPriceQuoteStatus.Draft]: '#999999',
	[EPriceQuoteStatus.SentToClient]: '#FF9900',
	[EPriceQuoteStatus.ClientApproved]: '#59B58D',
	[EPriceQuoteStatus.ClientDeclined]: '#ED474A',
	[EPriceQuoteStatus.ClientPaid]: '#33af14',
};

export const ApprovalStatusToColor: { [key in EApprovalStatus]: string } = {
	[EApprovalStatus.Pending]: '#FF9900',
	[EApprovalStatus.Approved]: '#59B58D',
	[EApprovalStatus.Blocked]: '#ED474A'
};

export const SolutionOrderStatusToColor: { [key in ESolutionOrderStatus]: string } = {
	[ESolutionOrderStatus.NewOrder]: '#AAAAAA',
	[ESolutionOrderStatus.GarageApproved]: '#59B58D',
	[ESolutionOrderStatus.WaitingForPriceQuote]: '#FF9900',
	[ESolutionOrderStatus.WaitingForClientApproval]: '#FF9900',
	[ESolutionOrderStatus.PriceQuoteApproved]: '#59B58D',
	[ESolutionOrderStatus.DeclinedPriceQuote]: '#993366',
	[ESolutionOrderStatus.InProgress]: '#FF9900',
	[ESolutionOrderStatus.WaitingForPayment]: '#FF9900',
	[ESolutionOrderStatus.Done]: '#0066CC',
	[ESolutionOrderStatus.ClientCancelled]: '#993366',
	[ESolutionOrderStatus.GarageCancelled]: '#999999',
	[ESolutionOrderStatus.WaitingForPickup]: '#FF9900',
};

export const VehicleStatusToColor: { [key in EVehicleStatus]: string } = {
	[EVehicleStatus.Waiting]: '#AAAAAA',
	[EVehicleStatus.InWork]: '#59B58D',
	[EVehicleStatus.InDrivingCheck]: '#FF9900',
	[EVehicleStatus.InTest]: '#FF9900',
	[EVehicleStatus.InWash]: '#59B58D',
	[EVehicleStatus.InTireShop]: '#993366',
	[EVehicleStatus.InWheelsAlignment]: '#FF9900',
	[EVehicleStatus.BackToOffice]: '#ff6600',
};
