import { GetListParams } from 'react-admin';
import { LazyLoadProperties } from '../interfaces/query.interface';
import { isNil } from 'lodash-es';
import { ESearchType } from '../enums';
import { endOfDay, format, startOfDay } from 'date-fns';

export const formatQueryParams = (params: GetListParams): LazyLoadProperties<any> => {
	const { page, perPage } = params.pagination;
	const { field, order } = params.sort;

	const query = params.filter?.q;
	delete params.filter?.q;

	const filterKeys = Object.keys(params.filter || {});

	const properties = {
		page,
		limit: perPage,
		sortFields: (field && order) ? [{ key: field, order: order === 'ASC' ? 1 : -1 }] : [],
		searchFields: filterKeys.filter(k => k !== 'search' && k !== 'q').map((key, i) => {
			if (params.filter[key] === undefined || params.filter[key] === 'undefined') {
				return null;
			}

			if (key.indexOf('from_') === 0) {
				const field = key.split('_').slice(1).join('_');
				const toKey = `to_${field}`;

				if (isNil(params.filter[toKey])) {
					return {
						key: field,
						from: (new Date(params.filter[key]) && !isNumeric(params.filter[key])) ? startOfDay(new Date(params.filter[key])) : params.filter[key],
						type: ESearchType.BETWEEN
					};
				} else {
					if (filterKeys.indexOf(toKey) > i) {
						const fromValue = (new Date(params.filter[key]) && !isNumeric(params.filter[key])) ? startOfDay(new Date(params.filter[key])) : params.filter[key];
						const toValue = (new Date(params.filter[toKey]) && !isNumeric(params.filter[toKey])) ? endOfDay(new Date(params.filter[toKey])) : params.filter[toKey];

						return {
							key: field,
							from: fromValue,
							to: toValue,
							type: ESearchType.BETWEEN
						};
					}
				}
			} else if (key.indexOf('to_') === 0) {
				const field = key.split('_').slice(1).join('_');
				const fromKey = `from_${field}`;

				if (isNil(params.filter[fromKey])) {
					return {
						key: field,
						to: (new Date(params.filter[key]) && !isNumeric(params.filter[key])) ? endOfDay(new Date(params.filter[key])) : params.filter[key],
						type: ESearchType.BETWEEN
					};
				} else {
					if (filterKeys.indexOf(fromKey) > i) {
						const toValue = (new Date(params.filter[key]) && !isNumeric(params.filter[key])) ? endOfDay(new Date(params.filter[key])) : params.filter[key];
						const fromValue = (new Date(params.filter[fromKey]) && !isNumeric(params.filter[fromKey])) ? startOfDay(new Date(params.filter[fromKey])) : params.filter[fromKey];

						return {
							key: field,
							from: fromValue,
							to: toValue,
							type: ESearchType.BETWEEN
						};
					}
				}
			} else if (key.indexOf('in_') === 0) {
				return {
					key: key.split('_').slice(1).join('_'),
					value: params.filter[key],
					type: ESearchType.IN
				};
			} else if (key.indexOf('enum_') === 0 || key.indexOf('eq_') === 0) {
				return {
					key: key.split('_').slice(1).join('_'),
					value: params.filter[key],
					type: ESearchType.EQUALS
				};
			} else {
				return {
					key,
					value: params.filter[key],
					type: typeof params.filter[key] === 'string' ? ESearchType.CONTAINS : ESearchType.EQUALS
				};
			}

			return null;
		}).filter(v => !isNil(v))
	} as LazyLoadProperties<any>;

	if (query) {
		properties.search = query;
	}

	return properties;
};

export const isNumeric = (value: any): boolean => {
	return !isNaN(value - parseFloat(value));
};

export const dateFormatter = (date: number): string => {
	if (date && new Date(date)?.toString() !== 'Invalid Date') {
		return format(new Date(date), 'dd.MM.yyyy');
	}

	return '';
};

export const vehiclesPlateNumberFormatter = (plate: string): string => {
	if (plate.length === 7) {
		return `${plate!.substring(0, 2)}-${plate!.substring(2, 5)}-${plate!.substring(5)}`;
	}
	if (plate.length === 8) {
		return `${plate!.substring(0, 3)}-${plate!.substring(3, 5)}-${plate!.substring(5)}`;
	}

	return plate;
};
