import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput, } from 'react-admin';
import { SolutionQuestionList } from './SolutionQuestionList';
import { SolutionQuestionDatagrid } from './SolutionQuestionDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { SolutionQuestion } from './SolutionQuestion';

export * from './SolutionQuestion';
export * from './SolutionQuestionCreate';
export * from './SolutionQuestionDatagrid';
export * from './SolutionQuestionEdit';
export * from './SolutionQuestionList';
export * from './SolutionQuestionShow';

const SolutionQuestionsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'solution', header: 'SOLUTION' },
	{ key: 'label', header: 'LABEL' },
	{ key: 'placeholder', header: 'PLACEHOLDER' },
	{ key: 'isRequired', header: 'IS_REQUIRED' },
	{ key: 'type', header: 'QUESTION_TYPE' },
];

export const SolutionQuestionsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: SolutionQuestion) => entity;

	const solutionQuestionsFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('שאלות פתרון', exportFields, exportDataMapper)}
				{...props}
				filters={solutionQuestionsFilter}
				bulkActionButtons={<SolutionQuestionsBulkActionButtons/>}
				title="SOLUTION_QUESTIONS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <SolutionQuestionList/> : <SolutionQuestionDatagrid/>}
			</List>
		</Fragment>
	);
};
