import React, { useEffect } from 'react';
import { Login, LoginForm, useRedirect } from 'react-admin';
import { EUserType } from '../enums';
import { LOCAL_STORAGE_USER_TYPE } from '../providers/constants';

export const CustomLoginPage = () => {
	return (
		<Login
			className="CustomLoginPage"
			title={'Kushikaro | פאנל ניהול'}
			backgroundImage="assets/login-background.jpg"
		>
			<LoginForm redirectTo="/redirectByType"/>
		</Login>);
};

export const RedirectByTypePage = () => {
	const redirect = useRedirect();
	const redirectByType = () => {
		const userType = localStorage.getItem(LOCAL_STORAGE_USER_TYPE);
		console.log(userType);
		if (userType === EUserType.Admin || userType === EUserType.GarageOwner) {
			return redirect('/');
		} else if (userType === EUserType.ServiceAdvisor) {
			return redirect('/clients');
		} else if (userType === EUserType.WorkshopManager) {
			return redirect('/solution-orders');
		}
	};


	useEffect(() => {
		redirectByType()
	},);

	return <div></div>;
};
