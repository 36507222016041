import * as React from 'react';
import { Datagrid, ImageField, TextField } from 'react-admin';
import './GarageDatagrid.scss';
import { StatusField } from '../components/StatusField';
import { ApprovalStatusToColor } from '../util/statuses-colors.config';
import { ColorField } from '../components/ColorField';

export const GarageDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="show" className="garages-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField sortable={false} source="id" label="#"/>
			<TextField source="name.he" label="NAME"/>
			<TextField source="address.addressStr" label="ADDRESS"/>
			<StatusField source="approvalStatus" label="APPROVAL_STATUS" enumName="EApprovalStatus"
			             enumToColors={ApprovalStatusToColor}/>
			<ColorField source="primaryColor" label="PRIMARY_COLOR"/>
			<ColorField source="backgroundColor" label="BACKGROUND_COLOR"/>
			<TextField source="description.he" label="DESCRIPTION"/>
			<ImageField source="logoImageUrl" label="LOGO_IMAGE"/>
			<ImageField source="coverImageUrl" label="COVER_IMAGE"/>
			<TextField source="openingHours.he" label="OPENING_HOURS"/>
			<TextField source="SOSHours.he" label="SOS_HOURS"/>
			<TextField source="code" label="CODE"/>
		</Datagrid>
	);
};
