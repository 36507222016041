import * as React from 'react';
import {
	EmailField,
	ImageField,
	Labeled,
	NumberField,
	Show,
	ShowProps,
	TabbedShowLayout,
	TextField,
	usePermissions,
	useTranslate,
} from 'react-admin';
import { PhoneField } from '../components/PhoneField';
import { GarageClientsView } from './GarageClientsView';
import { GarageEmployeesView } from './GarageEmployeesView';
import { GarageVehiclesView } from './GarageVehiclesView';
import { StatusField } from '../components/StatusField';
import { ApprovalStatusToColor } from '../util/statuses-colors.config';
import { WeekdaysField } from '../components/WeekdaysField';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { ColorField } from '../components/ColorField';
import { isAdminOrHigher } from '../providers/auth.provider';

export const styles = {
	accordionContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		justifyContent: 'start',
	},
	accordionLabels: {
		textAlign: 'start',
		'& .RaLabeled-label': {
			textAlign: 'start',
			fontWeight: 'bold',
		}
	}
};

export const GarageShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();
	const translate = useTranslate();

	return (
		<Show {...props} actions={isAdminOrHigher(permissions) ? undefined : false}>
			<TabbedShowLayout>
				<TabbedShowLayout.Tab label="DETAILS">
					<TextField source="id" label="#"/>
					<TextField source="name.he" label="NAME"/>
					<TextField source="name.en" label="EN_NAME"/>
					<StatusField source="approvalStatus" label="APPROVAL_STATUS" enumToColors={ApprovalStatusToColor}
					             enumName="EApprovalStatus"/>
					<TextField source="address.addressStr" label="ADDRESS"/>
					<ColorField source="primaryColor" label="PRIMARY_COLOR"/>
					<ColorField source="backgroundColor" label="BACKGROUND_COLOR"/>
					<TextField source="description.he" label="DESCRIPTION"/>
					<TextField source="description.en" label="EN_DESCRIPTION"/>
					<ImageField source="logoImageUrl" label="LOGO_IMAGE"/>
					<ImageField source="coverImageUrl" label="COVER_IMAGE"/>
					<TextField source="openingHours.he" label="OPENING_HOURS"/>
					<TextField source="openingHours.en" label="EN_OPENING_HOURS"/>
					<TextField source="SOSHours.he" label="SOS_HOURS"/>
					<TextField source="SOSHours.en" label="EN_SOS_HOURS"/>
					<WeekdaysField source="weekAvailability" label="AVAILABLE_DAYS"/>
					<TextField source="code" label="CODE"/>
					<Accordion>
						<AccordionSummary>
							<Typography>{translate('CONTACT_DETAILS_LABEL')}</Typography>
						</AccordionSummary>
						<AccordionDetails sx={styles.accordionContent}>
							<Box><Labeled sx={styles.accordionLabels}><TextField source="contactDetails.name"
							                                                     label="CONTACT_DETAILS.NAME"/></Labeled></Box>
							<Box><Labeled sx={styles.accordionLabels}><PhoneField source="contactDetails.phone"
							                                                      label="CONTACT_DETAILS.PHONE"/></Labeled></Box>
							<Box><Labeled sx={styles.accordionLabels}><PhoneField source="contactDetails.whatsappNumber"
							                                                      label="CONTACT_DETAILS.WHATSAPP_NUMBER"/></Labeled></Box>
							<Box><Labeled sx={styles.accordionLabels}><EmailField source="contactDetails.email"
							                                                      label="CONTACT_DETAILS.EMAIL"/></Labeled></Box>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary>
							<Typography>{translate('SOS_CONTACT_DETAILS_LABEL')}</Typography>
						</AccordionSummary>
						<AccordionDetails sx={styles.accordionContent}>
							<Box><Labeled sx={styles.accordionLabels}><TextField source="SOSContactDetails.name"
							                                                     label="SOS_CONTACT_DETAILS.NAME"/></Labeled></Box>
							<Box><Labeled sx={styles.accordionLabels}><PhoneField source="SOSContactDetails.phone"
							                                                      label="SOS_CONTACT_DETAILS.PHONE"/></Labeled></Box>
							<Box><Labeled sx={styles.accordionLabels}><PhoneField
								source="SOSContactDetails.whatsappNumber"
								label="SOS_CONTACT_DETAILS.WHATSAPP_NUMBER"/></Labeled></Box>
							<Box><Labeled sx={styles.accordionLabels}><EmailField source="SOSContactDetails.email"
							                                                      label="SOS_CONTACT_DETAILS.EMAIL"/></Labeled></Box>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary>
							<Typography>{translate('SETTINGS')}</Typography>
						</AccordionSummary>
						<AccordionDetails sx={styles.accordionContent}>
							<Box><Labeled sx={styles.accordionLabels}><NumberField
								source="reportSettings.treatmentExceededWarningTriggerTimeInMinutes"
								label="TREATMENT_EXCEEDED_WARNING_TRIGGER_TIME_IN_MINUTES"/></Labeled></Box>
							<Box><Labeled sx={styles.accordionLabels}><NumberField
								source="reportSettings.treatmentExceededExceptionTriggerTimeInMinutes"
								label="TREATMENT_EXCEEDED_EXCEPTION_TRIGGER_TIME_IN_MINUTES"/></Labeled></Box>
							<Box><Labeled sx={styles.accordionLabels}><NumberField
								source="reportSettings.priceQuoteExceededExceptionTriggerInMinutes"
								label="PRICE_QUOTE_EXCEEDED_EXCEPTION_TRIGGER_IN_MINUTES"/></Labeled></Box>
							<Box><Labeled sx={styles.accordionLabels}><NumberField
								source="reportSettings.livePriceQuoteExceededExceptionTriggerInMinutes"
								label="LIVE_PRICE_QUOTE_EXCEEDED_EXCEPTION_TRIGGER_IN_MINUTES"/></Labeled></Box>
						</AccordionDetails>
					</Accordion>
				</TabbedShowLayout.Tab>
				<TabbedShowLayout.Tab label="CLIENTS" path="clients">
					<GarageClientsView/>
				</TabbedShowLayout.Tab>
				<TabbedShowLayout.Tab label="EMPLOYEES" path="employees">
					<GarageEmployeesView/>
				</TabbedShowLayout.Tab>
				<TabbedShowLayout.Tab label="VEHICLES" path="vehicles">
					<GarageVehiclesView/>
				</TabbedShowLayout.Tab>
				{/*<TabbedShowLayout.Tab label="SOLUTIONS" path="solutions">*/}
				{/*	<GarageSolutionsView/>*/}
				{/*</TabbedShowLayout.Tab>*/}
			</TabbedShowLayout>
		</Show>
	);
};
