import * as React from 'react';
import {
	BooleanField,
	ReferenceField,
	Show,
	ShowProps,
	SimpleShowLayout,
	TextField,
	usePermissions,
} from 'react-admin';
import { isAdminOrHigher, isGarageOwnerOrHigher } from '../providers/auth.provider';

export const EmployeeShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={isGarageOwnerOrHigher(permissions) ? undefined : false}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				<ReferenceField source="userId" reference="users" label="USER" link="show">
					<TextField source="fullName"/>
				</ReferenceField>

				{isAdminOrHigher(permissions) &&
                    <ReferenceField source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField source="name.he"/>
                </ReferenceField>}

				<BooleanField source="isManager" label="IS_MANAGER"/>
			</SimpleShowLayout>
		</Show>
	);
};
