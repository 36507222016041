import * as React from 'react';
import {
	AutocompleteInput,
	BooleanInput,
	Edit,
	EditProps,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput
} from 'react-admin';
import { EQuestionType } from '../enums';

export const SolutionQuestionEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<ReferenceInput
					label="SOLUTION"
					source="solution" reference="solutions"
					alwaysOn
					validate={[required()]}
				>
					<AutocompleteInput label="SOLUTION" optionText="id" validate={[required()]}/>
				</ReferenceInput>

				<TextInput source="label" label="LABEL" validate={[required()]}/>
				<TextInput source="placeholder" label="PLACEHOLDER" validate={[required()]}/>
				<BooleanInput source="isRequired" label="IS_REQUIRED" validate={[required()]}/>
				<SelectInput source="type" label="QUESTION_TYPE" validate={[required()]}
				             choices={Object.keys(EQuestionType).map(key => ({
					             id: key,
					             name: `EQuestionType.${EQuestionType[key]}`
				             }))}
				/>
			</SimpleForm>
		</Edit>
	);
};
