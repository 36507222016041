import React, { PropsWithChildren } from 'react';
import { FunctionField, useTranslate } from 'react-admin';
import _get from 'lodash-es/get';

export const TranslateField: any = ({ namespace, ...props }: PropsWithChildren<any>) => {
	const translate = useTranslate();

	return (
		<FunctionField {...props}
		               render={(record, source) => {
			               const value = _get(record, source);

			               if (value) {
				               return translate(`${namespace ? `${namespace}.` : ''}${value}`);
			               } else {
				               return null;
			               }
		               }}
		/>
	);
};
