import * as React from 'react';
import {
	BooleanField,
	ReferenceField,
	Show,
	ShowProps,
	SimpleShowLayout,
	TextField,
	usePermissions,
} from 'react-admin';
import { TranslateField } from '../components/TranslateField';
import { isGarageOwnerOrHigher } from '../providers/auth.provider';

export const SolutionQuestionShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={isGarageOwnerOrHigher(permissions) ? undefined : false}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				<ReferenceField source="solution" reference="solutions" label="SOLUTION" link="show">
					<TextField source="id"/>
				</ReferenceField>

				<TextField source="label" label="LABEL"/>
				<TextField source="placeholder" label="PLACEHOLDER"/>
				<BooleanField source="isRequired" label="IS_REQUIRED"/>
				<TranslateField source="type" label="QUESTION_TYPE"/>
			</SimpleShowLayout>
		</Show>
	);
};
