export const englishMessages = {
	ra: {
		notification: {
			http_error: 'Network error. Please retry',
		},
		action: {
			save: 'Save',
			delete: 'Delete',
		},
	},
};
