import * as React from 'react';
import { ReferenceField, Show, ShowProps, TabbedShowLayout, TextField, usePermissions, } from 'react-admin';
import { SolutionFeedbackAnswersView } from './SolutionFeedbackAnswersView';
import { isAdminOrHigher, isGarageOwnerOrHigher } from '../providers/auth.provider';

export const SolutionFeedbackShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={isGarageOwnerOrHigher(permissions) ? undefined : false}>
			<TabbedShowLayout>
				<TabbedShowLayout.Tab label="DETAILS">
					<TextField source="id" label="#"/>
					{isAdminOrHigher(permissions) &&
                        <ReferenceField source="garageId" reference="garages" label="GARAGE" link="show">
                            <TextField source="name.he"/>
                        </ReferenceField>}

					<ReferenceField source="orderId" reference="solution-orders" label="SOLUTION_ORDER" link="show">
						<TextField source="client.user.fullName"/>
					</ReferenceField>

					<TextField source="review" label="REVIEW"/>
				</TabbedShowLayout.Tab>
				<TabbedShowLayout.Tab label="FEEDBACK_ANSWERS" path="answers">
					<SolutionFeedbackAnswersView/>
				</TabbedShowLayout.Tab>
			</TabbedShowLayout>
		</Show>
	);
};
