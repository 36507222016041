import React, { FC, HtmlHTMLAttributes, memo, PropsWithChildren } from 'react';
import { FieldProps, FunctionField, useTranslate } from 'react-admin';
import _get from 'lodash-es/get';
import { Box } from '@mui/material';

export const statusStyles = {
	box: {
		padding: '.5rem 1rem',
		borderRadius: '.25rem',
		border: '1px solid',
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontWeight: 'bold',
	}
};

export const StatusField: FC<StatusFieldProps> = memo<StatusFieldProps>(({
	                                                                         namespace,
	                                                                         enumName,
	                                                                         enumToColors,
	                                                                         ...props
                                                                         }: PropsWithChildren<any>) => {
	const styles = statusStyles;
	const translate = useTranslate();

	return (
		<FunctionField {...props}
		               render={(record, source) => {
			               const value = _get(record, source);
			               if (!value) return null;

			               return <Box sx={{
				               ...styles.box,
				               borderColor: enumToColors[value],
				               color: enumToColors[value]
			               }}>
				               {translate(`${namespace ? `${namespace}.` : ''}${enumName ? `${enumName}.` : ''}${value || ''}`)}
			               </Box>;
		               }}
		/>
	);
});

StatusField.displayName = 'StatusField';

export interface StatusFieldProps
	extends FieldProps,
		HtmlHTMLAttributes<HTMLAnchorElement> {
	enumToColors: { [key: string]: string };
	enumName: string;
}

