import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	AutocompleteInput,
	BulkActionProps,
	BulkDeleteButton,
	DateInput,
	List,
	ListProps,
	ReferenceInput,
	TextInput, usePermissions,
} from 'react-admin';
import { FeedbackQuestionList } from './FeedbackQuestionList';
import { FeedbackQuestionDatagrid } from './FeedbackQuestionDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { FeedbackQuestion } from './FeedbackQuestion';
import { isAdminOrHigher } from '../providers/auth.provider';

export * from './FeedbackQuestion';
export * from './FeedbackQuestionCreate';
export * from './FeedbackQuestionDatagrid';
export * from './FeedbackQuestionEdit';
export * from './FeedbackQuestionList';
export * from './FeedbackQuestionShow';

const FeedbackQuestionsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'garage', header: 'GARAGE' },
	{ key: 'label.he', header: 'LABEL' },
];

export const FeedbackQuestionsData = (props: ListProps): React.ReactElement => {
	const { permissions } = usePermissions();

	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: FeedbackQuestion) => entity;

	const feedbackQuestionsFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		...(isAdminOrHigher(permissions)) ? [<ReferenceInput
			label="GARAGE"
			source="garageId" reference="garages"
			alwaysOn
		>
			<AutocompleteInput label="GARAGE" optionText="name.he"/>
		</ReferenceInput>] : [],
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('שאלות משו״ב', exportFields, exportDataMapper)}
				{...props}
				filters={feedbackQuestionsFilter}
				bulkActionButtons={<FeedbackQuestionsBulkActionButtons/>}
				title="FEEDBACK_QUESTIONS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <FeedbackQuestionList/> : <FeedbackQuestionDatagrid/>}
			</List>
		</Fragment>
	);
};
