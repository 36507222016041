import * as React from 'react';
import { Datagrid, DateField, ImageField, NumberField, ReferenceField, TextField, usePermissions } from 'react-admin';
import { TranslateField } from '../components/TranslateField';
import './VehicleDatagrid.scss';
import { ApprovalStatusToColor } from '../util/statuses-colors.config';
import { StatusField } from '../components/StatusField';
import { isAdminOrHigher } from '../providers/auth.provider';

export const VehicleDatagrid = (): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="edit" className="vehicles-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField sortable={false} source="id" label="#"/>
			<ReferenceField sortable={false} source="ownerId" reference="clients" label="OWNER" link="show">
				<TextField sortable={false} source="user.fullName"/>
			</ReferenceField>

			{isAdminOrHigher(permissions) &&
                <ReferenceField sortable={false} source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField sortable={false} source="name.he"/>
                </ReferenceField>}

			<StatusField source="approvalStatus" label="APPROVAL_STATUS"
			             enumName="EApprovalStatus"
			             enumToColors={ApprovalStatusToColor}/>

			<ImageField source="imageUrl" label="IMAGE"/>
			<TextField source="plateNumber" label="PLATE_NUMBER"/>
			<TextField source="brand" label="BRAND"/>
			<TextField source="model" label="MODEL"/>
			<DateField locales="he-IL" source="testExpiresOn" label="TEST_EXPIRES_ON"/>
			<TranslateField source="type" label="TYPE"/>
			<TextField source="year" label="YEAR"/>
			<NumberField source="horsePower" label="HORSE_POWER"/>
			<NumberField source="engineSize" label="ENGINE_SIZE"/>
			<TranslateField source="gearType" label="GEAR_TYPE"/>
			<TextField source="color" label="COLOR"/>
		</Datagrid>
	);
};
