import * as React from 'react';
import {
	ArrayField,
	DateField,
	ImageField,
	ReferenceField,
	Show,
	ShowProps,
	SingleFieldList,
	TabbedShowLayout,
	TextField,
	usePermissions,
} from 'react-admin';
import { GalleryField } from '../components/GalleryField';
import { StatusField } from '../components/StatusField';
import { SolutionOrderStatusToColor } from '../util/statuses-colors.config';
import { TranslateField } from '../components/TranslateField';
import { Box } from '@mui/system';
import { columnStart } from '../theme';
import { SolutionOrderPriceQuotesView } from './SolutionOrderPriceQuotesView';
import { VideoField } from '../components/VideoField';
import { isAdminOrHigher, isWorkshopManagerOrHigher } from '../providers/auth.provider';

const styles = {
	columnStart: columnStart
};

export const SolutionOrderShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={isWorkshopManagerOrHigher(permissions) ? undefined : false}>
			<TabbedShowLayout>
				<TabbedShowLayout.Tab label="DETAILS">
					<TextField source="id" label="#"/>
					<DateField source="_createdAt" label="CREATED_AT" showTime options={{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: '2-digit',
						minute: '2-digit'
					}} locales="he-IL"/>
					{isAdminOrHigher(permissions) &&
                        <ReferenceField source="garageId" reference="garages" label="GARAGE" link="show">
						<TextField source="name.he"/>
					</ReferenceField>}
					<ReferenceField source="clientId" reference="clients" label="CLIENT" link="show">
						<TextField source="user.fullName"/>
					</ReferenceField>
					<ReferenceField source="solutionId" reference="solutions" label="SOLUTION" link="show">
						<TextField source="name.he"/>
					</ReferenceField>
					<ReferenceField source="assigneeId" label="ASSIGNEE" reference="employees" link="show">
						<TextField source="user.fullName"/>
					</ReferenceField>
					<ReferenceField source="vehicleId" reference="vehicles" label="PLATE_NUMBER" link="show">
						<TextField source="plateNumber"/>
					</ReferenceField>
					<ReferenceField source="vehicleId" reference="vehicles" label="BRAND" link="show">
						<TextField source="brand"/>
					</ReferenceField>
					<StatusField source="status" label="ORDER_STATUS" enumToColors={SolutionOrderStatusToColor}
					             enumName="ESolutionOrderStatus"/>
					<TranslateField source="vehicleStatus" label="VEHICLE_STATUS" namespace="EVehicleStatus"/>
					<DateField source="startedAt" label="STARTED_AT" showTime options={{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: '2-digit',
						minute: '2-digit'
					}} locales="he-IL"/>
					<DateField source="finishedAt" label="FINISHED_AT" showTime options={{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: '2-digit',
						minute: '2-digit'
					}} locales="he-IL"/>
					<DateField source="scheduledAppointment" label="SCHEDULED_APPOINTMENT" showTime options={{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: '2-digit',
						minute: '2-digit'
					}} locales="he-IL"/>
					<TranslateField source="vehicleDrivability" label="VEHICLE_DRIVABILITY"/>
					<ImageField source="drivingLicenseImageUrl" label="DRIVING_LICENSE_IMAGE"/>
					<ImageField source="personalIdImageUrl" label="PERSONAL_ID_IMAGE"/>
					<ImageField source="insuranceDocumentUrl" label="INSURANCE_DOCUMENT"/>
					<VideoField source="videoUrl" label="VIDEO"/>
					<GalleryField source="gallery" label="GALLERY"/>
					<TextField source="transportationAddress.addressStr" label="TRANSPORTATION_ADDRESS"/>
					<TextField source="transportationContactName" label="TRANSPORTATION_CONTACT_NAME"/>
					<TextField source="transportationContactPhone" label="TRANSPORTATION_CONTACT_PHONE"/>
					<ArrayField source="answers" label="ANSWERS">
						<SingleFieldList linkType={false} sx={styles.columnStart}>
							<Box sx={styles.columnStart}>
								<ReferenceField reference="solution-questions" source="questionId" link={false}>
									<TextField source="label.he" sx={{ fontWeight: 'bold' }}/>
								</ReferenceField>
								<TextField source="content"/>
							</Box>
						</SingleFieldList>
					</ArrayField>
				</TabbedShowLayout.Tab>
				<TabbedShowLayout.Tab label="PRICE_QUOTE" path="price-quotes">
					<SolutionOrderPriceQuotesView/>
				</TabbedShowLayout.Tab>
			</TabbedShowLayout>
		</Show>
	);
};
