import * as React from 'react';
import { Create, CreateProps, SimpleForm } from 'react-admin';
import { EmployeeForm } from './EmployeeForm';

export const EmployeeCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props} redirect="list">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<EmployeeForm/>
			</SimpleForm>
		</Create>
	);
};
