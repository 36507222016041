import * as React from 'react';
import { Datagrid, DateField, ReferenceField, TextField, usePermissions } from 'react-admin';
import './GarageDowntimeDatagrid.scss';
import { isAdminOrHigher } from '../providers/auth.provider';

export const GarageDowntimeDatagrid = (): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="edit" className="garage-downtimes-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField sortable={false} source="id" label="#"/>
			{isAdminOrHigher(permissions) &&
                <ReferenceField sortable={false} source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField sortable={false} source="name.he"/>
                </ReferenceField>}

			<DateField locales="he-IL" source="from" label="FROM_DATE"/>
			<DateField locales="he-IL" source="to" label="TO_DATE"/>
			<TextField source="reason.he" label="REASON"/>
		</Datagrid>
	);
};
