import * as React from 'react';
import {
	BooleanField,
	EditButton,
	Labeled,
	ReferenceField,
	ShowButton,
	TextField,
	useListContext,
	usePermissions
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { isAdminOrHigher } from '../providers/auth.provider';

const employeeStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const EmployeeList = () => {
	const { permissions } = usePermissions();

	const classes = employeeStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="employees"
										record={entity}
									/>
									<EditButton
										resource="employees"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={entity} source="id" label="#"/>
						</Labeled>
						<Labeled label="USER" sx={classes.cardContentRow}>
							<ReferenceField record={entity} source="userId" label="FORUM" reference="users" link="show">
								<TextField source="fullName"/>
							</ReferenceField>

						</Labeled>
						{isAdminOrHigher(permissions) && <Labeled label="GARAGE" sx={classes.cardContentRow}>
                            <ReferenceField record={entity} source="garageId" label="FORUM" reference="garages"
                                            link="show">
                                <TextField source="name.he"/>
                            </ReferenceField>
                        </Labeled>}
						<Labeled label="IS_MANAGER" sx={classes.cardContentRow}>
							<BooleanField record={entity} source="isManager" label="IS_MANAGER"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
