import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useTranslate } from 'react-admin';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';
import { SegmentationReport } from '../../reports/reports.interface';

const BarsSegmentationChart = (props: {
	segmentation?: SegmentationReport,
	key?: string,
	header: string,
	unit?: string,
	labelPrefix?: string,
	formatter?: Formatter<any, any>
}) => {
	const translator = useTranslate();
	const { segmentation, header, labelPrefix } = props;
	if (!segmentation) return null;

	const prepareData = (segmentation: { name: string, value: number }[]) => {
		return segmentation.map(segment => ({
			name: translator(`${labelPrefix ? `${labelPrefix}.` : ''}${segment.name}`),
			value: parseFloat(parseFloat(segment.value?.toString()).toFixed(2))
		}));
	};

	const data = prepareData(segmentation);

	return (
		<Card>
			<CardHeader title={translator(header ?? 'MONTHLY_PURCHASES')}/>
			<CardContent sx={{ paddingBottom: '3rem !important', paddingTop: '1rem' }}>
				<div style={{ width: '100%', maxWidth: '90vw', height: '100%' }}>
					<ResponsiveContainer height={300}>
						<BarChart data={data}>
							<XAxis dataKey="name"/>
							<YAxis dataKey="value"/>
							<Tooltip/>
							<Bar dataKey="value" fill="#8884d8">
								<LabelList dataKey="value" position="top"/>
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</div>
			</CardContent>
		</Card>
	);
};

export default BarsSegmentationChart;
