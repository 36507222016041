import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useTranslate } from 'react-admin';
import { addDays, format, subDays } from 'date-fns';
import { Entity } from '../../interfaces/entity.interface';
import { dateFormatter } from '../../util/Formatters';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';

const EntityChart = (props: {
	entities?: Entity[],
	key?: string,
	header: string,
	unit?: string,
	formatter?: Formatter<any, any>
}) => {
	const { entities, key, header, unit, formatter } = props;
	const translate = useTranslate();

	const referenceDate = new Date();
	const lastDay = referenceDate;
	const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
	const aMonthAgo = subDays(referenceDate, 30);

	if (!entities) return null;

	const aggregateEntitiesByDay = (entities: Entity[]): { [key: string]: number } =>
		entities
			.reduce((acc, curr) => {
				const day = format(new Date(curr._createdAt), 'dd.MM.yyyy');
				if (!acc[day]) {
					acc[day] = 0;
				}
				acc[day] += (key ? curr[key] : 1) || 0;
				return acc;
			}, {} as { [key: string]: number });

	const getRevenuePerDay = (entities: Entity[]): TotalByDay[] => {
		const daysWithRevenue = aggregateEntitiesByDay(entities);
		return lastMonthDays.map(date => ({
			date: new Date(date).getTime(),
			total: daysWithRevenue[format(date, 'dd.MM.yyyy')] || 0
		}));
	};

	return (
		<Card>
			<CardHeader title={translate(header ?? 'MONTHLY_PURCHASES')}/>
			<CardContent>
				<div style={{ width: '100%', height: 300 }}>
					<ResponsiveContainer>
						<AreaChart data={getRevenuePerDay(entities)}>
							<defs>
								<linearGradient
									id="colorUv"
									x1="0"
									y1="0"
									x2="0"
									y2="1"
								>
									<stop
										offset="5%"
										stopColor="#8884d8"
										stopOpacity={0.8}
									/>
									<stop
										offset="95%"
										stopColor="#8884d8"
										stopOpacity={0}
									/>
								</linearGradient>
							</defs>
							<XAxis
								dataKey="date"
								name="Date"
								type="number"
								scale="time"
								domain={[
									addDays(aMonthAgo, 1).getTime(),
									referenceDate.getTime()
								]}
								tickFormatter={dateFormatter}
							/>
							<YAxis dataKey="total" name="Revenue" unit={unit ?? ''}/>
							<CartesianGrid strokeDasharray="3 3"/>
							<Tooltip
								cursor={{ strokeDasharray: '3 3' }}
								formatter={formatter ?? (v => v)}
								labelFormatter={(label: any) =>
									dateFormatter(label)
								}
							/>
							<Area
								type="monotone"
								dataKey="total"
								stroke="#8884d8"
								strokeWidth={2}
								fill="url(#colorUv)"
							/>
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</CardContent>
		</Card>
	);
};

interface TotalByDay {
	date: number;
	total: number;
}

export default EntityChart;
