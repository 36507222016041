import Assistant from '@mui/icons-material/Assistant';
import EventBusy from '@mui/icons-material/EventBusy';
import Grading from '@mui/icons-material/Grading';
import Receipt from '@mui/icons-material/Receipt';
import DirectionsCar from '@mui/icons-material/DirectionsCar';
import Person from '@mui/icons-material/Person';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import WorkOutline from '@mui/icons-material/WorkOutline';
import Warehouse from '@mui/icons-material/Warehouse';
import React, { useState } from 'react';
import Dashboard from '@mui/icons-material/Dashboard';
import Notifications from '@mui/icons-material/Notifications';
import { MenuItemLink, usePermissions, useTranslate } from 'react-admin';
import { Box } from '@mui/system';
import SubMenu from './SubMenu';
import {
	isAdminOrHigher,
	isGarageOwnerOrHigher,
	isServiceAdvisorOrHigher,
	isWorkshopManagerOrHigher
} from '../providers/auth.provider';
import { EUserType } from '../enums';

interface MenuItem {
	name: string;
	text: string;
	icon: any;
	to?: () => string;
	items?: MenuItem[];
	isOpen?: boolean;
}

const adminMenuItems: MenuItem[] = [
	{ name: 'garages', text: 'GARAGES', icon: <Warehouse/> },
	{ name: 'clients', text: 'CLIENTS', icon: <Person/> },
	{
		name: 'solution-orders', text: 'SOLUTION_ORDERS', icon: <ShoppingCart/>, items: [
			{ name: 'not-yet-done', text: 'NOT_YET_DONE', icon: <></> },
			{ name: 'history', text: 'HISTORY', icon: <></> },
			{ name: 'exceptions', text: 'EXCEPTIONS', icon: <></> }
		]
	},
	{ name: 'price-quotes', text: 'PRICE_QUOTES', icon: <Receipt/> },
	{ name: 'vehicles', text: 'VEHICLES', icon: <DirectionsCar/> },
	{ name: 'employees', text: 'EMPLOYEES', icon: <WorkOutline/> },
	{ name: 'solutions', text: 'SOLUTIONS', icon: <SettingsApplicationsIcon/> },
	{ name: 'feedback-questions', text: 'FEEDBACK_QUESTIONS', icon: <Grading/> },
	{ name: 'solution-feedbacks', text: 'SOLUTION_FEEDBACKS', icon: <Assistant/> },
	{ name: 'downtimes', text: 'GARAGE_DOWNTIMES', icon: <EventBusy/> },
	{ name: 'reports', text: 'REPORTS', icon: <AssessmentIcon/>, to: () => `reports` },
	{ name: 'notifications', text: 'NOTIFICATIONS', icon: <Notifications/>, to: () => `notifications/create` }
];

const garageOwnerMenuItems: MenuItem[] = [
	{ name: 'clients', text: 'CLIENTS', icon: <Person/> },
	{
		name: 'solution-orders', text: 'SOLUTION_ORDERS', icon: <ShoppingCart/>, items: [
			{ name: 'not-yet-done', text: 'NOT_YET_DONE', icon: <></> },
			{ name: 'history', text: 'HISTORY', icon: <></> },
			{ name: 'exceptions', text: 'EXCEPTIONS', icon: <></> }
		]
	},
	{ name: 'price-quotes', text: 'PRICE_QUOTES', icon: <Receipt/> },
	{ name: 'vehicles', text: 'VEHICLES', icon: <DirectionsCar/> },
	{ name: 'employees', text: 'EMPLOYEES', icon: <WorkOutline/> },
	{ name: 'solutions', text: 'SOLUTIONS', icon: <SettingsApplicationsIcon/> },
	{ name: 'feedback-questions', text: 'FEEDBACK_QUESTIONS', icon: <Grading/> },
	{ name: 'solution-feedbacks', text: 'SOLUTION_FEEDBACKS', icon: <Assistant/> },
	{ name: 'downtimes', text: 'GARAGE_DOWNTIMES', icon: <EventBusy/> },
	{ name: 'reports', text: 'REPORTS', icon: <AssessmentIcon/>, to: () => `reports` },
];

const serviceAdvisorMenuItems: MenuItem[] = [
	{ name: 'clients', text: 'CLIENTS', icon: <Person/> },
	{
		name: 'solution-orders', text: 'SOLUTION_ORDERS', icon: <ShoppingCart/>, items: [
			{ name: 'not-yet-done', text: 'NOT_YET_DONE', icon: <></> },
			{ name: 'history', text: 'HISTORY', icon: <></> },
			{ name: 'exceptions', text: 'EXCEPTIONS', icon: <></> }
		]
	},
	{ name: 'price-quotes', text: 'PRICE_QUOTES', icon: <Receipt/> },
	{ name: 'vehicles', text: 'VEHICLES', icon: <DirectionsCar/> },
];

const workshopManagerMenuItems: MenuItem[] = [
	{
		name: 'solution-orders', text: 'SOLUTION_ORDERS', icon: <ShoppingCart/>, items: [
			{ name: 'not-yet-done', text: 'NOT_YET_DONE', icon: <></> },
			{ name: 'history', text: 'HISTORY', icon: <></> },
			{ name: 'exceptions', text: 'EXCEPTIONS', icon: <></> }
		]
	},
];

const useStyles = {
	menuContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '240px',
		backgroundImage: 'linear-gradient(135deg, #2f3ea0, #ae342d)'
	},
	sideBarLogo: {
		maxHeight: '4rem',
		padding: '1rem'
	},
	sideBarLogoImage: {
		maxHeight: '3rem'
	},
	menuItem: {
		padding: '1rem !important',
		color: 'white !important',
		'& .MuiListItemIcon-root': {
			color: 'white'
		},
		'&[class*="-active"]': {
			fontWeight: 'bold',
			backgroundColor: '#ffffff66 !important'
		}
	},
	subMenuItem: {
		padding: '1rem 2rem !important'
	},
	logout: {
		marginTop: 'auto'
	}
};

export const CustomMenu = ({ onMenuClick }) => {
	const translator = useTranslate();
	const { permissions } = usePermissions();
	const [state, setState] = useState(false);

	const classes = useStyles;

	const handleToggle = (item: MenuItem) => {
		item.isOpen = !item.isOpen;
		setState(!state);
	};

	const getMenuItemsByUserType = (userType: EUserType) => {
		if (isAdminOrHigher(userType)) {
			return adminMenuItems;
		}
		if (isGarageOwnerOrHigher(userType)) {
			return garageOwnerMenuItems;
		}
		if (isServiceAdvisorOrHigher(userType)) {
			return serviceAdvisorMenuItems;
		}
		if (isWorkshopManagerOrHigher(userType)) {
			return workshopManagerMenuItems;
		}
		return [];
	};

	return (
		<Box sx={classes.menuContainer}>
			<Box sx={classes.sideBarLogo}>
				<Box
					component="img"
					sx={classes.sideBarLogoImage} alt="Company Logo" src="/assets/logo-with-name.png"/>
			</Box>
			{isGarageOwnerOrHigher(permissions) && <MenuItemLink
				{...[] as any}
                to="/"
                exact="/"
                sx={useStyles.menuItem}
                primaryText={translator('DASHBOARD')}
                leftIcon={<Dashboard/>}
                onClick={onMenuClick}
            />
			}
			{getMenuItemsByUserType(permissions).map(item =>
				item.items?.length ?
					<SubMenu
						key={item.name}
						handleToggle={() => handleToggle(item)}
						isOpen={item.isOpen ?? false}
						name={item.text}
						icon={item.icon}
						dense={false}
					>
						{(item.items.map(subItem =>
							<MenuItemLink
								{...[] as any}
								sx={{ ...useStyles.menuItem, ...useStyles.subMenuItem }}
								key={subItem.to ? subItem.to() : subItem.name}
								to={`/${item.to ? item.to() : item.name}/${subItem.to ? subItem.to() : subItem.name}`}
								primaryText={translator(subItem.text)}
								leftIcon={subItem.icon}
								onClick={onMenuClick}
								dense={true}
							/>
						))}
					</SubMenu>
					: <MenuItemLink
						{...[] as any}
						sx={useStyles.menuItem}
						key={item.to ? item.to() : item.name}
						to={`/${item.to ? item.to() : item.name}`}
						primaryText={translator(item.text)}
						leftIcon={item.icon}
						onClick={onMenuClick}
						dense={true}
					/>
			)}
		</Box>
	);
};
