import React from 'react';
import _get from 'lodash-es/get';
import './GalleryField.scss';
import { Dialog, DialogTitle } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';

const FullImageDialog = ({ onClose, open, imageUrl }) => {
	const handleClose = () => onClose();
	const translator = useTranslate();

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{translator('FULL_IMAGE')}</DialogTitle>
			<img className="gallery-image" alt="Gallery"
			     style={{
				     borderRadius: '4px'
			     }}
			     src={imageUrl}/>
		</Dialog>
	);
};

export const GalleryField: any = ({ record, source, label }) => {
	record = useRecordContext();

	const [selectedImageUrl, setSelectedImageUrl] = React.useState<string>('');

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = (imageUrl: string) => {
		setSelectedImageUrl(imageUrl);
		setOpen(true);
	};

	const handleClose = (imageUrl) => {
		setOpen(false);

	};

	const gallery: string[] = _get(record, source);
	try {
		return <div style={{ display: 'flex', gap: '1rem' }}>
			{(gallery?.length) ? gallery.map((imageUrl, i) =>
				<img
					onClick={() => handleClickOpen(imageUrl)}
					className="gallery-image" alt="Gallery"
					style={{
						cursor: 'pointer',
						width: '6rem',
						maxHeight: '6rem',
						borderRadius: '4px'
					}}
					src={imageUrl} key={imageUrl + i}/>
			) : <></>}
			<FullImageDialog
				imageUrl={selectedImageUrl}
				open={open}
				onClose={handleClose}/>
		</div>;
	} catch (e) {
		return '';
	}
};
