import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, } from 'react-admin';
import { FeedbackAnswerList } from './FeedbackAnswerList';
import { FeedbackAnswerDatagrid } from './FeedbackAnswerDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { FeedbackAnswer } from './FeedbackAnswer';

export * from './FeedbackAnswer';
export * from './FeedbackAnswerCreate';
export * from './FeedbackAnswerDatagrid';
export * from './FeedbackAnswerEdit';
export * from './FeedbackAnswerList';
export * from './FeedbackAnswerShow';

const FeedbackAnswersBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'feedbackId', header: 'FEEDBACK' },
	{ key: 'questionId', header: 'QUESTION' },
	{ key: 'value', header: 'FEEDBACK_VALUE' },
];

export const FeedbackAnswersData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: FeedbackAnswer) => entity;

	const feedbackAnswersFilter = [
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('תשובות משו״ב', exportFields, exportDataMapper)}
				{...props}
				filters={feedbackAnswersFilter}
				bulkActionButtons={<FeedbackAnswersBulkActionButtons/>}
				title="FEEDBACK_ANSWERS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <FeedbackAnswerList/> : <FeedbackAnswerDatagrid/>}
			</List>
		</Fragment>
	);
};
