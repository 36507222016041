import * as React from 'react';
import {
	ArrayInput,
	AutocompleteInput,
	DateTimeInput,
	FormDataConsumer,
	ImageField,
	ReferenceField,
	ReferenceInput,
	required,
	SelectInput,
	SimpleFormIterator,
	TextField,
	TextInput,
	usePermissions,
	useTranslate
} from 'react-admin';
import { MultipleImagesInput } from '../components/MultipleImagesInput';
import { ESolutionOrderStatus, EVehicleDrivability, EVehicleStatus } from '../enums';
import { useFormContext } from 'react-hook-form';
import GoogleAddressInput from '../components/GoogleAddressInput';
import { Box } from '@mui/system';
import { columnStart } from '../theme';
import { SingleImageInput } from '../components/SingleImageInput';
import { SingleVideoInput } from '../components/SingleVideoInput';
import { isAdminOrHigher, isWorkshopManager } from '../providers/auth.provider';
import { GalleryField } from '../components/GalleryField';
import { VideoField } from '../components/VideoField';

const styles = {
	columnStart: columnStart
};

export const SolutionOrderForm = (): React.ReactElement => {
	const { permissions } = usePermissions();

	const [garageId, setGarageId] = React.useState<number>();
	const [clientId, setClientId] = React.useState<number>();

	const form = useFormContext();
	const translate = useTranslate();

	return (
		<>
			{isAdminOrHigher(permissions) && <ReferenceInput
                label="GARAGE"
                source="garageId" reference="garages"
                alwaysOn
            >
                <AutocompleteInput label="GARAGE" optionText="name.he" validate={[required()]} onChange={(garageId) => {
					form.setValue('clientId', null);
					form.setValue('solutionId', null);
					form.setValue('assigneeId', null);
					form.setValue('vehicleId', null);
	                setClientId(undefined);
	                setGarageId(garageId);
                }} disabled={isWorkshopManager(permissions)}/>
            </ReferenceInput>}

			<ReferenceInput
				label="CLIENT"
				source="clientId" reference="clients"
				filter={garageId ? { 'garage': garageId } : undefined}
				alwaysOn
			>
				<AutocompleteInput label="CLIENT" optionText="user.fullName" validate={[required()]}
				                   onChange={(clientId) => {
					form.setValue('vehicleId', null);
					                   setClientId(clientId);
				}} disabled={isWorkshopManager(permissions)}/>
			</ReferenceInput>

			<ReferenceInput
				label="SOLUTION"
				source="solutionId" reference="solutions"
				filter={garageId ? { 'garage': garageId } : undefined}
				alwaysOn
			>
				<AutocompleteInput label="SOLUTION" optionText="name.he" validate={[required()]}
				                   disabled={isWorkshopManager(permissions)}/>
			</ReferenceInput>

			<ReferenceInput
				label="ASSIGNEE"
				source="assigneeId" reference="employees"
				filter={
					{
						...(garageId ? { 'garage': garageId } : {}),
					}
				}
				alwaysOn
			>
				<AutocompleteInput label="ASSIGNEE" optionText="user.fullName"
				                   disabled={isWorkshopManager(permissions)}/>
			</ReferenceInput>

			<ReferenceInput
				label="VEHICLE"
				source="vehicleId" reference="vehicles"
				filter={
					{
						...(garageId ? { 'garage': garageId } : {}),
						...(clientId ? { 'owner': clientId } : {}),
					}
				}
				alwaysOn
			>
				<AutocompleteInput label="VEHICLE" optionText="brand" validate={[required()]}
				                   disabled={isWorkshopManager(permissions)}/>
			</ReferenceInput>
			<SelectInput source="status" label="ORDER_STATUS"
			             defaultValue={ESolutionOrderStatus.NewOrder}
			             choices={Object.keys(ESolutionOrderStatus).map(key => ({
				             id: key,
				             name: `ESolutionOrderStatus.${ESolutionOrderStatus[key]}`
			             }))} disabled={isWorkshopManager(permissions)}/>
			<SelectInput source="vehicleStatus" label="VEHICLE_STATUS"
			             choices={[{ id: null, name: '' }, ...Object.keys(EVehicleStatus).map(key => ({
				             id: key,
				             name: `EVehicleStatus.${EVehicleStatus[key]}`
			             }))]}/>

			<h3>{translate('CLIENT_INPUTS')}</h3>

			<FormDataConsumer>
				{({ scopedFormData }) => {
					if (scopedFormData?.answers?.length > 0) {
						return (<ArrayInput
							label="ANSWERS"
							source="answers"
							defaultValue={[]}
							disabled={isWorkshopManager(permissions)}>
							<SimpleFormIterator inline sx={{
								padding: '.5rem',
								borderRadius: '.5rem',
								boxShadow: '0px 15px 20px #27313f0c',
								backgroundColor: 'white'
							}}>
								<FormDataConsumer>
									{({ scopedFormData, getSource }) => {

										return scopedFormData && scopedFormData.questionId && (
											<Box sx={styles.columnStart}>
												<ReferenceField reference="solution-questions"
												                source={getSource('questionId')}
												                link={false}>
													<TextField source="label.he" label="LABEL"/>
												</ReferenceField>
												<TextInput label="ANSWER" validate={[required()]}
												           source={getSource('content')}/>
											</Box>);
									}
									}
								</FormDataConsumer>
							</SimpleFormIterator>
						</ArrayInput>);
					} else {
						return <></>;
					}
				}}
			</FormDataConsumer>

			<DateTimeInput source="scheduledAppointment" label="SCHEDULED_APPOINTMENT"
			               disabled={isWorkshopManager(permissions)}/>
			<SelectInput source="vehicleDrivability" label="VEHICLE_DRIVABILITY"
			             choices={[{ id: null, name: '' }, ...Object.keys(EVehicleDrivability).map(key => ({
				             id: key,
				             name: `EVehicleDrivability.${EVehicleDrivability[key]}`
			             }))]} disabled={isWorkshopManager(permissions)}/>

			{!isWorkshopManager(permissions) &&
                <SingleImageInput source="drivingLicenseImageUrl" label="DRIVING_LICENSE_IMAGE"/>}
			{isWorkshopManager(permissions) &&
                <ImageField source="drivingLicenseImageUrl" label="DRIVING_LICENSE_IMAGE"/>}

			{!isWorkshopManager(permissions) &&
                <SingleImageInput source="insuranceDocumentUrl" label="INSURANCE_DOCUMENT"/>}
			{isWorkshopManager(permissions) && <ImageField source="insuranceDocumentUrl" label="INSURANCE_DOCUMENT"/>}

			{!isWorkshopManager(permissions) &&
                <SingleImageInput source="insuranceDocumentUrl" label="INSURANCE_DOCUMENT"/>}
			{isWorkshopManager(permissions) && <ImageField source="insuranceDocumentUrl" label="INSURANCE_DOCUMENT"/>}

			{!isWorkshopManager(permissions) &&
                <SingleImageInput source="insuranceDocumentUrl" label="INSURANCE_DOCUMENT"/>}
			{isWorkshopManager(permissions) && <ImageField source="insuranceDocumentUrl" label="INSURANCE_DOCUMENT"/>}

			{!isWorkshopManager(permissions) && <SingleVideoInput source="videoUrl" label="VIDEO"/>}
			{isWorkshopManager(permissions) && <VideoField source="videoUrl" label="VIDEO"/>}

			{!isWorkshopManager(permissions) && <MultipleImagesInput source="gallery" label="GALLERY"/>}
			{isWorkshopManager(permissions) && <GalleryField source="gallery" label="GALLERY"/>}

			{!isWorkshopManager(permissions) &&
                <GoogleAddressInput source="transportationAddress" label="TRANSPORTATION_ADDRESS"/>}
			{isWorkshopManager(permissions) && <TextInput source="transportationAddress" label="TRANSPORTATION_ADDRESS"
                                                          disabled={isWorkshopManager(permissions)}/>}

			<TextInput source="transportationContactName" label="TRANSPORTATION_CONTACT_NAME"
			           disabled={isWorkshopManager(permissions)}/>
			<TextInput source="transportationContactPhone" label="TRANSPORTATION_CONTACT_PHONE"
			           disabled={isWorkshopManager(permissions)}/>
		</>
	);
};
