import * as React from 'react';
import {
	AutocompleteInput,
	BooleanInput,
	Edit,
	EditProps,
	NumberInput,
	ReferenceInput,
	required,
	SimpleForm,
	TextInput, usePermissions
} from 'react-admin';
import { EWeekday } from '../enums';
import WeekdaysInput from '../components/WeekdaysInput';
import { SingleImageInput } from '../components/SingleImageInput';
import { isAdminOrHigher } from '../providers/auth.provider';

export const SolutionEdit = (props: EditProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				{isAdminOrHigher(permissions) && <ReferenceInput
                    label="GARAGE"
                    source="garageId" reference="garages"
                    disabled
                    validate={[required()]}
                >
                    <AutocompleteInput label="GARAGE" optionText="name.he" validate={[required()]}/>
                </ReferenceInput>}

				<NumberInput source="index" label="INDEX" validate={[required()]}/>
				<BooleanInput source="isEnabled" label="IS_ENABLED" validate={[required()]}/>
				<TextInput source="name.he" label="NAME" validate={[required()]}/>
				<SingleImageInput source="imageUrl" label="IMAGE" validate={[required()]}/>
				<NumberInput source="price" label="PRICE"/>
				{/*<BooleanInput source="shouldSelectVehicle" label="SHOULD_SELECT_VEHICLE" validate={[required()]}/>*/}
				{/*<BooleanInput source="shouldAskForImage" label="SHOULD_ASK_FOR_IMAGE" validate={[required()]}/>*/}
				{/*<BooleanInput source="shouldAskForDrivingLicenseImage" label="SHOULD_ASK_FOR_DRIVING_LICENSE_IMAGE"*/}
				{/*              validate={[required()]}/>*/}
				{/*<BooleanInput source="shouldAskForVideo" label="SHOULD_ASK_FOR_VIDEO" validate={[required()]}/>*/}
				{/*<BooleanInput source="shouldAskForGallery" label="SHOULD_ASK_FOR_GALLERY" validate={[required()]}/>*/}
				{/*<BooleanInput source="shouldSchedule" label="SHOULD_SCHEDULE" validate={[required()]}/>*/}
				{/*<BooleanInput source="shouldAskForCarStatus" label="SHOULD_ASK_FOR_CAR_STATUS" validate={[required()]}/>*/}
				{/*<ReferenceArrayInput*/}
				{/*	label="QUESTIONS"*/}
				{/*	source="questions" reference="solution-questions"*/}
				{/*	alwaysOn*/}
				{/*	validate={[required()]}*/}
				{/*>*/}
				{/*	<AutocompleteArrayInput label="QUESTIONS" optionText="id" validate={[required()]}/>*/}
				{/*</ReferenceArrayInput>*/}
				<NumberInput source="maxOrdersPerDay" label="MAX_ORDERS_PER_DAY" validate={[required()]}/>
				{/*<TextInput source="remark" label="REMARK" validate={[required()]}/>*/}
				{/*<TextInput source="navigateTo" label="NAVIGATE_TO" validate={[required()]}/>*/}
				<BooleanInput source="shouldWaitForPriceQuote" label="SHOULD_WAIT_FOR_PRICE_QUOTE"/>
				<BooleanInput source="isTransportationAvailable" label="IS_TRANSPORTATION_AVAILABLE"
				              validate={[required()]}/>
				<WeekdaysInput source="weekAvailability" label="AVAILABLE_DAYS" defaultValue={
					{
						[EWeekday.Sunday]: true,
						[EWeekday.Monday]: true,
						[EWeekday.Tuesday]: true,
						[EWeekday.Wednesday]: true,
						[EWeekday.Thursday]: true,
					}}/>
				<NumberInput source="averageSolutionDurationInMinutes" label="AVERAGE_SOLUTION_DURATION_IN_MINUTES"
				             validate={[required()]}/>
				{/*<BooleanInput source="transportationConfig.shouldAskForDrivingLicense"*/}
				{/*              label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_DRIVING_LICENSE" validate={[required()]}/>*/}
				{/*<BooleanInput source="transportationConfig.shouldAskForPersonalId"*/}
				{/*              label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_PERSONAL_ID" validate={[required()]}/>*/}
				{/*<BooleanInput source="transportationConfig.shouldAskForInsuranceDocument"*/}
				{/*              label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_INSURANCE_DOCUMENT" validate={[required()]}/>*/}
			</SimpleForm>
		</Edit>
	);
};
