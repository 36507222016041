import * as React from 'react';
import { Edit, EditProps, SimpleForm } from 'react-admin';
import { SolutionOrderForm } from './SolutionOrderForm';

export const SolutionOrderEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<SolutionOrderForm/>
			</SimpleForm>
		</Edit>
	);
};
