import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import FamilyRestroom from '@mui/icons-material/FamilyRestroom';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Event from '@mui/icons-material/Event';
import { useDataProvider, usePermissions, useTranslate } from 'react-admin';
import EntityChart from './EntityChart';
import CardWithIcon from './CardWithIcon';
import { EntitySearch } from '../../interfaces/entity-search.interface';
import { Stats } from '../../interfaces/stats.interface';
import { subDays } from 'date-fns';
import { API_URL, LOCAL_STORAGE_GARAGE_ID, LOCAL_STORAGE_USER_TYPE } from '../../providers/constants';
import { httpClient } from '../../providers/http_client';
import Warehouse from '@mui/icons-material/Warehouse';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import { isAdminOrHigher } from '../../providers/auth.provider';
import { EUserType } from '../../enums';

const styles = {
	flex: { display: 'flex' },
	flexColumn: { display: 'flex', flexDirection: 'column' },
	leftCol: { flex: 1, marginRight: '0.5em' },
	rightCol: { flex: 1, marginLeft: '0.5em' },
	singleCol: { marginTop: '1em', marginBottom: '1em' }
};

const Spacer = () => <span style={{ width: '1em' }}/>;
const VerticalSpacer = () => <div style={{ height: '1em' }}/>;

export const Dashboard = () => {
	const { permissions } = usePermissions();

	const dataProvider = useDataProvider();
	const translate = useTranslate();

	const [purchases, setPurchases] = useState([] as EntitySearch[]);
	const [stats, setStats] = useState<Stats>();

	const isXSmall = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('xs')
	);
	const isSmall = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down('md')
	);

	const fetchOrders = useCallback(async () => {
		const { data } = await dataProvider.getList<any>(
			'solution-orders',
			{
				filter: {
					'from__createdAt': subDays(new Date(), 30)
				},
				sort: { field: '_createdAt', order: 'DESC' },
				pagination: { page: 1, perPage: 9999 }
			}
		);

		console.log(data);

		setPurchases(data);
	}, [dataProvider]);

	const fetchStats = useCallback(async () => {
		const userType = localStorage.getItem(LOCAL_STORAGE_USER_TYPE);
		if (userType === EUserType.Admin) {
			const { json } = await httpClient(`${API_URL}/admin/stats`);
			setStats(json.data);
		} else {
			const garageId = localStorage.getItem(LOCAL_STORAGE_GARAGE_ID);
			const { json } = await httpClient(`${API_URL}/garages/${garageId}/stats`);
			setStats(json.data);
		}

	}, []);

	useEffect(() => {
		fetchOrders();
	}, [fetchOrders]);

	useEffect(() => {
		fetchStats();
	}, [fetchStats]);

	return isXSmall ? (
		<div>
			<div style={styles.flexColumn as CSSProperties}>
				{isAdminOrHigher(permissions) && <div style={styles.singleCol}>
                    <CardWithIcon to="/garages" icon={Event} title={translate('TOTAL_GARAGES')}
                                  subtitle={stats?.totalGarages?.toLocaleString() || '---'}/>
                </div>}
				<div style={styles.singleCol}>
					<CardWithIcon to="/clients" icon={FamilyRestroom} title={translate('TOTAL_CLIENTS')}
					              subtitle={stats?.totalClients?.toLocaleString() || '---'}/>
				</div>
				<div style={styles.singleCol}>
					<CardWithIcon to="/solution-orders/not-yet-done" icon={ShoppingCart} title={translate('OPENED_SOLUTION_ORDERS')}
					              subtitle={stats?.openedSolutionOrders?.toLocaleString() || '---'}/>
				</div>
				<div style={styles.singleCol}>
					<CardWithIcon to="/solution-orders/history" icon={ShoppingCart} title={translate('CLOSED_SOLUTION_ORDERS')}
					              subtitle={stats?.closedSolutionOrders?.toLocaleString() || '---'}/>
				</div>
			</div>
		</div>
	) : isSmall ? (
		<div style={styles.flexColumn as CSSProperties}>
			<div style={styles.singleCol}>
				{isAdminOrHigher(permissions) &&
                    <CardWithIcon to="/garages" icon={Warehouse} title={translate('TOTAL_GARAGES')}
                                  subtitle={stats?.totalGarages?.toLocaleString() || '---'}/>}
				<VerticalSpacer/>
				<CardWithIcon to="/clients" icon={VerifiedUser} title={translate('TOTAL_CLIENTS')}
				              subtitle={stats?.totalClients?.toLocaleString() || '---'}/>
				<VerticalSpacer/>
				<CardWithIcon to="/solution-orders/not-yet-done" icon={ShoppingCart} title={translate('OPENED_SOLUTION_ORDERS')}
				              subtitle={stats?.openedSolutionOrders?.toLocaleString() || '---'}/>
				<CardWithIcon to="/solution-orders/history" icon={ShoppingCart} title={translate('CLOSED_SOLUTION_ORDERS')}
				              subtitle={stats?.closedSolutionOrders?.toLocaleString() || '---'}/>
			</div>
			<div style={styles.singleCol}>
				<EntityChart entities={purchases} header={'MONTHLY_SOLUTION_ORDERS'}/>
			</div>
		</div>
	) : (
		<>
			<div style={styles.flex}>
				<div style={styles.leftCol}>
					<div style={styles.flex}>
						{isAdminOrHigher(permissions) &&
                            <CardWithIcon to="/garages" icon={Event} title={translate('TOTAL_GARAGES')}
                                          subtitle={stats?.totalGarages?.toLocaleString() || '---'}/>}
						<Spacer/>
						<CardWithIcon to="/clients" icon={FamilyRestroom} title={translate('TOTAL_CLIENTS')}
						              subtitle={stats?.totalClients?.toLocaleString() || '---'}/>
						<Spacer/>
						<CardWithIcon to="/solution-orders/not-yet-done" icon={ShoppingCart}
						              title={translate('OPENED_SOLUTION_ORDERS')}
						              subtitle={stats?.openedSolutionOrders?.toLocaleString() || '---'}/>
						<Spacer/>
						<CardWithIcon to="/solution-orders/history" icon={ShoppingCart}
						              title={translate('CLOSED_SOLUTION_ORDERS')}
						              subtitle={stats?.closedSolutionOrders?.toLocaleString() || '---'}/>
						<Spacer/>
					</div>
					<div style={styles.singleCol}>
						<EntityChart entities={purchases} header={'MONTHLY_SOLUTION_ORDERS'}/>
					</div>
				</div>
			</div>
		</>
	);
};
