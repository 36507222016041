import * as React from 'react';
import { ReferenceField, TextField, useGetOne, useRecordContext } from 'react-admin';
import _get from 'lodash-es/get';

export const SolutionOrderAssigneeField = ({ source, label = 'ASSIGNEE', record }:
	                                           { source: string, label: string, record?: any }) => {
	const recordFromContext = useRecordContext<{ solutionOrderId: number }>();
	record ??= recordFromContext;

	const solutionOrderId = _get(record, source);

	const { data: solutionOrder } = useGetOne('solution-orders', { id: solutionOrderId });

	if (!solutionOrder) return null;

	return (
		<ReferenceField sortable={false} source="assigneeId" record={solutionOrder} reference="employees" label={label}
		                link="show">
		<TextField sortable={false} source="user.fullName" label={label}/>
	</ReferenceField>);
};
