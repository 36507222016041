import * as React from 'react';
import { List, Loading, useRecordContext, } from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';
import { VehicleDatagrid, VehicleList } from '../vehicle';

export const GarageVehiclesView = (): React.ReactElement => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

	if (!record) {
		return <Loading/>;
	}

	return (
		<List
			actions={false}
			hasCreate={false}
			resource="vehicles"
			sort={{ field: '_createdAt', order: 'DESC' }}
			perPage={20}
			filter={{ garage: record?.id }}
		>
			{isXSmall ? <VehicleList/> : <VehicleDatagrid/>}
		</List>
	);
};
