import * as React from 'react';
import {
	AutocompleteInput,
	DateInput,
	Edit,
	EditProps,
	ReferenceInput,
	required,
	SimpleForm,
	TextInput, usePermissions
} from 'react-admin';
import { isAdminOrHigher } from '../providers/auth.provider';

export const GarageDowntimeEdit = (props: EditProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				{isAdminOrHigher(permissions) && <ReferenceInput
                    label="GARAGE"
                    source="garageId" reference="garages"
                    alwaysOn
                    validate={[required()]}
                >
                    <AutocompleteInput label="GARAGE" optionText="name.he" validate={[required()]}/>
                </ReferenceInput>}

				<DateInput source="from" label="FROM_DATE" validate={[required()]}/>
				<DateInput source="to" label="TO_DATE" validate={[required()]}/>
				<TextInput source="reason.he" label="REASON" validate={[required()]}/>
			</SimpleForm>
		</Edit>
	);
};
