import * as React from 'react';
import {
	BooleanField,
	EditButton,
	FunctionField,
	ImageField,
	Labeled,
	NumberField,
	ReferenceField,
	ShowButton,
	TextField,
	useListContext,
	usePermissions
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { PriceField } from '../components/PriceField';
import { WeekdaysField } from '../components/WeekdaysField';
import { isAdminOrHigher } from '../providers/auth.provider';

const solutionStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const SolutionList = () => {
	const { permissions } = usePermissions();

	const classes = solutionStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="solutions"
										record={entity}
									/>
									<EditButton
										resource="solutions"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={entity} source="id" label="#"/>
						</Labeled>
						{isAdminOrHigher(permissions) && <Labeled label="GARAGE" sx={classes.cardContentRow}>
                            <ReferenceField record={entity} source="garageId" label="FORUM" reference="garages"
                                            link="show">
                                <TextField source="name.he"/>
                            </ReferenceField>

                        </Labeled>}
						<Labeled label="INDEX" sx={classes.cardContentRow}>
							<NumberField record={entity} source="index" label="INDEX"/>
						</Labeled>
						<Labeled label="IS_ENABLED" sx={classes.cardContentRow}>
							<BooleanField record={entity} source="isEnabled" label="IS_ENABLED"/>
						</Labeled>
						<Labeled label="NAME" sx={classes.cardContentRow}>
							<TextField record={entity} source="name" label="NAME"/>
						</Labeled>
						<Labeled label="IMAGE" sx={classes.cardContentRow}>
							<ImageField record={entity} source="imageUrl" label="IMAGE"/>
						</Labeled>
						<Labeled label="PRICE" sx={classes.cardContentRow}>
							<PriceField record={entity} source="price" label="PRICE"/>
						</Labeled>
						{/*<Labeled label="SHOULD_SELECT_VEHICLE" sx={classes.cardContentRow}>*/}
						{/*	<BooleanField record={entity} source="shouldSelectVehicle" label="SHOULD_SELECT_VEHICLE"/>*/}
						{/*</Labeled>*/}
						{/*<Labeled label="SHOULD_ASK_FOR_IMAGE" sx={classes.cardContentRow}>*/}
						{/*	<BooleanField record={entity} source="shouldAskForImage" label="SHOULD_ASK_FOR_IMAGE"/>*/}
						{/*</Labeled>*/}
						{/*<Labeled label="SHOULD_ASK_FOR_DRIVING_LICENSE_IMAGE" sx={classes.cardContentRow}>*/}
						{/*	<BooleanField record={entity} source="shouldAskForDrivingLicenseImage"*/}
						{/*	              label="SHOULD_ASK_FOR_DRIVING_LICENSE_IMAGE"/>*/}
						{/*</Labeled>*/}
						{/*<Labeled label="SHOULD_ASK_FOR_VIDEO" sx={classes.cardContentRow}>*/}
						{/*	<BooleanField record={entity} source="shouldAskForVideo" label="SHOULD_ASK_FOR_VIDEO"/>*/}
						{/*</Labeled>*/}
						{/*<Labeled label="SHOULD_ASK_FOR_GALLERY" sx={classes.cardContentRow}>*/}
						{/*	<BooleanField record={entity} source="shouldAskForGallery" label="SHOULD_ASK_FOR_GALLERY"/>*/}
						{/*</Labeled>*/}
						{/*<Labeled label="SHOULD_SCHEDULE" sx={classes.cardContentRow}>*/}
						{/*	<BooleanField record={entity} source="shouldSchedule" label="SHOULD_SCHEDULE"/>*/}
						{/*</Labeled>*/}
						{/*<Labeled label="SHOULD_ASK_FOR_CAR_STATUS" sx={classes.cardContentRow}>*/}
						{/*	<BooleanField record={entity} source="shouldAskForCarStatus"*/}
						{/*	              label="SHOULD_ASK_FOR_CAR_STATUS"/>*/}
						{/*</Labeled>*/}
						{/*<Labeled label="QUESTIONS" sx={classes.cardContentRow}>*/}
						{/*	<ReferenceArrayField record={entity} source="questionsIds" label="QUESTIONS"*/}
						{/*	                     reference="solution-questions">*/}
						{/*		<SingleFieldList>*/}
						{/*			<ChipField source="label"/>*/}
						{/*		</SingleFieldList>*/}
						{/*	</ReferenceArrayField>*/}
						{/*</Labeled>*/}
						<Labeled label="MAX_ORDERS_PER_DAY" sx={classes.cardContentRow}>
							<NumberField record={entity} source="maxOrdersPerDay" label="MAX_ORDERS_PER_DAY"/>
						</Labeled>
						{/*<Labeled label="REMARK" sx={classes.cardContentRow}>*/}
						{/*	<TextField record={entity} source="remark" label="REMARK"/>*/}
						{/*</Labeled>*/}
						{/*<Labeled label="NAVIGATE_TO" sx={classes.cardContentRow}>*/}
						{/*	<TextField record={entity} source="navigateTo" label="NAVIGATE_TO"/>*/}
						{/*</Labeled>*/}
						<Labeled label="IS_TRANSPORTATION_AVAILABLE" sx={classes.cardContentRow}>
							<BooleanField record={entity} source="shouldWaitForPriceQuote"
							              label="SHOULD_WAIT_FOR_PRICE_QUOTE"/>
						</Labeled>
						<Labeled label="IS_TRANSPORTATION_AVAILABLE" sx={classes.cardContentRow}>
							<BooleanField record={entity} source="isTransportationAvailable"
							              label="IS_TRANSPORTATION_AVAILABLE"/>
						</Labeled>
						<Labeled sx={classes.cardContentRow}>
							<WeekdaysField record={entity} source="weekAvailability"/>
						</Labeled>
						<Labeled sx={classes.cardContentRow}>
							<FunctionField
								record={entity}
								source="averageSolutionDurationInMinutes" label="AVERAGE_SOLUTION_DURATION_HOURS"
								render={(record, source) => record[source] / 60}
							/>
						</Labeled>
						{/*<Labeled label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_DRIVING_LICENSE"*/}
						{/*         sx={classes.cardContentRow}>*/}
						{/*	<BooleanField record={entity} source="transportationConfig.shouldAskForDrivingLicense"*/}
						{/*	              label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_DRIVING_LICENSE"/>*/}
						{/*</Labeled>*/}
						{/*<Labeled label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_PERSONAL_ID" sx={classes.cardContentRow}>*/}
						{/*	<BooleanField record={entity} source="transportationConfig.shouldAskForPersonalId"*/}
						{/*	              label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_PERSONAL_ID"/>*/}
						{/*</Labeled>*/}
						{/*<Labeled label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_INSURANCE_DOCUMENT"*/}
						{/*         sx={classes.cardContentRow}>*/}
						{/*	<BooleanField record={entity} source="transportationConfig.shouldAskForInsuranceDocument"*/}
						{/*	              label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_INSURANCE_DOCUMENT"/>*/}
						{/*</Labeled>*/}
					</CardContent>
				</Card>
			))}
		</div>
	);
};
