import * as React from 'react';
import { Datagrid, ReferenceField, TextField, usePermissions } from 'react-admin';
import './EmployeeDatagrid.scss';
import { isAdminOrHigher } from '../providers/auth.provider';
import { TranslateField } from '../components/TranslateField';

export const EmployeeDatagrid = (props): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="edit" className="employees-table" optimized
		          {...(props ?? {})}
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField sortable={false} source="id" label="#"/>
			<ReferenceField sortable={false} source="userId" reference="users" label="USER" link="show">
				<TextField sortable={false} source="fullName"/>
			</ReferenceField>

			{isAdminOrHigher(permissions) &&
                <ReferenceField sortable={false} source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField sortable={false} source="name.he"/>
                </ReferenceField>}

			<TranslateField source="user.type" namespace="EUserType" label="TYPE"/>
		</Datagrid>
	);
};
