import * as React from 'react';
import { Datagrid, DateField, FunctionField, ReferenceField, TextField, usePermissions } from 'react-admin';
import './SolutionOrderDatagrid.scss';
import { SolutionOrder } from './SolutionOrder';
import { differenceInMinutes } from 'date-fns';
import { GalleryField } from '../components/GalleryField';
import { SolutionOrderStatusToColor, VehicleStatusToColor } from '../util/statuses-colors.config';
import { StatusField } from '../components/StatusField';
import { TranslateField } from '../components/TranslateField';
import { isAdminOrHigher } from '../providers/auth.provider';
import { VideoFieldPreview } from '../components/VideoField';

export const SolutionOrderDatagrid = (): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="show" className="solution-orders-table" optimized
		          rowStyle={(row: SolutionOrder) => {
			          const isRead = row._readAt;

			          let isWarninglyExceeding = false;
			          let isExceptionallyExceeding = false;
			          const handlingTimeInMinutes = differenceInMinutes(new Date(row.finishedAt), new Date(row.startedAt));

			          if (handlingTimeInMinutes) {
				          isWarninglyExceeding = handlingTimeInMinutes > (row.averageSolutionDurationInMinutes ?? 0) + row.garage?.reportSettings?.treatmentExceededWarningTriggerTimeInMinutes ?? 999;
				          isExceptionallyExceeding = handlingTimeInMinutes > (row.averageSolutionDurationInMinutes ?? 0) + row.garage?.reportSettings?.treatmentExceededExceptionTriggerTimeInMinutes ?? 999;
			          }

			          return ({
				          borderRightColor: isRead ? '#49d18c' : '#bbbbbb',
				          backgroundColor: isExceptionallyExceeding ? '#ffaaaa' : isWarninglyExceeding ? '#ffddaa' : 'unset'
			          });
		          }}>
			<TextField sortable={false} source="id" label="#"/>
			<DateField source="_createdAt" label="CREATED_AT" showTime options={{
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			}} locales="he-IL"/>
			{isAdminOrHigher(permissions) &&
                <ReferenceField sortable={false} source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField sortable={false} source="name.he"/>
                </ReferenceField>}

			<ReferenceField sortable={false} source="clientId" reference="clients" label="CLIENT" link="show">
				<TextField sortable={false} source="user.fullName"/>
			</ReferenceField>

			<ReferenceField sortable={false} source="solutionId" reference="solutions" label="SOLUTION" link="show">
				<TextField sortable={false} source="name.he"/>
			</ReferenceField>

			<ReferenceField sortable={false} source="assigneeId" reference="employees" label="ASSIGNEE" link="show">
				<TextField sortable={false} source="user.fullName"/>
			</ReferenceField>

			<ReferenceField sortable={false} source="vehicleId" reference="vehicles" label="VEHICLE" link="show">
				<FunctionField sortable={false} render={(record) => `${record['plateNumber']} - ${record['brand']}`}/>
			</ReferenceField>
			<StatusField source="status" label="ORDER_STATUS" enumToColors={SolutionOrderStatusToColor}
			             enumName="ESolutionOrderStatus"/>
			<StatusField source="vehicleStatus" label="VEHICLE_STATUS" enumToColors={VehicleStatusToColor}
			             enumName="EVehicleStatus"/>
			<DateField source="startedAt" label="STARTED_AT" showTime options={{
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			}} locales="he-IL"/>
			<DateField source="finishedAt" label="FINISHED_AT" showTime options={{
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			}} locales="he-IL"/>
			<VideoFieldPreview source="videoUrl" label="VIDEO"/>
			<GalleryField source="gallery" label="GALLERY"/>
			<DateField source="scheduledAppointment" label="SCHEDULED_APPOINTMENT" showTime options={{
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			}} locales="he-IL"/>
			<TranslateField source="vehicleDrivability" label="VEHICLE_DRIVABILITY" namespace="EVehicleDrivability"/>
		</Datagrid>
	);
};