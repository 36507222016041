import * as React from 'react';
import { AutocompleteInput, Create, CreateProps, ReferenceInput, required, SimpleForm, TextInput } from 'react-admin';

export const SolutionAnswerCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<ReferenceInput
					label="QUESTION"
					source="questionId" reference="solution-questions"
					alwaysOn
					validate={[required()]}
				>
					<AutocompleteInput label="QUESTION" optionText="label.he" validate={[required()]}/>
				</ReferenceInput>

				<ReferenceInput
					label="SOLUTION_ORDER"
					source="orderId" reference="solution-orders"
					alwaysOn
					validate={[required()]}
				>
					<AutocompleteInput label="SOLUTION_ORDER" optionText="client.user.fullName"
					                   validate={[required()]}/>
				</ReferenceInput>

				<TextInput source="content" label="CONTENT" validate={[required()]}/>
			</SimpleForm>
		</Create>
	);
};
