import * as React from 'react';
import { List, Loading, TopToolbar, useRecordContext, useRedirect, useTranslate, } from 'react-admin';
import { Button, Theme, useMediaQuery } from '@mui/material';
import { PriceQuoteDatagrid, PriceQuoteList } from '../price-quote';

export const SolutionOrderPriceQuotesView = (): React.ReactElement => {
	const redirect = useRedirect();
	const translator = useTranslate();

	const record = useRecordContext();
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

	const handleClick = () => {
		redirect(`/price-quotes/create?solutionOrderId=${record.id}`);
	};

	if (!record) {
		return <Loading/>;
	}

	const ListActions = () => (
		<TopToolbar>
			<Button onClick={handleClick}>{translator('CREATE')}</Button>
		</TopToolbar>
	);
	const CustomEmptyState = () => (
		<div style={{ margin: '0 auto', textAlign: 'center' }}>
			<Button onClick={handleClick}>{translator('CREATE')}</Button>
		</div>

	);

	return (
		<List
			actions={<ListActions/>}
			hasCreate={true}
			resource="price-quotes"
			sort={{ field: '_createdAt', order: 'DESC' }}
			perPage={20}
			filter={{ solutionOrder: record?.id }}
			empty={<CustomEmptyState/>}
		>
			{isXSmall ? <PriceQuoteList/> : <PriceQuoteDatagrid/>}
		</List>
	);
};
