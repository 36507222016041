import * as React from 'react';
import {
	Edit,
	EditProps,
	maxLength,
	minLength,
	NumberInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
	useTranslate
} from 'react-admin';
import { SingleImageInput } from '../components/SingleImageInput';
import GoogleAddressInput from '../components/GoogleAddressInput';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { EApprovalStatus, EWeekday } from '../enums';
import WeekdaysInput from '../components/WeekdaysInput';
import { ColorInput } from '../components/ColorInput';

export const GarageEdit = (props: EditProps): React.ReactElement => {
	const translate = useTranslate();

	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<TextInput source="id" label="#" disabled/>
				<TextInput disabled source="code" label="CODE" validate={[required()]}/>
				<div>
					<TextInput source="name.he" label="NAME" validate={[required(), maxLength(30), minLength(3)]}
					           style={{ padding: '0px 0px 0px 10px' }}/>
					<TextInput source="name.en" label="EN_NAME" validate={[required(), maxLength(30), minLength(3)]}/>
				</div>
				<SelectInput source="approvalStatus" label="APPROVAL_STATUS"
				             choices={Object.keys(EApprovalStatus).map(key => ({
					             id: key,
					             name: `EApprovalStatus.${EApprovalStatus[key]}`
				             }))}/>
				<GoogleAddressInput source="address" label="ADDRESS" validate={[required()]}/>
				<ColorInput source="primaryColor" label="PRIMARY_COLOR" validate={[required()]}/>
				<ColorInput source="backgroundColor" label="BACKGROUND_COLOR" validate={[required()]}/>
				<div>
					<TextInput multiline source="description.he" label="DESCRIPTION" validate={[required()]}
					           style={{ padding: '0px 0px 0px 10px' }}/>
					<TextInput multiline source="description.en" label="EN_DESCRIPTION" validate={[required()]}/>
				</div>
				<SingleImageInput source="logoImageUrl" label="LOGO_IMAGE" validate={[required()]}/>
				<SingleImageInput source="coverImageUrl" label="COVER_IMAGE" validate={[required()]}/>
				<div>
					<TextInput multiline source="openingHours.he" label="OPENING_HOURS" validate={[required()]}
					           style={{ padding: '0px 0px 0px 10px' }}/>
					<TextInput multiline source="openingHours.en" label="EN_OPENING_HOURS" validate={[required()]}/>
				</div>
				<div>
					<TextInput multiline source="SOSHours.he" label="SOS_HOURS" validate={[required()]}
					           style={{ padding: '0px 0px 0px 10px' }}/>
					<TextInput multiline source="SOSHours.en" label="EN_SOS_HOURS" validate={[required()]}/>

				</div>
				<WeekdaysInput source="weekAvailability" label="AVAILABLE_DAYS" defaultValue={
					{
						[EWeekday.Sunday]: true,
						[EWeekday.Monday]: true,
						[EWeekday.Tuesday]: true,
						[EWeekday.Wednesday]: true,
						[EWeekday.Thursday]: true,
					}}/>
				<Accordion>
					<AccordionSummary>
						<Typography>{translate('CONTACT_DETAILS_LABEL')}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<TextInput source="contactDetails.name" label="CONTACT_DETAILS.NAME" validate={[required()]}/>
						<TextInput source="contactDetails.phone" label="CONTACT_DETAILS.PHONE" validate={[required()]}/>
						<TextInput source="contactDetails.whatsappNumber" label="CONTACT_DETAILS.WHATSAPP_NUMBER"
						           validate={[required()]}/>
						<TextInput source="contactDetails.email" label="CONTACT_DETAILS.EMAIL"/>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary>
						<Typography>{translate('SOS_CONTACT_DETAILS_LABEL')}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<TextInput source="SOSContactDetails.name" label="SOS_CONTACT_DETAILS.NAME"/>
						<TextInput source="SOSContactDetails.phone" label="SOS_CONTACT_DETAILS.PHONE"/>
						<TextInput source="SOSContactDetails.whatsappNumber"
						           label="SOS_CONTACT_DETAILS.WHATSAPP_NUMBER"/>
						<TextInput source="SOSContactDetails.email" label="SOS_CONTACT_DETAILS.EMAIL"/>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary>
						<Typography>{translate('SETTINGS')}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<div><NumberInput source="reportSettings.treatmentExceededWarningTriggerTimeInMinutes"
						                  label="TREATMENT_EXCEEDED_WARNING_TRIGGER_TIME_IN_MINUTES"
						                  validate={[required()]}/></div>
						<div><NumberInput source="reportSettings.treatmentExceededExceptionTriggerTimeInMinutes"
						                  label="TREATMENT_EXCEEDED_EXCEPTION_TRIGGER_TIME_IN_MINUTES"
						                  validate={[required()]}/></div>
						<div><NumberInput source="reportSettings.priceQuoteExceededExceptionTriggerInMinutes"
						                  label="PRICE_QUOTE_EXCEEDED_EXCEPTION_TRIGGER_IN_MINUTES"
						                  validate={[required()]}/></div>
						<div><NumberInput source="reportSettings.livePriceQuoteExceededExceptionTriggerInMinutes"
						                  label="LIVE_PRICE_QUOTE_EXCEEDED_EXCEPTION_TRIGGER_IN_MINUTES"
						                  validate={[required()]}/></div>
					</AccordionDetails>
				</Accordion>
			</SimpleForm>
		</Edit>
	);
};
