import * as React from 'react';
import {
	BooleanField,
	FunctionField,
	ImageField,
	NumberField,
	ReferenceField,
	Show,
	ShowProps,
	SimpleShowLayout,
	TextField,
	usePermissions,
} from 'react-admin';
import { PriceField } from '../components/PriceField';
import { WeekdaysField } from '../components/WeekdaysField';
import { isAdminOrHigher, isGarageOwnerOrHigher } from '../providers/auth.provider';

export const SolutionShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={isGarageOwnerOrHigher(permissions) ? undefined : false}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				{isAdminOrHigher(permissions) &&
                    <ReferenceField source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField source="name.he"/>
                </ReferenceField>}

				<NumberField source="index" label="INDEX"/>
				<BooleanField source="isEnabled" label="IS_ENABLED"/>
				<TextField source="name.he" label="NAME"/>
				<ImageField source="imageUrl" label="IMAGE"/>
				<PriceField source="price" label="PRICE"/>
				{/*<BooleanField source="shouldSelectVehicle" label="SHOULD_SELECT_VEHICLE"/>*/}
				{/*<BooleanField source="shouldAskForImage" label="SHOULD_ASK_FOR_IMAGE"/>*/}
				{/*<BooleanField source="shouldAskForDrivingLicenseImage" label="SHOULD_ASK_FOR_DRIVING_LICENSE_IMAGE"/>*/}
				{/*<BooleanField source="shouldAskForVideo" label="SHOULD_ASK_FOR_VIDEO"/>*/}
				{/*<BooleanField source="shouldAskForGallery" label="SHOULD_ASK_FOR_GALLERY"/>*/}
				{/*<BooleanField source="shouldSchedule" label="SHOULD_SCHEDULE"/>*/}
				{/*<BooleanField source="shouldAskForCarStatus" label="SHOULD_ASK_FOR_CAR_STATUS"/>*/}
				{/*<ReferenceArrayField source="questionsIds" label="QUESTIONS" reference="solution-questions">*/}
				{/*	<SingleFieldList>*/}
				{/*		<ChipField source="label"/>*/}
				{/*	</SingleFieldList>*/}
				{/*</ReferenceArrayField>*/}
				<NumberField source="maxOrdersPerDay" label="MAX_ORDERS_PER_DAY"/>
				{/*<TextField source="remark" label="REMARK"/>*/}
				{/*<TextField source="navigateTo" label="NAVIGATE_TO"/>*/}
				<BooleanField source="shouldWaitForPriceQuote" label="SHOULD_WAIT_FOR_PRICE_QUOTE"/>
				<BooleanField source="isTransportationAvailable" label="IS_TRANSPORTATION_AVAILABLE"/>
				<WeekdaysField source="weekAvailability" label="AVAILABLE_DAYS"/>
				<FunctionField
					source="averageSolutionDurationInMinutes" label="AVERAGE_SOLUTION_DURATION_HOURS"
					render={(record, source) => record[source] / 60}
				/>
				{/*<BooleanField source="transportationConfig.shouldAskForDrivingLicense"*/}
				{/*              label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_DRIVING_LICENSE"/>*/}
				{/*<BooleanField source="transportationConfig.shouldAskForPersonalId"*/}
				{/*              label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_PERSONAL_ID"/>*/}
				{/*<BooleanField source="transportationConfig.shouldAskForInsuranceDocument"*/}
				{/*              label="TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_INSURANCE_DOCUMENT"/>*/}
			</SimpleShowLayout>
		</Show>
	);
};
