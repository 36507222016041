import * as React from 'react';
import {
	AutocompleteInput,
	CreateProps,
	ReferenceInput,
	required, SelectInput,
	TextInput,
	usePermissions,
} from 'react-admin';
import { EUserType } from '../enums';
import { isAdminOrHigher } from '../providers/auth.provider';

export const EmployeeForm = (props: CreateProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<>
			<TextInput source="user.fullName" label="FULL_NAME"/>
			{isAdminOrHigher(permissions) && <ReferenceInput
                label="GARAGE"
                source="garageId" reference="garages"
                alwaysOn
                validate={[required()]}
            >
                <AutocompleteInput label="GARAGE" optionText="name.he" validate={[required()]}/>
            </ReferenceInput>}

			<SelectInput source="user.type" label="TYPE" validate={[required()]}
			             choices={[EUserType.GarageOwner, EUserType.WorkshopManager, EUserType.ServiceAdvisor].map(key => ({
				             id: key,
				             name: `EUserType.${EUserType[key]}`
			             }))}
			/>
			<TextInput source="user.email" label="EMAIL"/>
			<TextInput source="user.password" label="PASSWORD" type="password"/>

		</>
	);
};
