import * as React from 'react';
import {
	BooleanField,
	Datagrid,
	FunctionField,
	ImageField,
	NumberField,
	ReferenceField,
	TextField,
	usePermissions
} from 'react-admin';
import { PriceField } from '../components/PriceField';
import './SolutionDatagrid.scss';
import { isAdminOrHigher } from '../providers/auth.provider';

export const SolutionDatagrid = (props): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="edit" className="solutions-table" optimized
		          {...(props ?? {})}
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField sortable={false} source="id" label="#"/>
			{isAdminOrHigher(permissions) &&
                <ReferenceField sortable={false} source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField sortable={false} source="name.he"/>
                </ReferenceField>}

			<NumberField source="index" label="INDEX"/>
			<BooleanField source="isEnabled" label="IS_ENABLED"/>
			<TextField source="name.he" label="NAME"/>
			<ImageField source="imageUrl" label="IMAGE"/>
			<PriceField source="price" label="PRICE"/>
			{/*<BooleanField source="shouldSelectVehicle" label="SHOULD_SELECT_VEHICLE"/>*/}
			<BooleanField source="shouldWaitForPriceQuote" label="SHOULD_WAIT_FOR_PRICE_QUOTE"/>
			<BooleanField source="isTransportationAvailable" label="IS_TRANSPORTATION_AVAILABLE"/>
			<NumberField source="maxOrdersPerDay" label="MAX_ORDERS_PER_DAY"/>
			<FunctionField
				source="averageSolutionDurationInMinutes" label="AVERAGE_SOLUTION_DURATION_HOURS"
				render={(record, source) => record[source] / 60}
			/>
		</Datagrid>
	);
};
