import { useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslate } from 'react-admin';
import { CellValue, Workbook, Worksheet } from 'exceljs';
import { useFormContext } from 'react-hook-form';

export const ImportFromTableInput = ({ source, ...props }: any) => {
	const translator = useTranslate();
	const form = useFormContext();

	const [worksheet, setWorksheet] = useState<Worksheet | null>(null);
	const [headers, setHeaders] = useState<CellValue[]>([]);
	const [selectedHeaderIndex, setSelectedHeaderIndex] = useState<number | null>(null);

	const updateWorksheet = (worksheet: Worksheet | null) => {
		setWorksheet(worksheet);
	};

	const updateHeaders = (headers) => {
		setHeaders(headers);
	};

	const updateSelectedHeaderIndex = (index) => {
		setSelectedHeaderIndex(index);
	};

	const updateVehiclesPlates = (headerIndex) => {
		if (worksheet == null) {
			return;
		}

		let column = worksheet.getColumn(headerIndex + 1);
		updateSelectedHeaderIndex(headerIndex);

		const plates: string[] = [];
		for (let i = 0; i < column.values.length; i++) {
			if (column.values[i] == null || column.values[i] === undefined) {
				continue;
			}
			plates.push(column.values[i]!.toString());
		}

		form.setValue(source, plates);
	};

	const readExcel = (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsArrayBuffer(file);
			reader.onload = async () => {
				const buffer = reader.result as any;
				const workbook = new Workbook();
				workbook.xlsx
					.load(buffer)
					.then(() => {
						let worksheet = workbook?.getWorksheet() || null;

						if (worksheet === null) {
							reject('There is no worksheet');
						}

						let row = worksheet!.getRow(1);
						let cellValues: CellValue[] = [];
						for (let cell of row.values as CellValue[]) {
							if (cell == null || cell === undefined) {
								continue;
							}

							cellValues.push(cell);
						}

						updateWorksheet(worksheet);
						updateHeaders(cellValues);

						resolve(cellValues);
					})
					.catch((error) => {
						updateWorksheet(null);
						updateHeaders([]);

						reject('Error occurred while loading the workbook.' + error);
					});
			};
		});
	};

	return <>
		<h4>{translator('IMPORT_VEHICLES_PLATES_FROM_EXCEL')}</h4>
		<input type="file" onChange={async (e) => {
			const files = e.target?.files;

			if (files != null) {
				await readExcel(files[0]);
			}
		}}/>
		<div style={{ padding: 10 }}></div>
		{(worksheet != null) && <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{translator('IMPORT_COLUMN')}</InputLabel>
            <Select labelId="select-label" id="select" value={selectedHeaderIndex}
                    onChange={(event) => updateVehiclesPlates(event.target.value)}>
				{headers.map((header, index) => (
					<MenuItem value={index}>
						{header}
					</MenuItem>
				))}
            </Select>
        </FormControl>}
	</>;
};