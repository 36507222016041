export const hebrewMessages = {
	PROFILE_IMAGE: 'תמונת פרופיל',
	NAME: 'שם',
	EN_NAME: 'שם באנגלית',
	CONTENT: 'תוכן',
	PRICE: 'מחיר',
	SEARCH: 'חיפוש',
	IS_READ: 'נצפה',
	DATE: 'תאריך',
	DASHBOARD_WELCOME: 'ברוכים הבאים לפאנל הניהול',
	DASHBOARD: 'דשבורד',
	MONTHLY_REVENUE: 'הכנסה חודשית',
	NEW_ORDERS: 'הזמנות חדשות',
	MONTH_HISTORY: 'החודש האחרון',
	FROM_DATE: 'מתאריך',
	TO_DATE: 'עד תאריך',
	GENDER: 'מין',
	FULL_NAME: 'שם מלא',
	HAS_CREDIT: 'יש קרדיט',
	BUDGET: 'תקציב',
	TOTAL: 'סה״כ',
	CREATED_AT: 'שנוצרו ב-',
	FROM_CREATED_AT: 'שנוצרו מ-',
	TO_CREATED_AT: 'שנוצרו עד',
	SOLUTION_FEEDBACK: 'משו״ב',
	SOLUTION_FEEDBACKS: 'משו״בים',

	FEEDBACK_ANSWER: 'תשובת משו״ב',
	FEEDBACK_ANSWERS: 'תשובות משו״ב',

	GARAGE_DOWNTIME: 'יום סגור',
	GARAGE_DOWNTIMES: 'ימים סגורים',

	FEEDBACK_QUESTION: 'שאלת משו״ב',
	FEEDBACK_QUESTIONS: 'שאלות משו״ב',

	PRICE_QUOTE: 'הצעת מחיר',
	PRICE_QUOTES: 'הצעות מחיר',

	VEHICLE: 'רכב',
	VEHICLES: 'רכבים',

	SOLUTION_ANSWER: 'תשובה',
	SOLUTION_ANSWERS: 'תשובות',

	CLIENT: 'לקוח',
	CLIENTS: 'לקוחות',

	SOLUTION_ORDER: 'הזמנת שירות',
	SOLUTION_ORDERS: 'הזמנות שירות',
	SOLUTION_QUESTION: 'שאלת שירות',
	SOLUTION_QUESTIONS: 'שאלות שירות',
	SOLUTION: 'שירות',
	SOLUTIONS: 'שירותים',
	EMPLOYEE: 'עובד',
	EMPLOYEES: 'עובדים',
	GARAGE: 'מוסך',
	GARAGES: 'מוסכים',
	PRODUCT: 'מוצר',
	PRODUCTS: 'מוצרים',
	IMAGE: 'תמונה',
	DESCRIPTION: 'תיאור',
	EN_DESCRIPTION: 'תיאור באנגלית',
	Male: 'זכר',
	Female: 'נקבה',
	FULL_IMAGE: 'תמונה מלאה',
	SEND_NOTIFICATION_TO_CLIENTS: 'שליחת התראה לכל המשתמשים',
	NOTIFICATIONS: 'התראות',
	USER: 'משתמש',
	IS_MANAGER: 'האם מנהל?',
	TITLE: 'כותרת',
	ADDRESS: 'כתובת',
	INDEX: 'סדר',
	IS_ENABLED: 'שירות פעיל?',
	SOS_HOURS: 'שעות חירום (SOS)',
	EN_SOS_HOURS: 'שעות חירום (SOS) באנגלית',
	CONTACT_DETAILS_LABEL: 'פרטי התקשרות',
	CONTACT_DETAILS: {
		NAME: 'שם',
		PHONE: 'טלפון',
		EMAIL: 'אימייל',
		WHATSAPP_NUMBER: 'מספר ווצאפ'
	},
	SOS_CONTACT_DETAILS_LABEL: 'פרטי התקשרות SOS',
	SOS_CONTACT_DETAILS: {
		NAME: 'שם',
		PHONE: 'טלפון',
		EMAIL: 'אימייל',
		WHATSAPP_NUMBER: 'מספר ווצאפ'
	},
	PRIMARY_COLOR: 'צבע ראשי',
	BACKGROUND_COLOR: 'צבע רקע',
	LOGO_IMAGE: 'לוגו',
	COVER_IMAGE: 'תמונת רקע',
	OPENING_HOURS: 'שעות פתיחה',
	EN_OPENING_HOURS: 'שעות פתיחה באנגלית',
	CODE: 'קוד',
	SHOULD_SELECT_VEHICLE: 'נדרש לבחור רכב?',
	SHOULD_ASK_FOR_IMAGE: 'נדרש להעלות תמונה?',
	SHOULD_ASK_FOR_DRIVING_LICENSE_IMAGE: 'נדרש להעלות רישיון נהיגה?',
	SHOULD_ASK_FOR_VIDEO: 'נדרש להעלות סרטון?',
	SHOULD_ASK_FOR_GALLERY: 'נדרש להעלות מספר תמונות?',
	SHOULD_SCHEDULE: 'נדרש לתאם?',
	SHOULD_ASK_FOR_CAR_STATUS: 'נדרש להגדיר את מצב הרכב?',
	SHOULD_WAIT_FOR_PRICE_QUOTE: 'נדרש להמתין להצעת מחיר?',
	QUESTIONS: 'שאלות',
	MAX_ORDERS_PER_DAY: 'מקסימום שירותים ליום',
	REMARK: 'הערות',
	NAVIGATE_TO: 'עבור ל-',
	IS_TRANSPORTATION_AVAILABLE: 'לאפשר שינוע?',
	EPriceQuoteType: {
		Main: 'ראשי',
		Addition: 'תוספת',
	},
	EUserType: {
		Admin: 'מנהל מערכת',
		Regular: 'רגיל',
		Client: 'לקוח',
		GarageOwner: 'בעלים',
		ServiceAdvisor: 'יועצי שירות',
		WorkshopManager: 'מנהלי עבודה',
	},
	TRANSPORTATION_CONFIG: {
		SHOULD_ASK_FOR_DRIVING_LICENSE: 'נדרש להעלות צילום רכב?',
		SHOULD_ASK_FOR_PERSONAL_ID: 'נדרש לתעודה מזהה?',
		SHOULD_ASK_FOR_INSURANCE_DOCUMENT: 'נדרש להעלות ביטוח רכב?',
	},
	MONTHLY_SOLUTION_ORDERS: 'הזמנות חודשיות',
	TOTAL_GARAGES: 'מוסכים',
	TOTAL_CLIENTS: 'לקוחות',
	TOTAL_SOLUTION_ORDERS: 'הזמנות שירות',
	OPENED_SOLUTION_ORDERS: 'הזמנות שירות פתוחות',
	CLOSED_SOLUTION_ORDERS: 'הזמנות שירות סגורות',
	LABEL: 'כותרת',
	PLACEHOLDER: 'פלייסהולדר',
	IS_REQUIRED: 'חובה?',
	QUESTION_TYPE: 'סוג שאלה',
	Car: 'רכב',
	Motorbike: 'אופנוע',
	Truck: 'משאית',
	Manual: 'ידני',
	Automatic: 'אוטומט',
	Text: 'טקסט',
	LongText: 'טקסט ארוך',
	Numeric: 'מספרי',
	SentToClient: 'נשלח ללקוח',
	ClientApproved: 'לקוח אישר',
	ClientDeclined: 'לקוח סירב',
	ClientPaid: 'לקוח שילם',
	Pending: 'ממתין',
	Approved: 'מאושר',
	Blocked: 'חסום',
	NewOrder: 'הזמנה חדשה',
	GarageApproved: 'מוסך אישר',
	PriceQuoteApproved: 'הצעת מחיר אושרה',
	DeclinedPriceQuote: 'הצעת מחיר סורבה',
	InProgress: 'בעבודה',
	WaitingForPayment: 'ממתין לתשלום',
	Done: 'רכב נאסף',
	GarageDeclined: 'מוסך סירב',
	ClientCancelled: 'לקוח ביטל',
	GarageCancelled: 'מוסך ביטל',
	WaitingForPickup: 'ממתין לאיסוף',
	Gasoline: 'דלק',
	Diesel: 'דיזל',
	Electric: 'חשמלי',
	DRIVING_LICENSE_IMAGE: 'רישיון נהיגה',
	PERSONAL_ID_IMAGE: 'תמונת ת.ז.',
	INSURANCE_DOCUMENT: 'תעודת ביטוח',
	VIDEO: 'וידאו',
	GALLERY: 'גלריה',
	SCHEDULED_APPOINTMENT: 'תור שנקבע',
	ANSWERS: 'תשובות לשאלות',
	STATUS: 'סטטוס',
	TAX_ID: 'מספר זיהוי',
	OWNER: 'בעלים',
	PLATE_NUMBER: 'מספר רישוי',
	TYPE: 'סוג',
	YEAR: 'שנה',
	HORSE_POWER: 'כוחות סוס',
	ENGINE_SIZE: 'סמ״ק',
	GEAR_TYPE: 'הילוכים',
	COLOR: 'צבע',
	FILE: 'קובץ',
	QUESTION: 'שאלה',
	REASON: 'סיבה',
	DETAILS: 'פרטים',
	REVIEW: 'ביקורת',
	SETTINGS: 'הגדרות',
	TREATMENT_EXCEEDED_WARNING_TRIGGER_TIME_IN_MINUTES: 'זמן אזהרה לחריגה מזמן השירות בדקות',
	TREATMENT_EXCEEDED_EXCEPTION_TRIGGER_TIME_IN_MINUTES: 'זמן חריגה מזמן השירות בדקות',
	PRICE_QUOTE_EXCEEDED_EXCEPTION_TRIGGER_IN_MINUTES: 'זמן חריגה מזמן ההצעה בדקות',
	LIVE_PRICE_QUOTE_EXCEEDED_EXCEPTION_TRIGGER_IN_MINUTES: 'זמן חריגה מזמן ההצעה החיה בדקות',
	BRAND: 'חברה',
	MODEL: 'מודל',
	TEST_EXPIRES_ON: 'תוקף טסט',
	REPORTS: 'דו״חות',
	SOLUTION_ORDERS_TIME_OVERFLOWS_SEGMENTATIONS: 'חריגות זמן שירות',
	ORDERS_AVERAGE_HANDLING_TIME_BY_SOLUTION: 'זמן טיפול ממוצע לפי שירות (בשעות)',
	FEEDBACK_AVERAGE_SCORE_BY_QUESTIONS: 'ציון ממוצע לפי שאלות משו״ב',
	CLEAR_FILTERS: 'איפוס סינונים',
	IN_TIME: 'בזמן',
	WARNING_OVERFLOW: 'עיכוב קל',
	EXCEPTIONAL_OVERFLOW: 'עיכוב משמעותי',
	ALL: 'הכל',
	NOT_YET_DONE: 'טרם בוצעו',
	HISTORY: 'היסטוריה',
	EXCEPTIONS: 'חריגים',
	STARTED_AT: 'התחיל בשעה',
	FINISHED_AT: 'הסתיים בשעה',
	NUMBER_OF_VEHICLES: 'מס׳ רכבים',
	LOGOUT: 'התנתקות',
	APPROVAL_STATUS: 'סטטוס אישור',
	ASSIGNEE: 'יועץ שירות',
	VEHICLE_STATUS: 'סטטוס הרכב (מנהל עבודה)',
	TRANSPORTATION_ADDRESS: 'כתובת שינוע',
	TRANSPORTATION_CONTACT_NAME: 'שם איש קשר לשינוע',
	TRANSPORTATION_CONTACT_PHONE: 'טלפון איש קשר לשינוע',
	SOLUTION_ORDERS_BY_STATUS_SEGMENTATION: 'הזמנות שירות לפי סטטוס',
	CLIENTS_BY_APPROVAL_STATUS_SEGMENTATION: 'לקוחות לפי סטטוס אישור',
	PRICE_QUOTES_BY_STATUS_SEGMENTATION: 'הצעות מחיר לפי סטטוס',
	SOLUTION_ORDERS_BY_SOLUTION_SEGMENTATION: 'הזמנות שירות לפי סוג שירות',
	AVAILABLE_DAYS: 'ימים פעילים',
	SUNDAY_SHORT: 'א׳',
	MONDAY_SHORT: 'ב׳',
	TUESDAY_SHORT: 'ג׳',
	WEDNESDAY_SHORT: 'ד׳',
	THURSDAY_SHORT: 'ה׳',
	FRIDAY_SHORT: 'ו׳',
	SATURDAY_SHORT: 'ש׳',
	AVERAGE_SOLUTION_DURATION_IN_MINUTES: 'ממוצע ביצוע שירות (בדקות)',
	AVERAGE_SOLUTION_DURATION_HOURS: 'ממוצע ביצוע שירות (בשעות)',
	STANDARD_TIME_IN_MINUTES: 'זמן תקן (בדקות)',
	ORDER_STATUS: 'סטטוס הזמנה (יועצי שירות)',
	CLIENT_INPUTS: 'פרטים שהזין הלקוח',
	FROM_PRICE: 'ממחיר',
	TO_PRICE: 'עד מחיר',
	FROM_STANDARD_TIME: 'מזמן תקן',
	TO_STANDARD_TIME: 'עד זמן תקן',
	CREATE: '+ צור',
	EPriceQuoteStatus: {
		Draft: 'טיוטה',
		SentToClient: 'נשלח ללקוח',
		ClientApproved: 'לקוח אישר',
		ClientDeclined: 'לקוח סירב',
		ClientPaid: 'לקוח שילם',
	},
	ESolutionOrderStatus: {
		NewOrder: 'הזמנה חדשה',
		GarageApproved: 'מוסך אישר',
		WaitingForPriceQuote: 'ממתין להצעת מחיר',
		WaitingForClientApproval: 'ממתין לאישור לקוח',
		PriceQuoteApproved: 'הצעת מחיר אושרה',
		DeclinedPriceQuote: 'הצעת מחיר סורבה',
		InProgress: 'בתהליך',
		WaitingForPayment: 'ממתין לתשלום',
		Done: 'רכב נאסף',
		ClientCancelled: 'לקוח ביטל',
		GarageCancelled: 'מוסך ביטל',
		WaitingForPickup: 'ממתין לאיסוף',
	},
	NO_QUESTIONS_ANSWERED: 'אין שאלות שנענו',
	PHONE: 'מספר טלפון',
	EVehicleGearType: {
		Manual: 'ידני',
		Automatic: 'אוטומטי'
	},
	EQuestionType: {
		Text: 'טקסט',
		LongText: 'טקסט ארוך',
		Numeric: 'נומרי'
	},
	EVehicleType: {
		Car: 'רכב',
		Motorbike: 'אופנוע',
		Truck: 'משאית'
	},
	EVehicleStatus: {
		Waiting: 'ממתין במוסך',
		InWork: 'בעבודה',
		InDrivingCheck: 'בנסיעת מבחן',
		InTest: 'בטסט',
		InWash: 'בשטיפה',
		InTireShop: 'בפנצ׳ריה',
		InWheelsAlignment: 'בכיוון פרונט',
		BackToOffice: 'חזרה למשרד',
	},
	EVehicleDrivability: {
		Drivable: 'ניתן לנסיעה',
		UnDrivable: 'לא ניתן לנסיעה'
	},
	EApprovalStatus: {
		Pending: 'ממתין לאישור',
		Approved: 'מאושר',
		Blocked: 'חסום',
	},
	VEHICLE_DRIVABILITY: 'האם ניתן לנסוע ברכב?',
	FEEDBACK_ID: '# משו״ב',
	FEEDBACK_VALUE: 'דירוג',
	ANSWER: 'תשובה',
	EMAIL: 'אימייל',
	PASSWORD: 'סיסמה',
	IMPORT_VEHICLES_PLATES_FROM_EXCEL: 'יבוא מאקסל מספרים רישוי',
	IMPORT_COLUMN: 'טור',
	general: {
		error: 'שגיאה כללית'
	},
	message: {
		error: 'שגיאה כללית'
	},
	error: {
		general: 'שגיאה כללית',
		request: {
			invalid_req_params: 'נשלחו פרטים לא תקינים'
		},
		files: {
			empty: 'לא נשלח קובץ',
			unsupported_ext: 'סיומת קובץ לא נתמכת'
		},
		auth: {
			wrong_credentials: 'פרטי הזדהות לא תקינים',
			not_admin: 'אין הרשאות אדמין',
			not_client: 'אין הרשאות לקוח',
			failed_authenticating_jwt: 'משתמש לא מזוהה',
			not_registered: 'משתמש לא קיים',
			invalid_token: 'משתמש פג תוקף'
		}
	},
	resources: {
		'solution-feedback': { name: 'משו״ב', },
		'solution-feedbacks': { name: 'משו״בים', },

		'feedback-answer': { name: 'תשובת משו״ב', },
		'feedback-answers': { name: 'תשובות משו״ב', },

		'garage-downtime': { name: 'יום סגור', },
		'garage-downtimes': { name: 'ימים סגורים', },

		'feedback-question': { name: 'שאלת משו״ב', },
		'feedback-questions': { name: 'שאלות משו״ב', },

		'price-quote': { name: 'הצעת מחיר', },
		'price-quotes': { name: 'הצעות מחיר', },

		'vehicle': { name: 'רכב', },
		'vehicles': { name: 'רכבים', },

		'solution-answer': { name: 'תשובה', },
		'solution-answers': { name: 'תשובות', },

		'client': { name: 'לקוח', },
		'clients': { name: 'לקוחות', },

		'solution-order': { name: 'הזמנת שירות', },
		'solution-orders': { name: 'הזמנות שירות', },

		'solution-question': { name: 'שאלת שירות', },
		'solution-questions': { name: 'שאלות שירות', },

		'solution': { name: 'שירות', },
		'solutions': { name: 'שירותים', },

		'employee': { name: 'עובד', },
		'employees': { name: 'עובדים', },

		'garage': { name: 'מוסך', },
		'garages': { name: 'מוסכים', },

		'product': { name: 'מוצר' },
		'products': { name: 'מוצרים' },
	},
	ra: {
		configurable: {
			customize: 'הערה מוזרה'
		},
		saved_queries: {
			label: 'חיפושים שמורים',
			query_name: 'כותרת חיפוש',
			new_label: 'שמירת חיפוש',
			new_dialog_title: 'שמירת חיפוש בתור',
			remove_label: 'מחיקת חיפוש',
			remove_label_with_name: 'מחיקת חיפוש "%{name}"',
			remove_dialog_title: 'למחוק את החיפוש?',
			remove_message:
				'האם אתה בטוח שברצונך למחוק חיפוש מרשימת החיפושים?',
			help: 'חפש ושמור את החיפוש לפעם הבאה',
		},
		action: {
			clear_input_value: 'נקה',
			delete: 'מחק',
			unselect: 'בטל בחירה',
			sort: 'מיון',
			show: 'הצג',
			undo: 'שחזר',
			confirm: 'אישור',
			bulk_actions: 'נבחרו %{smart_count} פריטים',
			list: 'רשימה',
			export: 'ייצוא',
			save: 'שמור',
			create: 'צור',
			edit: 'ערוך',
			cancel: 'ביטול',
			refresh: 'רענן',
			add_filter: 'הוסף סינון',
			remove_filter: 'הסר סינון',
			back: 'חזור'
		},
		boolean: {
			true: 'כן',
			false: 'לא'
		},
		page: {
			list: 'רשימת %{name}',
			edit: '%{name} #%{id}',
			show: '%{name} #%{id}',
			empty: 'לא נמצאו תוצאות',
			invite: 'מקווים שממש בקרוב יגיע לפה מידע...',
			create: 'צור %{name}',
			delete: 'מחק %{name} #%{id}',
			dashboard: 'דשבורד',
			not_found: 'לא קיים',
			error: 'שגיאה'
		},
		input: {
			references: {
				single_missing: 'חסר'
			},
			file: {
				upload_several:
					'גרור מספר קבצים להעלאה או לחץ לבחור אחד.',
				upload_single: 'גרור קובץ להעלאה או לחץ לבחור אחד.'
			},
			image: {
				upload_several:
					'גרור מספר תמונות להעלאה או לחץ לבחור אחת.',
				upload_single:
					'גרור תמונה להעלאה או לחץ לבחור אחת.'
			}
		},
		message: {
			error: 'אופס.. קרתה שגיאה',
			invalid_form: 'טופס לא תקין',
			yes: 'כן',
			no: 'לא',
			are_you_sure: 'האם את/ה בטוח/ה ?',
			about: 'אודות',
			not_found:
				'הכנסת כתובת שגויה או לחצת על קישור לא תקין',
			delete_title: 'מחיקה',
			delete_content: 'האם אתה בטוח שברצונך למחוק?',
			bulk_delete_title: 'שים לב!',
			bulk_delete_content: 'האם אתה בטוח שברצונך למחוק את כל המידע הזה?'
		},
		navigation: {
			no_results: 'לא נמצאו תוצאות',
			page_rows_per_page: 'מס׳ שורות בעמוד',
			skip_nav: 'דילוג',
			page_out_of_boundaries: 'עמוד %{page} מחוץ לתחום',
			page_out_from_end: 'לא ניתן להמשיך מעבר לעמוד האחרון',
			page_out_from_begin: 'לא ניתן לגשת לפני העמוד הראשון',
			page_range_info: '%{offsetBegin}-%{offsetEnd} מתוך %{total}',
			next: 'הבא',
			prev: 'הקודם'
		},
		auth: {
			username: 'שם משתמש',
			password: 'סיסמא',
			sign_in: 'כניסה',
			sign_in_error: 'הכניסה נכשלה, אנא נסו שנית',
			logout: 'התנתקות',
			auth_check_error: 'הזדהות לא הצליחה'
		},
		notification: {
			data_provider_error: 'תקלה בתקשורת מידע',
			updated: 'פריט עודכן בהצלחה',
			created: 'פריט נוצר בהצלחה',
			deleted: 'פריט נמחק בהצלחה',
			item_doesnt_exist: 'פריט לא קיים',
			http_error: 'שגיאה בהתקשרות מול השרת'
		},
		validation: {
			required: 'שדה חובה',
			minLength: 'חייב להכיל לפחות %{min} תווים',
			maxLength: 'יכול להכיל לכל היותר %{max} תווים',
			minValue: 'חייב להיות לפחות %{min}',
			maxValue: 'חייב להיות %{max} או פחות',
			number: 'חייב להיות מספר',
			email: 'כתובת דוא"ל לא תקנית'
		}
	}
};
