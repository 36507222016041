import * as React from 'react';
import { Edit, EditProps, SimpleForm } from 'react-admin';
import { EmployeeForm } from './EmployeeForm';

export const EmployeeEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<EmployeeForm/>
			</SimpleForm>
		</Edit>
	);
};
