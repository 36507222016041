import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	AutocompleteInput,
	BulkActionProps,
	BulkDeleteButton,
	DateInput,
	List,
	ListProps,
	ReferenceInput,
	TextInput, usePermissions,
} from 'react-admin';
import { EmployeeList } from './EmployeeList';
import { EmployeeDatagrid } from './EmployeeDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Employee } from './Employee';
import { isAdminOrHigher } from '../providers/auth.provider';

export * from './Employee';
export * from './EmployeeCreate';
export * from './EmployeeDatagrid';
export * from './EmployeeEdit';
export * from './EmployeeList';
export * from './EmployeeShow';

const EmployeesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'userId', header: 'USER' },
	{ key: 'garageId', header: 'GARAGE' },
	{ key: 'isManager', header: 'IS_MANAGER' },
];

export const EmployeesData = (props: ListProps): React.ReactElement => {
	const { permissions } = usePermissions();

	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: Employee) => entity;

	const employeesFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		...(isAdminOrHigher(permissions)) ? [<ReferenceInput
			label="GARAGE"
			source="garageId" reference="garages"
			alwaysOn
		>
			<AutocompleteInput label="GARAGE" optionText="name.he"/>
		</ReferenceInput>] : [],
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('עובדים', exportFields, exportDataMapper)}
				{...props}
				filters={employeesFilter}
				bulkActionButtons={<EmployeesBulkActionButtons/>}
				title="EMPLOYEES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <EmployeeList/> : <EmployeeDatagrid/>}
			</List>
		</Fragment>
	);
};
