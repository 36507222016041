import * as React from 'react';
import {
	AutocompleteInput,
	Create,
	CreateProps,
	DateInput,
	maxValue,
	minValue,
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput, usePermissions
} from 'react-admin';
import { SingleImageInput } from '../components/SingleImageInput';
import { EApprovalStatus, EVehicleGearType, EVehicleType } from '../enums';
import { isAdminOrHigher } from '../providers/auth.provider';

export const VehicleCreate = (props: CreateProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Create {...props}>
			<SimpleForm>
				<ReferenceInput
					label="OWNER"
					source="ownerId" reference="clients"
					alwaysOn
					validate={[required()]}
				>
					<AutocompleteInput label="OWNER" optionText="user.fullName" validate={[required()]}/>
				</ReferenceInput>

				{isAdminOrHigher(permissions) && <ReferenceInput
					label="GARAGE"
					source="garageId" reference="garages"
					alwaysOn
					validate={[required()]}
				>
					<AutocompleteInput label="GARAGE" optionText="name.he" validate={[required()]}/>
				</ReferenceInput>}

				<SelectInput source="approvalStatus" label="APPROVAL_STATUS"
				             defaultValue={EApprovalStatus.Approved}
				             choices={Object.keys(EApprovalStatus).map(key => ({
					             id: key,
					             name: `EApprovalStatus.${EApprovalStatus[key]}`
				             }))}/>

				<SingleImageInput source="imageUrl" label="IMAGE"/>
				<TextInput source="plateNumber" label="PLATE_NUMBER" validate={[required()]}/>
				<NumberInput source="year" label="YEAR" validate={[required(), maxValue(2030), minValue(1800)]}/>
				<TextInput source="brand" label="BRAND"/>
				<TextInput source="model" label="MODEL"/>
				<DateInput source="testExpiresOn" label="TEST_EXPIRES_ON"/>
				<SelectInput source="type" label="TYPE" validate={[required()]}
				             defaultValue={EVehicleType.Car}
				             choices={Object.keys(EVehicleType).map(key => ({
					             id: key,
					             name: `EVehicleType.${EVehicleType[key]}`
				             }))}/>
				<NumberInput source="horsePower" label="HORSE_POWER"/>
				<NumberInput source="engineSize" label="ENGINE_SIZE"/>
				<SelectInput source="gearType" label="GEAR_TYPE"
				             defaultValue={EVehicleGearType.Automatic}
				             choices={Object.keys(EVehicleGearType).map(key => ({
					             id: key,
					             name: `EVehicleGearType.${EVehicleGearType[key]}`
				             }))}
				/>
				<TextInput source="color" label="COLOR"/>
			</SimpleForm>
		</Create>
	);
};
