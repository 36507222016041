import * as React from 'react';
import {
	NumberField,
	ReferenceField,
	Show,
	ShowProps,
	TabbedShowLayout,
	TextField,
	usePermissions,
} from 'react-admin';
import { ClientVehiclesView } from './ClientVehiclesView';
import { ApprovalStatusToColor } from '../util/statuses-colors.config';
import { StatusField } from '../components/StatusField';
import { PhoneField } from '../components/PhoneField';
import { ClientSolutionOrdersView } from './ClientSolutionOrdersView';
import { isAdminOrHigher, isServiceAdvisorOrHigher } from '../providers/auth.provider';

export const ClientShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={isServiceAdvisorOrHigher(permissions) ? undefined : false}>
			<TabbedShowLayout>
				<TabbedShowLayout.Tab label="DETAILS">
					{/*<TabbedShowLayout.Tab label="SOLUTIONS" path="solutions">*/}
					{/*	<GarageSolutionsView/>*/}
					{/*</TabbedShowLayout.Tab>*/}
					<TextField source="id" label="#"/>
					<TextField source="user.fullName" label="FULL_NAME"/>
					<PhoneField source="user.phone" label="PHONE"/>
					{isAdminOrHigher(permissions) &&
                        <ReferenceField source="garageId" reference="garages" label="GARAGE" link="show">
                            <TextField source="name.he"/>
                        </ReferenceField>}
					<TextField source="taxId" label="TAX_ID"/>
					<NumberField source="vehiclesIds.length" label="NUMBER_OF_VEHICLES"/>
					<StatusField source="approvalStatus" label="APPROVAL_STATUS" enumName="EApprovalStatus"
					             enumToColors={ApprovalStatusToColor}/>
					{isAdminOrHigher(permissions) &&
                        <TextField source="description" label="DESCRIPTION"/>}
				</TabbedShowLayout.Tab>
				<TabbedShowLayout.Tab label="VEHICLES" path="vehicles">
					<ClientVehiclesView/>
				</TabbedShowLayout.Tab>
				<TabbedShowLayout.Tab label="SOLUTION_ORDERS" path="solution-orders">
					<ClientSolutionOrdersView/>
				</TabbedShowLayout.Tab>
			</TabbedShowLayout>
		</Show>
	);
};
