import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { AutocompleteInput, DateInput, List, ListProps, ReferenceInput, TextInput, usePermissions, } from 'react-admin';
import { SolutionList } from './SolutionList';
import { SolutionDatagrid } from './SolutionDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Solution } from './Solution';
import { isAdminOrHigher } from '../providers/auth.provider';

export * from './Solution';
export * from './SolutionCreate';
export * from './SolutionDatagrid';
export * from './SolutionEdit';
export * from './SolutionList';
export * from './SolutionShow';

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'garage', header: 'GARAGE' },
	{ key: 'index', header: 'INDEX' },
	{ key: 'isEnabled', header: 'IS_ENABLED' },
	{ key: 'name', header: 'NAME' },
	{ key: 'imageUrl', header: 'IMAGE_URL' },
	{ key: 'price', header: 'PRICE' },
	{ key: 'shouldSelectVehicle', header: 'SHOULD_SELECT_VEHICLE' },
	{ key: 'shouldAskForImage', header: 'SHOULD_ASK_FOR_IMAGE' },
	{ key: 'shouldAskForDrivingLicenseImage', header: 'SHOULD_ASK_FOR_DRIVING_LICENSE_IMAGE' },
	{ key: 'shouldAskForVideo', header: 'SHOULD_ASK_FOR_VIDEO' },
	{ key: 'shouldAskForGallery', header: 'SHOULD_ASK_FOR_GALLERY' },
	{ key: 'shouldSchedule', header: 'SHOULD_SCHEDULE' },
	{ key: 'shouldAskForCarStatus', header: 'SHOULD_ASK_FOR_CAR_STATUS' },
	{ key: 'shouldWaitForPriceQuote', header: 'SHOULD_WAIT_FOR_PRICE_QUOTE' },
	{ key: 'questions', header: 'QUESTIONS' },
	{ key: 'maxOrdersPerDay', header: 'MAX_ORDERS_PER_DAY' },
	{ key: 'remark', header: 'REMARK' },
	{ key: 'navigateTo', header: 'NAVIGATE_TO' },
	{ key: 'isTransportationAvailable', header: 'IS_TRANSPORTATION_AVAILABLE' },
	{
		key: 'transportationConfig.shouldAskForDrivingLicense',
		header: 'TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_DRIVING_LICENSE'
	},
	{ key: 'transportationConfig.shouldAskForPersonalId', header: 'TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_PERSONAL_ID' },
	{
		key: 'transportationConfig.shouldAskForInsuranceDocument',
		header: 'TRANSPORTATION_CONFIG.SHOULD_ASK_FOR_INSURANCE_DOCUMENT'
	},
];

export const SolutionsData = (props: ListProps): React.ReactElement => {
	const { permissions } = usePermissions();

	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: Solution) => entity;

	const solutionsFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		...(isAdminOrHigher(permissions)) ? [<ReferenceInput
			label="GARAGE"
			source="garageId" reference="garages"
			alwaysOn
		>
			<AutocompleteInput label="GARAGE" optionText="name.he"/>
		</ReferenceInput>] : [],
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('פתרונות', exportFields, exportDataMapper)}
				{...props}
				filters={solutionsFilter}
				bulkActionButtons={false}
				title="SOLUTIONS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				hasCreate={false}
				pagination={<Pagination/>}
			>
				{isXSmall ? <SolutionList/> : <SolutionDatagrid/>}
			</List>
		</Fragment>
	);
};
