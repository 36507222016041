import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	AutocompleteInput,
	BulkActionProps,
	BulkDeleteButton,
	DateInput,
	List,
	ListProps,
	ReferenceInput,
	TextInput, usePermissions,
} from 'react-admin';
import { GarageDowntimeList } from './GarageDowntimeList';
import { GarageDowntimeDatagrid } from './GarageDowntimeDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { GarageDowntime } from './GarageDowntime';
import { isAdminOrHigher } from '../providers/auth.provider';

export * from './GarageDowntime';
export * from './GarageDowntimeCreate';
export * from './GarageDowntimeDatagrid';
export * from './GarageDowntimeEdit';
export * from './GarageDowntimeList';
export * from './GarageDowntimeShow';

const GarageDowntimesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'garage', header: 'GARAGE' },
	{ key: 'from', header: 'FROM_DATE' },
	{ key: 'to', header: 'TO_DATE' },
	{ key: 'reason.he', header: 'REASON' },
];

export const GarageDowntimesData = (props: ListProps): React.ReactElement => {
	const { permissions } = usePermissions();

	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: GarageDowntime) => entity;

	const garageDowntimesFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		...(isAdminOrHigher(permissions)) ? [<ReferenceInput
			label="GARAGE"
			source="garageId" reference="garages"
			alwaysOn
		>
			<AutocompleteInput label="GARAGE" optionText="name.he"/>
		</ReferenceInput>] : [],
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('ימים סגורים', exportFields, exportDataMapper)}
				{...props}
				filters={garageDowntimesFilter}
				bulkActionButtons={<GarageDowntimesBulkActionButtons/>}
				title="GARAGE_DOWNTIMES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <GarageDowntimeList/> : <GarageDowntimeDatagrid/>}
			</List>
		</Fragment>
	);
};
