import * as React from 'react';
import {
	EditButton,
	Labeled,
	NumberField,
	ReferenceField,
	ShowButton,
	TextField,
	useListContext,
	usePermissions
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { ApprovalStatusToColor } from '../util/statuses-colors.config';
import { StatusField } from '../components/StatusField';
import { PhoneField } from '../components/PhoneField';
import { isAdminOrHigher } from '../providers/auth.provider';

const clientStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const ClientList = () => {
	const { permissions } = usePermissions();

	const classes = clientStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="clients"
										record={entity}
									/>
									<EditButton
										resource="clients"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={entity} source="id" label="#"/>
						</Labeled>
						<Labeled label="FULL_NAME" sx={classes.cardContentRow}>
							<TextField source="user.fullName" label="FULL_NAME"/>
						</Labeled>
						<Labeled label="PHONE" sx={classes.cardContentRow}>
							<PhoneField source="user.phone" label="PHONE"/>
						</Labeled>
						{isAdminOrHigher(permissions) && <Labeled label="GARAGE" sx={classes.cardContentRow}>
                            <ReferenceField record={entity} source="garageId" label="FORUM" reference="garages"
                                            link="show">
                                <TextField source="name.he"/>
                            </ReferenceField>
                        </Labeled>}
						<Labeled label="TAX_ID" sx={classes.cardContentRow}>
							<TextField record={entity} source="taxId" label="TAX_ID"/>
						</Labeled>
						<Labeled label="NUMBER_OF_VEHICLES" sx={classes.cardContentRow}>
							<NumberField record={entity} source="vehiclesIds.length" label="NUMBER_OF_VEHICLES"/>
						</Labeled>
						<Labeled label="NUMBER_OF_VEHICLES" sx={classes.cardContentRow}>
							<StatusField record={entity} source="approvalStatus" enumName="EApprovalStatus"
							             label="APPROVAL_STATUS"
							             enumToColors={ApprovalStatusToColor}/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
