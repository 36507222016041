import * as React from 'react';
import { List, Loading, useRecordContext, } from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';
import { FeedbackAnswerDatagrid, FeedbackAnswerList } from '../feedback-answer';

export const SolutionFeedbackAnswersView = (): React.ReactElement => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

	if (!record) {
		return <Loading/>;
	}

	return (
		<List
			actions={false}
			hasCreate={false}
			resource="feedback-answers"
			sort={{ field: '_createdAt', order: 'DESC' }}
			perPage={20}
			filter={{ feedback: record?.id }}
		>
			{isXSmall ? <FeedbackAnswerList/> : <FeedbackAnswerDatagrid/>}
		</List>
	);
};
