import * as React from 'react';
import {
	AutocompleteInput,
	Edit,
	EditProps,
	ReferenceInput,
	required,
	SimpleForm,
	TextInput,
	usePermissions
} from 'react-admin';
import { isAdminOrHigher } from '../providers/auth.provider';

export const FeedbackQuestionEdit = (props: EditProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				{isAdminOrHigher(permissions) && <ReferenceInput
                    label="GARAGE"
                    source="garageId" reference="garages"
                    alwaysOn
                    validate={[required()]}
                >
                    <AutocompleteInput label="GARAGE" optionText="name.he" validate={[required()]}/>
                </ReferenceInput>}

				<TextInput source="label.he" label="LABEL" validate={[required()]}/>
			</SimpleForm>
		</Edit>
	);
};
