import * as React from 'react';
import { Datagrid, NumberField, ReferenceField, TextField, usePermissions } from 'react-admin';
import './ClientDatagrid.scss';
import { ApprovalStatusToColor } from '../util/statuses-colors.config';
import { StatusField } from '../components/StatusField';
import { PhoneField } from '../components/PhoneField';
import { isAdminOrHigher } from '../providers/auth.provider';

export const ClientDatagrid = (props): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="show" className="clients-table" optimized
		          {...(props ?? {})}
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField sortable={false} source="id" label="#"/>
			<TextField source="user.fullName" label="FULL_NAME"/>
			<PhoneField source="user.phone" label="PHONE"/>
			{isAdminOrHigher(permissions) &&
                <ReferenceField sortable={false} source="garageId" reference="garages" label="GARAGE" link="show">
                    <TextField sortable={false} source="name.he"/>
                </ReferenceField>}
			<TextField source="taxId" label="TAX_ID"/>
			<NumberField source="vehiclesIds.length" label="NUMBER_OF_VEHICLES"/>
			<StatusField source="approvalStatus" label="APPROVAL_STATUS" enumName="EApprovalStatus"
			             enumToColors={ApprovalStatusToColor}/>
		</Datagrid>
	)
		;
};
