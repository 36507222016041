import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	BulkActionProps,
	BulkDeleteButton,
	DateInput,
	List,
	ListProps,
	NumberInput,
	SelectInput,
	TextInput,
} from 'react-admin';
import { PriceQuoteList } from './PriceQuoteList';
import { PriceQuoteDatagrid } from './PriceQuoteDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { PriceQuote } from './PriceQuote';
import { EPriceQuoteStatus } from '../enums';

export * from './PriceQuote';
export * from './PriceQuoteCreate';
export * from './PriceQuoteDatagrid';
export * from './PriceQuoteEdit';
export * from './PriceQuoteList';
export * from './PriceQuoteShow';

const PriceQuotesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'solutionOrder', header: 'SOLUTION_ORDER' },
	{ key: 'status', header: 'STATUS' },
	{ key: 'description', header: 'DESCRIPTION' },
	{ key: 'price', header: 'PRICE' },
	{ key: 'fileUrl', header: 'FILE' },
	{ key: 'gallery', header: 'GALLERY' },
];

export const PriceQuotesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: PriceQuote) => entity;

	const priceQuotesFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<SelectInput source="eq_status" label="STATUS"
		             alwaysOn
		             choices={Object.keys(EPriceQuoteStatus).map(key => ({
			             id: key,
			             name: `EPriceQuoteStatus.${EPriceQuoteStatus[key]}`
		             }))}
		/>,
		<NumberInput source="from_price" label="FROM_PRICE"/>,
		<NumberInput source="to_price" label="TO_PRICE"/>,
		<NumberInput source="from_standardTimeInMinutes" label="FROM_STANDARD_TIME"/>,
		<NumberInput source="to_standardTimeInMinutes" label="TO_STANDARD_TIME"/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('הצעות מחיר', exportFields, exportDataMapper)}
				{...props}
				filters={priceQuotesFilter}
				bulkActionButtons={<PriceQuotesBulkActionButtons/>}
				title="PRICE_QUOTES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <PriceQuoteList/> : <PriceQuoteDatagrid/>}
			</List>
		</Fragment>
	);
};
