import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { DateInput, List, ListProps, } from 'react-admin';
import { SolutionFeedbackList } from './SolutionFeedbackList';
import { SolutionFeedbackDatagrid } from './SolutionFeedbackDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { SolutionFeedback } from './SolutionFeedback';

export * from './SolutionFeedback';
export * from './SolutionFeedbackCreate';
export * from './SolutionFeedbackDatagrid';
export * from './SolutionFeedbackEdit';
export * from './SolutionFeedbackList';
export * from './SolutionFeedbackShow';

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'garageId', header: 'GARAGE' },
	{ key: 'orderId', header: 'SOLUTION_ORDER' },
	{ key: 'review', header: 'REVIEW' },
];

export const SolutionFeedbacksData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: SolutionFeedback) => entity;

	const solutionFeedbacksFilter = [
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('משו״בים', exportFields, exportDataMapper)}
				{...props}
				filters={solutionFeedbacksFilter}
				title="SOLUTION_FEEDBACKS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				hasCreate={false}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <SolutionFeedbackList/> : <SolutionFeedbackDatagrid/>}
			</List>
		</Fragment>
	);
};
