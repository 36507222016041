import * as React from 'react';
import { Datagrid, ReferenceField, TextField } from 'react-admin';
import './SolutionAnswerDatagrid.scss';

export const SolutionAnswerDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="solution-answers-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField sortable={false} source="id" label="#"/>
			<ReferenceField sortable={false} source="questionId" reference="solution-questions" label="QUESTION"
			                link="show">
				<TextField sortable={false} source="label.he"/>
			</ReferenceField>

			<ReferenceField sortable={false} source="orderId" reference="solution-orders" label="SOLUTION_ORDER"
			                link="show">
				<TextField sortable={false} source="client.user.fullName"/>
			</ReferenceField>

			<TextField source="content" label="CONTENT"/>
		</Datagrid>
	);
};
