import * as React from 'react';
import {
	FileField,
	FunctionField,
	NumberField,
	ReferenceField,
	Show,
	ShowProps,
	SimpleShowLayout,
	TextField,
	usePermissions,
} from 'react-admin';
import { PriceField } from '../components/PriceField';
import { GalleryField } from '../components/GalleryField';
import { PriceQuoteStatusToColor } from '../util/statuses-colors.config';
import { StatusField } from '../components/StatusField';
import { TranslateField } from '../components/TranslateField';
import { isServiceAdvisorOrHigher } from '../providers/auth.provider';
import { vehiclesPlateNumberFormatter } from '../util/Formatters';

export const PriceQuoteShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={isServiceAdvisorOrHigher(permissions) ? undefined : false}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				<ReferenceField source="solutionOrderId" reference="solution-orders" label="SOLUTION_ORDER" link="show">
					<FunctionField
						render={record => `#${record?.id} - ${record?.client?.user?.fullName} - ${record?.vehicle?.plateNumber}`}/>
				</ReferenceField>
				<ReferenceField source="solutionOrderId" reference="solution-orders" label="PLATE_NUMBER" link={false}>
					<FunctionField render={record => vehiclesPlateNumberFormatter(record.vehicle.plateNumber)}/>
				</ReferenceField>
				<TranslateField source="type" label="TYPE" enumName="EPriceQuoteType"/>
				<StatusField source="status" label="STATUS" enumToColors={PriceQuoteStatusToColor}
				             enumName="EPriceQuoteStatus"/>
				<TextField source="description" label="DESCRIPTION"/>
				<PriceField source="price" label="PRICE"/>
				<NumberField source="standardTimeInMinutes" label="STANDARD_TIME_IN_MINUTES"/>
				<FileField source="fileUrl" label="FILE"/>
				<GalleryField source="gallery" label="GALLERY"/>
			</SimpleShowLayout>
		</Show>
	);
};
