import React, { useCallback, useEffect, useState } from 'react';
import {
	AutocompleteInput,
	DateInput,
	Filter,
	ListContextProvider,
	ReferenceInput,
	usePermissions,
	useTranslate
} from 'react-admin';
import { Button, Theme, useMediaQuery } from '@mui/material';
import './Reports.scss';
import { formatQueryParams } from '../util/Formatters';
import { httpClient } from '../providers/http_client';
import { API_URL, LOCAL_STORAGE_GARAGE_ID, LOCAL_STORAGE_USER_TYPE } from '../providers/constants';
import { serialize } from '../util/Serialize';
import PieSegmentationChart from '../components/dashboard/PieSegmentationChart';
import { Spacer, VerticalSpacer } from '../components/Spacers';
import { SegmentationReport } from './reports.interface';
import BarsSegmentationChart from '../components/dashboard/BarsSegmentationChart';
import { EUserType } from '../enums';
import { isAdminOrHigher } from '../providers/auth.provider';

const styles = {
	flex: { display: 'flex' },
	flexColumn: { display: 'flex', flexDirection: 'column' },
	leftCol: { flex: 1, marginRight: '0.5em' },
	rightCol: { flex: 1, marginLeft: '0.5em' },
	singleCol: { marginTop: '1em', marginBottom: '1em' }
};

export const Reports = () => {
	const { permissions } = usePermissions();

	const translator = useTranslate();
	const [solutionOrdersTimeOverflowsSegmentations, setSolutionOrdersTimeOverflowsSegmentations] = useState<SegmentationReport>();
	const [ordersAverageHandlingTimeBySolution, setOrdersAverageHandlingTimeBySolution] = useState<SegmentationReport>();
	const [feedbackAverageScoreByQuestions, setFeedbackAverageScoreByQuestions] = useState<SegmentationReport>();
	const [solutionOrdersByStatusSegmentation, setSolutionOrdersByStatusSegmentation] = useState<SegmentationReport>();
	const [clientsByApprovalStatusSegmentation, setClientsByApprovalStatusSegmentation] = useState<SegmentationReport>();
	const [priceQuotesByStatusSegmentation, setPriceQuotesByStatusSegmentation] = useState<SegmentationReport>();
	const [solutionOrdersBySolutionSegmentation, setSolutionOrdersBySolutionSegmentation] = useState<SegmentationReport>();

	const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const [garageId, setGarageId] = useState(localStorage.getItem(LOCAL_STORAGE_GARAGE_ID));

	const [filterValues, setFilterValues] = useState({
		from__createdAt: undefined,
		to__createdAt: undefined,
	});

	const setFilters = (filters) => {
		const filter = prepareFilter(filters);

		setFilterValues(filter);

		if (filter.garageId) {
			setTimeout(() => {
				fetchAllReports(filter);
			}, 200);
		}
	};

	const prepareFilter = (filters) => {
		const filterClone = { ...filters };
		Object.keys(filterClone).forEach(k => filters[k] = (filters[k] !== '' && filters[k] !== null) ? filters[k] : undefined);

		return filterClone;
	};

	const fetchSolutionOrdersTimeOverflowsSegmentations = useCallback(async (filters = filterValues, reportsUrl) => {
		const properties = formatQueryParams({
			filter: filters,
			pagination: { perPage: 9999, page: 1 },
			sort: { field: 'id', order: 'ASC' }
		});

		const { json } = await httpClient(`${reportsUrl}/solution-orders-time-overflows-segmentations?${serialize(properties)}`);

		setSolutionOrdersTimeOverflowsSegmentations(json.data);
	}, [filterValues]);

	const fetchOrdersAverageHandlingTimeBySolution = useCallback(async (filters = filterValues, reportsUrl) => {
		const properties = formatQueryParams({
			filter: filters,
			pagination: { perPage: 9999, page: 1 },
			sort: { field: 'id', order: 'ASC' }
		});

		const { json } = await httpClient(`${reportsUrl}/order-average-handling-time-by-solution?${serialize(properties)}`);

		setOrdersAverageHandlingTimeBySolution((json.data as SegmentationReport)?.map((item) => {
			return {
				name: item.name,
				value: item.value / 60
			};
		}));
	}, [filterValues]);

	const fetchFeedbackAverageScoreByQuestions = useCallback(async (filters = filterValues, reportsUrl) => {
		const properties = formatQueryParams({
			filter: filters,
			pagination: { perPage: 9999, page: 1 },
			sort: { field: 'id', order: 'ASC' }
		});

		const { json } = await httpClient(`${reportsUrl}/feedback-questions-average-score?${serialize(properties)}`);

		setFeedbackAverageScoreByQuestions(json.data);
	}, [filterValues]);

	const fetchSolutionOrdersByStatusSegmentation = useCallback(async (filters = filterValues, reportsUrl) => {
		const properties = formatQueryParams({
			filter: filters,
			pagination: { perPage: 9999, page: 1 },
			sort: { field: 'id', order: 'ASC' }
		});

		const { json } = await httpClient(`${reportsUrl}/solution-orders-by-status-segmentation?${serialize(properties)}`);

		setSolutionOrdersByStatusSegmentation(json.data);
	}, [filterValues]);

	const fetchClientsByApprovalStatusSegmentation = useCallback(async (filters = filterValues, reportsUrl) => {
		const properties = formatQueryParams({
			filter: filters,
			pagination: { perPage: 9999, page: 1 },
			sort: { field: 'id', order: 'ASC' }
		});

		const { json } = await httpClient(`${reportsUrl}/clients-by-approval-status-segmentation?${serialize(properties)}`);

		setClientsByApprovalStatusSegmentation(json.data);
	}, [filterValues]);

	const fetchPriceQuotesByStatusSegmentation = useCallback(async (filters = filterValues, reportsUrl) => {
		const properties = formatQueryParams({
			filter: filters,
			pagination: { perPage: 9999, page: 1 },
			sort: { field: 'id', order: 'ASC' }
		});
		const { json } = await httpClient(`${reportsUrl}/price-quotes-by-status-segmentation?${serialize(properties)}`);

		setPriceQuotesByStatusSegmentation(json.data);
	}, [filterValues]);

	const fetchSolutionOrdersBySolutionSegmentation = useCallback(async (filters = filterValues, reportsUrl) => {
		const properties = formatQueryParams({
			filter: filters,
			pagination: { perPage: 9999, page: 1 },
			sort: { field: 'id', order: 'ASC' }
		});

		const { json } = await httpClient(`${reportsUrl}/solution-orders-by-solution-segmentation?${serialize(properties)}`);

		setSolutionOrdersBySolutionSegmentation(json.data);
	}, [filterValues]);

	const fetchAllReports = useCallback((filter) => {
		let reportsUrl = `${API_URL}/admin/reports/${garageId}`;

		if (localStorage.getItem(LOCAL_STORAGE_USER_TYPE) !== EUserType.Admin) {
			const garageId = localStorage.getItem(LOCAL_STORAGE_GARAGE_ID);
			reportsUrl = `${API_URL}/garages/${garageId}/reports`;
		}

		fetchSolutionOrdersTimeOverflowsSegmentations(filter, reportsUrl);
		fetchOrdersAverageHandlingTimeBySolution(filter, reportsUrl);
		fetchFeedbackAverageScoreByQuestions(filter, reportsUrl);
		fetchSolutionOrdersByStatusSegmentation(filter, reportsUrl);
		fetchClientsByApprovalStatusSegmentation(filter, reportsUrl);
		fetchPriceQuotesByStatusSegmentation(filter, reportsUrl);
		fetchSolutionOrdersBySolutionSegmentation(filter, reportsUrl);
	}, [
		fetchSolutionOrdersTimeOverflowsSegmentations,
		fetchOrdersAverageHandlingTimeBySolution,
		fetchFeedbackAverageScoreByQuestions,
		fetchSolutionOrdersByStatusSegmentation,
		fetchClientsByApprovalStatusSegmentation,
		fetchPriceQuotesByStatusSegmentation,
		fetchSolutionOrdersBySolutionSegmentation,
		garageId,
	]);

	useEffect(() => {
		const filter = prepareFilter(filterValues);

		fetchAllReports(filter);
	}, [filterValues, fetchAllReports, garageId]);

	const setGarageIdFilter = (filters) => {
		const filter = prepareFilter(filters);
		setGarageId(filter.garageId);
	};

	return (
		<>
			<div>
				{isAdminOrHigher(permissions) && <ListContextProvider
                    value={{ setFilters: setGarageIdFilter, filterValues: { garageId } } as any}>
                    <Filter>
                        <ReferenceInput label="GARAGE" source="garageId" reference="garages" alwaysOn>
                            <AutocompleteInput label="GARAGE" optionText="name.he"/>
                        </ReferenceInput>
                    </Filter>
                </ListContextProvider>}
				<ListContextProvider value={{ setFilters, filterValues } as any}>
					<Filter>
						<DateInput source="from__createdAt" label="FROM_DATE" alwaysOn/>
						<DateInput source="to__createdAt" label="TO_DATE" alwaysOn/>
					</Filter>
				</ListContextProvider>
				<Button
					style={{ width: 'fit-content', marginTop: '2re' }}
					onClick={() => setFilters({
						from__createdAt: undefined,
						to__createdAt: undefined
					})}>{translator('CLEAR_FILTERS')}</Button>
			</div>
			<div style={styles.singleCol}>
				<div style={isSmall ? styles.singleCol : styles.flex}>
					<div style={styles.rightCol}>
						<PieSegmentationChart segmentation={solutionOrdersTimeOverflowsSegmentations}
						                      header={'SOLUTION_ORDERS_TIME_OVERFLOWS_SEGMENTATIONS'}/>
					</div>
					{isSmall ? <VerticalSpacer/> : <Spacer/>}
					<div style={styles.rightCol}>
						<PieSegmentationChart segmentation={solutionOrdersByStatusSegmentation}
						                      labelPrefix="ESolutionOrderStatus"
						                      header={'SOLUTION_ORDERS_BY_STATUS_SEGMENTATION'}/>
					</div>
					{isSmall ? <VerticalSpacer/> : <Spacer/>}
					<div style={styles.leftCol}>
						<PieSegmentationChart segmentation={clientsByApprovalStatusSegmentation}
						                      labelPrefix="EApprovalStatus"
						                      header={'CLIENTS_BY_APPROVAL_STATUS_SEGMENTATION'}/>
						{/*<VerticalSpacer/>*/}
					</div>
					{isSmall ? <VerticalSpacer/> : <Spacer/>}
					<div style={styles.leftCol}>
						<PieSegmentationChart segmentation={priceQuotesByStatusSegmentation}
						                      labelPrefix="EPriceQuoteStatus"
						                      header={'PRICE_QUOTES_BY_STATUS_SEGMENTATION'}/>
						{/*<VerticalSpacer/>*/}
					</div>
				</div>
				<VerticalSpacer/>
				{/*<Button variant="contained"*/}
				{/*        onClick={() => navigate(`/deliveries`,)}><span>{translator('SEE_FULL_REPORT')}</span></Button>*/}
			</div>
			<div style={styles.singleCol}>
				<div style={isSmall ? styles.singleCol : styles.flex}>
					<div style={styles.leftCol}>
						<BarsSegmentationChart segmentation={ordersAverageHandlingTimeBySolution}
						                       header={'ORDERS_AVERAGE_HANDLING_TIME_BY_SOLUTION'}/>
						{/*<VerticalSpacer/>*/}
					</div>
					{isSmall ? <VerticalSpacer/> : <Spacer/>}
					<div style={styles.leftCol}>
						<BarsSegmentationChart segmentation={feedbackAverageScoreByQuestions}
						                       header={'FEEDBACK_AVERAGE_SCORE_BY_QUESTIONS'}/>
						{/*<VerticalSpacer/>*/}
					</div>
					{isSmall ? <VerticalSpacer/> : <Spacer/>}
					<div style={styles.leftCol}>
						<BarsSegmentationChart segmentation={solutionOrdersBySolutionSegmentation}
						                       header={'SOLUTION_ORDERS_BY_SOLUTION_SEGMENTATION'}/>
						{/*<VerticalSpacer/>*/}
					</div>
				</div>
				<VerticalSpacer/>
				{/*<Button variant="contained"*/}
				{/*        onClick={() => navigate(`/deliveries`,)}><span>{translator('SEE_FULL_REPORT')}</span></Button>*/}
			</div>
		</>
	);
};
