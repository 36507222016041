import * as React from 'react';
import {
	EditButton,
	FileField,
	FunctionField,
	Labeled,
	NumberField,
	ReferenceField,
	ShowButton,
	TextField,
	useListContext
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { PriceField } from '../components/PriceField';
import { GalleryField } from '../components/GalleryField';
import { PriceQuoteStatusToColor } from '../util/statuses-colors.config';
import { StatusField } from '../components/StatusField';
import { TranslateField } from '../components/TranslateField';

const priceQuoteStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const PriceQuoteList = () => {
	const classes = priceQuoteStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="price-quotes"
										record={entity}
									/>
									<EditButton
										resource="price-quotes"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={entity} source="id" label="#"/>
						</Labeled>
						<Labeled label="SOLUTION_ORDER" sx={classes.cardContentRow}>
							<ReferenceField record={entity} source="solutionOrderId" label="FORUM"
							                reference="solution-orders" link="show">
								<FunctionField
									render={(record, source) => `#${record?.id} - ${record?.client?.user?.fullName} - ${record?.vehicle?.plateNumber}`}/>
							</ReferenceField>

						</Labeled>
						<Labeled label="TYPE" sx={classes.cardContentRow}>
							<TranslateField record={entity} source="type" label="TYPE" enumName="EPriceQuoteType"/>
						</Labeled>
						<Labeled label="STATUS" sx={classes.cardContentRow}>
							<StatusField record={entity} source="status" label="STATUS"
							             enumName="EPriceQuoteStatus"
							             enumToColors={PriceQuoteStatusToColor}/>
						</Labeled>
						<Labeled label="DESCRIPTION" sx={classes.cardContentRow}>
							<TextField record={entity} source="description" label="DESCRIPTION"/>
						</Labeled>
						<Labeled label="PRICE" sx={classes.cardContentRow}>
							<PriceField record={entity} source="price" label="PRICE"/>
						</Labeled>
						<Labeled label="STANDARD_TIME_IN_MINUTES" sx={classes.cardContentRow}>
							<NumberField record={entity} source="standardTimeInMinutes"
							             label="STANDARD_TIME_IN_MINUTES"/>
						</Labeled>
						<Labeled label="FILE" sx={classes.cardContentRow}>
							<FileField record={entity} source="fileUrl" label="FILE"/>
						</Labeled>
						<Labeled label="GALLERY" sx={classes.cardContentRow}>
							<GalleryField record={entity} source="gallery" label="GALLERY"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
