import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	AutocompleteInput,
	BulkActionProps,
	BulkDeleteButton,
	DateInput,
	ExportButton,
	FilterButton,
	List,
	ListProps,
	ReferenceInput,
	TextInput,
	TopToolbar,
	usePermissions,
} from 'react-admin';
import { SolutionOrderList } from './SolutionOrderList';
import { SolutionOrderDatagrid } from './SolutionOrderDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { SolutionOrder } from './SolutionOrder';
import { isAdminOrHigher, isServiceAdvisorOrHigher, isWorkshopManager } from '../providers/auth.provider';

export * from './SolutionOrder';
export * from './SolutionOrderCreate';
export * from './SolutionOrderDatagrid';
export * from './SolutionOrderEdit';
export * from './SolutionOrderList';
export * from './SolutionOrderShow';

const SolutionOrdersBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'garageId', header: 'GARAGE' },
	{ key: 'client', header: 'CLIENT' },
	{ key: 'solution', header: 'SOLUTION' },
	{ key: 'vehicle', header: 'VEHICLE' },
	{ key: 'drivingLicenseImageUrl', header: 'DRIVING_LICENSE_IMAGE' },
	{ key: 'personalIdImageUrl', header: 'PERSONAL_ID_IMAGE' },
	{ key: 'insuranceDocumentUrl', header: 'INSURANCE_DOCUMENT' },
	{ key: 'videoUrl', header: 'VIDEO' },
	{ key: 'gallery', header: 'GALLERY' },
	{ key: 'scheduledAppointment', header: 'SCHEDULED_APPOINTMENT' },
	{ key: 'answers', header: 'ANSWERS' },
	{ key: 'status', header: 'VEHICLE_STATUS' },
];

export const SolutionOrdersData = (props: ListProps): React.ReactElement => {
	const { permissions } = usePermissions();

	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: SolutionOrder) => entity;

	const solutionOrdersFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		...(isAdminOrHigher(permissions)) ? [<ReferenceInput
			label="GARAGE"
			source="garageId" reference="garages" alwaysOn
		>
			<AutocompleteInput label="GARAGE" optionText="name.he"/>
		</ReferenceInput>] : [],
		<ReferenceInput
			label="VEHICLE"
			source="vehicleId" reference="vehicles"
			alwaysOn
		>
			<AutocompleteInput label="VEHICLE" optionText="brand"/>
		</ReferenceInput>,
		<ReferenceInput
			label="SOLUTION"
			source="solutionId" reference="solutions" alwaysOn
		>
			<AutocompleteInput label="SOLUTION" optionText="name.he"/>
		</ReferenceInput>,
		<ReferenceInput
			label="CLIENT"
			source="clientId" reference="clients"
		>
			<AutocompleteInput label="CLIENT" optionText="user.fullName"/>
		</ReferenceInput>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	].filter(v => v) as React.JSX.Element[];

	const WorkshopManagerListActions = () => (
		<TopToolbar>
			<FilterButton></FilterButton>
			<ExportButton></ExportButton>
		</TopToolbar>
	);

	return (
		<Fragment>
			<List
				actions={isServiceAdvisorOrHigher(permissions) ? undefined : <WorkshopManagerListActions/>}
				exporter={generateExporter('הזמנות פתרון', exportFields, exportDataMapper)}
				{...props}
				filters={solutionOrdersFilter}
				bulkActionButtons={<SolutionOrdersBulkActionButtons/>}
				title="SOLUTION_ORDERS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				empty={isWorkshopManager(permissions) ? false : undefined}
				pagination={<Pagination/>}
			>
				{isXSmall ? <SolutionOrderList/> : <SolutionOrderDatagrid/>}
			</List>
		</Fragment>
	);
};
