import React, { FC, memo, useState } from 'react';
import { TextInput, useInput } from 'react-admin';
import { CommonInputProps } from 'ra-ui-materialui/src/input/CommonInputProps';
import * as ReactColor from 'react-color';

const styles = {
	popup: {
		position: 'absolute',
		zIndex: 2
	},
	cover: {
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0
	}
};

export const ColorInput: FC<ColorInputProps> = memo<ColorInputProps>(({ source, label, picker, ...props }) => {
	const [show, setShow] = useState<boolean>(false);
	const input = useInput({ source });

	const handleOpen = () => setShow(true);
	const handleClose = () => setShow(false);
	const handleChange = ({ hex }) => {
		input.field.onChange(hex);
	};

	const Picker = ReactColor[`${picker}Picker`];

	const {
		className,
		options,
		// resource,
		// helperText,
		// isRequired,
	} = props;

	return <div>
		<TextInput
			margin="normal"
			onFocus={handleOpen}
			source={source}
			label={label}
			// error={!!(touched && error)}
			// helperText={touched && error || helperText}
			className={className}
		/>
		{
			show ?
				<div className="ColorInput-popup">
					<div
						style={{ ...styles.cover, position: 'fixed' }}
						className="ColorInput-cover"
						onClick={handleClose}
					/>
					<Picker
						{...options}
						color={input.field.value}
						onChange={handleChange}
					/>
				</div>
				: null
		}
	</div>;
});

ColorInput.displayName = 'ColorInput';
// eslint-disable-next-line
ColorInput.propTypes = {
	picker: (props, propName, componentName) =>
		!ReactColor[`${props[propName]}Picker`] &&
		new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`)
} as any;

ColorInput.defaultProps = {
	picker: 'Chrome',
	options: {
		disableAlpha: true
	}
} as any;

export type ColorInputProps =
	CommonInputProps
	& {
	label: string,
	source: string,
	className?: string,
	options?: string[]
	input?: string,
	picker?: any
};
