import React from 'react';
import Assistant from '@mui/icons-material/Assistant';
import { SolutionFeedbacksData, SolutionFeedbackShow } from './solution-feedback';

import QuestionAnswer from '@mui/icons-material/QuestionAnswer';
import { FeedbackAnswersData, FeedbackAnswerShow } from './feedback-answer';

import EventBusy from '@mui/icons-material/EventBusy';
import { GarageDowntimeCreate, GarageDowntimeEdit, GarageDowntimesData, GarageDowntimeShow } from './garage-downtime';

import Grading from '@mui/icons-material/Grading';
import {
	FeedbackQuestionCreate,
	FeedbackQuestionEdit,
	FeedbackQuestionsData,
	FeedbackQuestionShow
} from './feedback-question';

import Receipt from '@mui/icons-material/Receipt';
import { PriceQuoteCreate, PriceQuoteEdit, PriceQuotesData, PriceQuoteShow } from './price-quote';

import DirectionsCar from '@mui/icons-material/DirectionsCar';
import { VehicleCreate, VehicleEdit, VehiclesData, VehicleShow } from './vehicle';
import { SolutionAnswerCreate, SolutionAnswerEdit, SolutionAnswersData, SolutionAnswerShow } from './solution-answer';

import Person from '@mui/icons-material/Person';
import { ClientCreate, ClientEdit, ClientsData, ClientShow } from './client';

import ShoppingCart from '@mui/icons-material/ShoppingCart';
import { SolutionOrderCreate, SolutionOrderEdit, SolutionOrdersData, SolutionOrderShow } from './solution-order';
import {
	SolutionQuestionCreate,
	SolutionQuestionEdit,
	SolutionQuestionsData,
	SolutionQuestionShow
} from './solution-question';

import SettingsApplications from '@mui/icons-material/SettingsApplications';
import { SolutionEdit, SolutionsData, SolutionShow } from './solution';

import WorkOutline from '@mui/icons-material/WorkOutline';
import { EmployeeCreate, EmployeeEdit, EmployeesData, EmployeeShow } from './employee';

import Warehouse from '@mui/icons-material/Warehouse';
import { GarageCreate, GarageEdit, GaragesData, GarageShow } from './garage';

import Notifications from '@mui/icons-material/Notifications';
import { i18nProvider } from './providers/i18n.provider';
import GatewayProvider from './providers/gateway.provider';
import { jwtAuthProvider } from './providers/auth.provider';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import { theme } from './theme';
import { CustomLoginPage, RedirectByTypePage } from './auth/Login';
import { CustomLayout } from './components/CustomLayout';
import { Dashboard } from './components/dashboard/Dashboard';
import './App.scss';
import { NotificationsForm } from './notifications/NotificationsForm';
import { Reports } from './reports/Reports';
import { ESolutionOrdersSegment } from './enums';

const App = (): React.ReactElement => {
	return (
		<div className="App" dir="rtl">
			<Admin
				loginPage={CustomLoginPage}
				theme={theme}
				layout={CustomLayout}
				dashboard={Dashboard}
				title={'אפליקציית Kushikaro'}
				dataProvider={GatewayProvider}
				authProvider={jwtAuthProvider}
				i18nProvider={i18nProvider}>
				<Resource
					icon={Assistant}
					name="solution-feedbacks"
					options={{ label: 'SOLUTION_FEEDBACKS' }}
					list={SolutionFeedbacksData}
					show={SolutionFeedbackShow}
				/>

				<Resource
					icon={QuestionAnswer}
					name="feedback-answers"
					options={{ label: 'FEEDBACK_ANSWERS' }}
					list={FeedbackAnswersData}
					show={FeedbackAnswerShow}
				/>

				<Resource
					icon={EventBusy}
					name="downtimes"
					options={{ label: 'GARAGE_DOWNTIMES' }}
					list={GarageDowntimesData}
					edit={GarageDowntimeEdit}
					create={GarageDowntimeCreate}
					show={GarageDowntimeShow}
				/>

				<Resource
					icon={Grading}
					name="feedback-questions"
					options={{ label: 'FEEDBACK_QUESTIONS' }}
					list={FeedbackQuestionsData}
					edit={FeedbackQuestionEdit}
					create={FeedbackQuestionCreate}
					show={FeedbackQuestionShow}
				/>

				<Resource
					icon={Receipt}
					name="price-quotes"
					options={{ label: 'PRICE_QUOTES' }}
					list={PriceQuotesData}
					edit={PriceQuoteEdit}
					create={PriceQuoteCreate}
					show={PriceQuoteShow}
				/>

				<Resource
					icon={DirectionsCar}
					name="vehicles"
					options={{ label: 'VEHICLES' }}
					list={VehiclesData}
					edit={VehicleEdit}
					create={VehicleCreate}
					show={VehicleShow}
				/>

				<Resource
					icon={QuestionAnswer}
					name="solution-answers"
					options={{ label: 'SOLUTION_ANSWERS' }}
					list={SolutionAnswersData}
					edit={SolutionAnswerEdit}
					create={SolutionAnswerCreate}
					show={SolutionAnswerShow}
				/>

				<Resource
					icon={Person}
					name="clients"
					options={{ label: 'CLIENTS' }}
					list={ClientsData}
					edit={ClientEdit}
					create={ClientCreate}
					show={ClientShow}
				/>

				<Resource
					icon={ShoppingCart}
					name="solution-orders"
					options={{ label: 'SOLUTION_ORDERS' }}
					list={SolutionOrdersData}
					edit={SolutionOrderEdit}
					create={SolutionOrderCreate}
					show={SolutionOrderShow}
				/>

				<Resource
					icon={QuestionAnswer}
					name="solution-questions"
					options={{ label: 'SOLUTION_QUESTIONS' }}
					list={SolutionQuestionsData}
					edit={SolutionQuestionEdit}
					create={SolutionQuestionCreate}
					show={SolutionQuestionShow}
				/>

				<Resource
					icon={SettingsApplications}
					name="solutions"
					options={{ label: 'SOLUTIONS' }}
					list={SolutionsData}
					edit={SolutionEdit}
					show={SolutionShow}
				/>

				<Resource
					icon={WorkOutline}
					name="employees"
					options={{ label: 'EMPLOYEES' }}
					list={EmployeesData}
					edit={EmployeeEdit}
					create={EmployeeCreate}
					show={EmployeeShow}
				/>

				<Resource
					icon={Warehouse}
					name="garages"
					options={{ label: 'GARAGES' }}
					list={GaragesData}
					edit={GarageEdit}
					create={GarageCreate}
					show={GarageShow}
				/>

				<Resource
					icon={Notifications}
					name="notifications"
					options={{ label: 'NOTIFICATIONS' }}
					create={NotificationsForm}
				/>

				<CustomRoutes>
					<Route path="/reports" element={<Reports/>}/>
					<Route path="/solution-orders/not-yet-done"
					       element={
						       <SolutionOrdersData resource="solution-orders"
						                           filter={{ segment: ESolutionOrdersSegment.NotYetDone }}>
							       <></>
						       </SolutionOrdersData>
					       }/>
					<Route path="/solution-orders/history"
					       element={
						       <SolutionOrdersData resource="solution-orders"
						                           filter={{ segment: ESolutionOrdersSegment.History }}>
							       <></>
						       </SolutionOrdersData>
					       }/>
					<Route path="/solution-orders/exceptions"
					       element={
						       <SolutionOrdersData resource="solution-orders"
						                           filter={{ segment: ESolutionOrdersSegment.Exceptions }}>
							       <></>
						       </SolutionOrdersData>
					       }/>
					<Route path="/redirectByType" element={<RedirectByTypePage/>}/>
				</CustomRoutes>
			</Admin>
		</div>
	);
};

export default App;
