import * as React from 'react';
import {
	ChipField,
	DateField,
	EditButton,
	ImageField,
	Labeled,
	ReferenceArrayField,
	ReferenceField,
	ShowButton,
	SingleFieldList,
	TextField,
	useListContext,
	usePermissions
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { GalleryField } from '../components/GalleryField';
import { StatusField } from '../components/StatusField';
import { SolutionOrderStatusToColor } from '../util/statuses-colors.config';
import { TranslateField } from '../components/TranslateField';
import { isAdminOrHigher } from '../providers/auth.provider';

const solutionOrderStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const SolutionOrderList = () => {
	const { permissions } = usePermissions();

	const classes = solutionOrderStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="solution-orders"
										record={entity}
									/>
									<EditButton
										resource="solution-orders"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={entity} source="id" label="#"/>
						</Labeled>
						{isAdminOrHigher(permissions) && <Labeled label="GARAGE" sx={classes.cardContentRow}>
                            <ReferenceField record={entity} source="garageId" label="GARAGE" reference="garages"
                                            link="show">
                                <TextField source="name.he"/>
                            </ReferenceField>
                        </Labeled>}
						<Labeled label="CLIENT" sx={classes.cardContentRow}>
							<ReferenceField record={entity} source="clientId" label="CLIENT" reference="clients"
							                link="show">
								<TextField source="user.fullName"/>
							</ReferenceField>

						</Labeled>
						<Labeled label="SOLUTION" sx={classes.cardContentRow}>
							<ReferenceField record={entity} source="solutionId" label="SOLUTION" reference="solutions"
							                link="show">
								<TextField source="name.he"/>
							</ReferenceField>
						</Labeled>

						<Labeled label="ASSIGNEE" sx={classes.cardContentRow}>
							<ReferenceField record={entity} source="assigneeId" label="ASSIGNEE" reference="employees"
							                link="show">
								<TextField source="user.fullName"/>
							</ReferenceField>
						</Labeled>

						<Labeled label="VEHICLE" sx={classes.cardContentRow}>
							<ReferenceField record={entity} source="vehicleId" label="VEHICLE" reference="vehicles"
							                link="show">
								<TextField source="brand"/>
							</ReferenceField>
						</Labeled>
						<Labeled label="VEHICLE_STATUS" sx={classes.cardContentRow}>
							<StatusField record={entity} source="status" label="VEHICLE_STATUS"
							             enumName="EVehicleStatus"
							             enumToColors={SolutionOrderStatusToColor}/>
						</Labeled>
						<Labeled label="STARTED_AT" sx={classes.cardContentRow}>
							<DateField record={entity} source="startedAt" label="STARTED_AT" showTime options={{
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
								hour: '2-digit',
								minute: '2-digit'
							}} locales="he-IL"/>
						</Labeled>
						<Labeled label="FINISHED_AT" sx={classes.cardContentRow}>
							<DateField record={entity} source="finishedAt" label="FINISHED_AT" showTime options={{
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
								hour: '2-digit',
								minute: '2-digit'
							}}
							           locales="he-IL"/>
						</Labeled>
						<Labeled label="DRIVING_LICENSE_IMAGE" sx={classes.cardContentRow}>
							<ImageField record={entity} source="drivingLicenseImageUrl" label="DRIVING_LICENSE_IMAGE"/>
						</Labeled>
						<Labeled label="PERSONAL_ID_IMAGE" sx={classes.cardContentRow}>
							<ImageField record={entity} source="personalIdImageUrl" label="PERSONAL_ID_IMAGE"/>
						</Labeled>
						<Labeled label="INSURANCE_DOCUMENT" sx={classes.cardContentRow}>
							<ImageField record={entity} source="insuranceDocumentUrl" label="INSURANCE_DOCUMENT"/>
						</Labeled>
						<Labeled label="VIDEO" sx={classes.cardContentRow}>
							<TextField record={entity} source="videoUrl" label="VIDEO"/>
						</Labeled>
						<Labeled label="GALLERY" sx={classes.cardContentRow}>
							<GalleryField record={entity} source="gallery" label="GALLERY"/>
						</Labeled>
						<Labeled label="SCHEDULED_APPOINTMENT" sx={classes.cardContentRow}>
							<DateField record={entity} source="scheduledAppointment" label="SCHEDULED_APPOINTMENT"
							           showTime options={{
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
								hour: '2-digit',
								minute: '2-digit'
							}} locales="he-IL"/>
						</Labeled>
						<Labeled label="VEHICLE_DRIVABILITY" sx={classes.cardContentRow}>
							<TranslateField record={entity} source="vehicleDrivability" label="VEHICLE_DRIVABILITY"/>
						</Labeled>
						<Labeled label="ANSWERS" sx={classes.cardContentRow}>
							<ReferenceArrayField record={entity} source="answers" label="ANSWERS"
							                     reference="solution-answers">
								<SingleFieldList>
									<ChipField source="question.label"/>
								</SingleFieldList>
							</ReferenceArrayField>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
