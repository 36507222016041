export enum EUserType {
	Admin = 'Admin',
	Regular = 'Regular',
	Client = 'Client',
	GarageOwner = 'GarageOwner',
	ServiceAdvisor = 'ServiceAdvisor',
	WorkshopManager = 'WorkshopManager',
}

export enum ESearchType {
	EQUALS = 'EQUALS',
	NOT_EQUALS = 'NOT_EQUALS',
	CONTAINS = 'CONTAINS',
	IN = 'IN',
	BETWEEN = 'BETWEEN'
}

export enum EUserStatus {
	Pending = 'Pending',
	Approved = 'Approved'
}

export enum NotificationType {
	SMS = 'SMS',
	Email = 'Email',
	All = 'All'
}

export enum EGender {
	Male = 'Male',
	Female = 'Female'
}

export enum EPlatform {
	AdminPanel = 'AdminPanel',
	App = 'App',
	Web = 'Web'
}

export enum EWeekday {
	Sunday = 'sunday',
	Monday = 'monday',
	Tuesday = 'tuesday',
	Wednesday = 'wednesday',
	Thursday = 'thursday',
	Friday = 'friday',
	Saturday = 'saturday',
}

export enum EVehicleType {
	Car = 'Car',
	Motorbike = 'Motorbike',
	Truck = 'Truck'
}

export enum EVehicleGearType {
	Manual = 'Manual',
	Automatic = 'Automatic'
}

export enum EQuestionType {
	Text = 'Text',
	LongText = 'LongText',
	Numeric = 'Numeric'
}

export enum EApprovalStatus {
	Pending = 'Pending',
	Approved = 'Approved',
	Blocked = 'Blocked'
}

export enum EFuelType {
	Gasoline = 'Gasoline',
	Diesel = 'Diesel',
	Electric = 'Electric'
}

export enum ESolutionOrdersSegment {
	All = 'All',
	NotYetDone = 'NotYetDone',
	History = 'History',
	Exceptions = 'Exceptions'
}

export enum EPriceQuoteType {
	Main = 'Main',
	Addition = 'Addition'
}

export enum EPriceQuoteStatus {
	Draft = 'Draft',
	SentToClient = 'SentToClient',
	ClientApproved = 'ClientApproved',
	ClientDeclined = 'ClientDeclined',
	ClientPaid = 'ClientPaid',
}

export enum ESolutionOrderStatus {
	NewOrder = 'NewOrder',
	GarageApproved = 'GarageApproved',
	WaitingForPriceQuote = 'WaitingForPriceQuote',
	WaitingForClientApproval = 'WaitingForClientApproval',
	PriceQuoteApproved = 'PriceQuoteApproved',
	DeclinedPriceQuote = 'DeclinedPriceQuote',
	InProgress = 'InProgress',
	WaitingForPayment = 'WaitingForPayment',
	Done = 'Done',
	ClientCancelled = 'ClientCancelled',
	GarageCancelled = 'GarageCancelled',
	WaitingForPickup = 'WaitingForPickup',
}

export enum EVehicleStatus {
	Waiting = 'Waiting',
	InWork = 'InWork',
	InDrivingCheck = 'InDrivingCheck',
	InTest = 'InTest',
	InWash = 'InWash',
	InTireShop = 'InTireShop',
	InWheelsAlignment = 'InWheelsAlignment',
	BackToOffice = 'BackToOffice'
}

export enum EVehicleDrivability {
	Drivable = 'Drivable',
	UnDrivable = 'UnDrivable'
}
