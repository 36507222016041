import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { BulkActionProps, BulkDeleteButton, DateInput, List, ListProps, TextInput, } from 'react-admin';
import { SolutionAnswerList } from './SolutionAnswerList';
import { SolutionAnswerDatagrid } from './SolutionAnswerDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { SolutionAnswer } from './SolutionAnswer';

export * from './SolutionAnswer';
export * from './SolutionAnswerCreate';
export * from './SolutionAnswerDatagrid';
export * from './SolutionAnswerEdit';
export * from './SolutionAnswerList';
export * from './SolutionAnswerShow';

const SolutionAnswersBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'question', header: 'QUESTION' },
	{ key: 'order', header: 'SOLUTION_ORDER' },
	{ key: 'content', header: 'CONTENT' },
];

export const SolutionAnswersData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: SolutionAnswer) => entity;

	const solutionAnswersFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('תשובות', exportFields, exportDataMapper)}
				{...props}
				filters={solutionAnswersFilter}
				bulkActionButtons={<SolutionAnswersBulkActionButtons/>}
				title="SOLUTION_ANSWERS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <SolutionAnswerList/> : <SolutionAnswerDatagrid/>}
			</List>
		</Fragment>
	);
};
