import * as React from 'react';
import { useEffect } from 'react';
import {
	AutocompleteInput,
	minValue,
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	TextInput,
} from 'react-admin';
import { MultipleImagesInput } from '../components/MultipleImagesInput';
import { EPriceQuoteStatus, EPriceQuoteType } from '../enums';
import { SolutionOrder } from '../solution-order';
import { useFormContext } from 'react-hook-form';
import { PriceQuoteFileInput } from '../components/PriceQuoteFileInput';

export const PriceQuoteForm = ({ isSolutionOrderIdChangeable }): React.ReactElement => {
	const form = useFormContext();

	const getQueryParam = (url, param) => {
		const match = url.match(new RegExp(`[?&]${param}=([^&]*)`));
		return match && match[1] ? match[1] : null;
	};

	useEffect(() => {
		const url = window.location.href;
		const solutionOrderId = getQueryParam(url, 'solutionOrderId');

		if (solutionOrderId) {
			console.log(solutionOrderId);
			form.setValue('solutionOrderId', solutionOrderId);
		}
	}, [form]);

	return (
		<>
			<ReferenceInput
				label="SOLUTION_ORDER"
				source="solutionOrderId" reference="solution-orders"
				alwaysOn
				validate={[required()]}
			>
				<AutocompleteInput
					label="SOLUTION_ORDER"
					validate={[required()]}
					disabled={!isSolutionOrderIdChangeable}
					optionText={(solutionOrder: SolutionOrder) => {
						return `#${solutionOrder?.id} - ${solutionOrder?.client?.user?.fullName} - ${solutionOrder?.vehicle?.plateNumber}`;
					}}
				/>
			</ReferenceInput>

			<PriceQuoteFileInput source="fileUrl" label="FILE" onChange={(value) => {
				form.setValue('price', value);
			}}/>

			<TextInput multiline source="description" label="DESCRIPTION" validate={[required()]}/>
			<NumberInput source="price" label="PRICE" validate={[required(), minValue(0)]} min="0"/>
			<SelectInput source="type" label="TYPE"
			             choices={Object.keys(EPriceQuoteType).map(key => ({
				             id: key,
				             name: `EPriceQuoteType.${EPriceQuoteType[key]}`
			             }))}
			/>
			<SelectInput source="status" label="STATUS"
			             choices={Object.keys(EPriceQuoteStatus).map(key => ({
				             id: key,
				             name: `EPriceQuoteStatus.${EPriceQuoteStatus[key]}`
			             }))}
			/>
			<NumberInput source="standardTimeInMinutes" label="STANDARD_TIME_IN_MINUTES" step={0.1}/>
			<MultipleImagesInput source="gallery" label="GALLERY"/>
		</>
	);
};