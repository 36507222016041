import * as React from 'react';
import { ReferenceField, Show, ShowProps, SimpleShowLayout, TextField, usePermissions, } from 'react-admin';
import { isGarageOwnerOrHigher } from '../providers/auth.provider';

export const SolutionAnswerShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={isGarageOwnerOrHigher(permissions) ? undefined : false}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				<ReferenceField source="questionId" reference="solution-questions" label="QUESTION" link="show">
					<TextField source="label.he"/>
				</ReferenceField>

				<ReferenceField source="orderId" reference="solution-orders" label="SOLUTION_ORDER" link="show">
					<TextField source="client.user.fullName"/>
				</ReferenceField>

				<TextField source="content" label="CONTENT"/>
			</SimpleShowLayout>
		</Show>
	);
};
