import * as React from 'react';
import { Create, CreateProps, SimpleForm } from 'react-admin';
import { SolutionOrderForm } from './SolutionOrderForm';

export const SolutionOrderCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props} redirect="list">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<SolutionOrderForm/>
			</SimpleForm>
		</Create>
	);
};
