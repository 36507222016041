import * as React from 'react';
import {
	DateField,
	EditButton,
	ImageField,
	Labeled,
	ReferenceField,
	ShowButton,
	TextField,
	useListContext,
	usePermissions
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { ApprovalStatusToColor } from '../util/statuses-colors.config';
import { StatusField } from '../components/StatusField';
import { isAdminOrHigher } from '../providers/auth.provider';

const vehicleStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const VehicleList = () => {
	const { permissions } = usePermissions();

	const classes = vehicleStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="vehicles"
										record={entity}
									/>
									<EditButton
										resource="vehicles"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<TextField record={entity} source="id" label="#"/>
						</Labeled>
						<Labeled label="PLATE_NUMBER" sx={classes.cardContentRow}>
							<TextField record={entity} source="plateNumber" label="PLATE_NUMBER"/>
						</Labeled>
						<Labeled label="MODEL" sx={classes.cardContentRow}>
							<TextField record={entity} source="model" label="MODEL"/>
						</Labeled>
						<Labeled label="OWNER" sx={classes.cardContentRow}>
							<ReferenceField record={entity} source="ownerId" label="OWNER" reference="clients"
							                link="show">
								<TextField source="user.fullName"/>
							</ReferenceField>

						</Labeled>
						{isAdminOrHigher(permissions) && <Labeled label="GARAGE" sx={classes.cardContentRow}>
                            <ReferenceField record={entity} source="garageId" label="GARAGE" reference="garages"
                                            link="show">
                                <TextField source="name.he"/>
                            </ReferenceField>
                        </Labeled>}
						<Labeled label="APPROVAL_STATUS" sx={classes.cardContentRow}>
							<StatusField record={entity} source="approvalStatus" label="APPROVAL_STATUS"
							             enumName="EApprovalStatus"
							             enumToColors={ApprovalStatusToColor}/>
						</Labeled>
						<Labeled label="IMAGE" sx={classes.cardContentRow}>
							<ImageField record={entity} source="imageUrl" label="IMAGE"/>
						</Labeled>
						<Labeled label="BRAND" sx={classes.cardContentRow}>
							<TextField record={entity} source="brand" label="BRAND"/>
						</Labeled>
						<Labeled label="TEST_EXPIRES_ON" sx={classes.cardContentRow}>
							<DateField locales="he-IL" record={entity} source="testExpiresOn" label="TEST_EXPIRES_ON"/>
						</Labeled>
						<Labeled label="YEAR" sx={classes.cardContentRow}>
							<TextField record={entity} source="year" label="YEAR"/>
						</Labeled>
						<Labeled label="COLOR" sx={classes.cardContentRow}>
							<TextField record={entity} source="color" label="COLOR"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
