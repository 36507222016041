import * as React from 'react';
import { Create, CreateProps, SimpleForm, } from 'react-admin';
import { PriceQuoteForm } from './PriceQuotesForm';

export const PriceQuoteCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props}>
			<SimpleForm>
				<PriceQuoteForm isSolutionOrderIdChangeable={true}/>
			</SimpleForm>
		</Create>
	);
};
