import * as React from 'react';
import { ReferenceField, Show, ShowProps, SimpleShowLayout, TextField, usePermissions, } from 'react-admin';
import { isGarageOwnerOrHigher } from '../providers/auth.provider';

export const FeedbackAnswerShow = (props: ShowProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Show {...props} actions={isGarageOwnerOrHigher(permissions) ? undefined : false}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				<ReferenceField source="feedbackId" reference="solution-feedbacks" label="FEEDBACK" link="show">
					<TextField source="id"/>
				</ReferenceField>

				<ReferenceField source="questionId" reference="solution-questions" label="QUESTION" link="show">
					<TextField source="label.he"/>
				</ReferenceField>

				<TextField source="value" label="FEEDBACK_VALUE"/>
			</SimpleShowLayout>
		</Show>
	);
};
