import * as React from 'react';
import { useState } from 'react';

import { FileField, FileInput, FunctionField, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { httpClient } from '../providers/http_client';
import { API_URL } from '../providers/constants';

export const PriceQuoteFileInput = ({ record, source, onChange, ...props }: any) => {
	const recordByContext = useRecordContext();
	record = record ?? recordByContext;
	const form = useFormContext();
	const [, setFile] = useState(null);
	const [, setIsLoading] = useState(false);

	const onFileChange = async (file: any) => {
		if (file) {
			await uploadFile(file);

			if (file.type === 'text/html') {
				const textContent = await readFile(file);

				if (textContent) {
					onChange(extractPrice(textContent));
				}
			}
		}
	};

	async function uploadFile(file) {
		const formData = new FormData();
		formData.append('file', file);
		setFile(file);

		setIsLoading(true);
		const { json } = await httpClient(`${API_URL}/files/upload/single`, {
			method: 'POST',
			headers: new Headers({ 'ContentType': 'multipart/form-data' }),
			body: formData
		});
		setIsLoading(false);

		form.setValue(source, json.data);
	}

	async function readFile(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = function (e) {
				const text = e.target?.result;

				const parser = new DOMParser();
				const doc = parser.parseFromString(text as string, 'text/html');

				const priceText = findPriceText(doc);
				if (priceText) {
					resolve(priceText);
				} else {
					console.log('Div with id "price" not found');
					resolve(null);
				}
			};

			reader.onerror = function (e) {
				console.error('Error reading file', e);
				reject(e);
			};

			reader.readAsText(file);
		});
	}

	function findPriceText(doc: Document) {
		const divs: any = doc.querySelectorAll('table td font b');

		if (divs[divs.length - 1].innerHTML.includes('סה"כ מחיר')) {
			return divs[divs.length - 2].textContent;
		}

		return null;
	}

	function extractPrice(text) {
		const numberPattern = /(\d+(?:,\d+)*(?:\.\d+)?)/;
		const match = text.match(numberPattern);

		if (match) {
			const numberString = match[0].replace(/,/g, '');
			return parseFloat(numberString);
		} else {
			return null;
		}
	}

	return <FileInput {...props}
	                  source={source}
	                  onChange={onFileChange}
	>
		<FunctionField render={(record) => {
			const file = { src: record, title: record?.title ?? record?.split('/').pop() };

			return <FileField record={{ file }} source="file" src={file.src} title={file.title}/>;
		}}/>
	</FileInput>;
};

