import * as React from 'react';
import { BooleanField, Datagrid, ReferenceField, TextField } from 'react-admin';
import { TranslateField } from '../components/TranslateField';
import './SolutionQuestionDatagrid.scss';

export const SolutionQuestionDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="solution-questions-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField sortable={false} source="id" label="#"/>
			<ReferenceField sortable={false} source="solution" reference="solutions" label="SOLUTION" link="show">
				<TextField sortable={false} source="id"/>
			</ReferenceField>

			<TextField source="label" label="LABEL"/>
			<TextField source="placeholder" label="PLACEHOLDER"/>
			<BooleanField source="isRequired" label="IS_REQUIRED"/>
			<TranslateField source="type" label="QUESTION_TYPE"/>
		</Datagrid>
	);
};
